import { useState, ChangeEvent, useEffect, useRef } from 'react';
import Images from "../assets/images/index";
import { useNavigate } from "react-router-dom";
import { useRegister, useUpdateUserProcessStatus, useFetchCountry, useFetchUser } from "../services/user.service";
import { IRegister, ICountry } from "../types/user";
import { storage } from "../utils/storage";
// import 'react-phone-input-2/lib/style.css';
import { googleAuthenticateUrl } from '../helpers/socialauthenticateurl';
import { checkPasswordStrength } from '../helpers/passwordstrength';
import { getTimezoneDisplayName, handleEmptySpaces, handleKeyDown, isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage } from '../helpers/common';
import { validateEmailFormat, validateNotEmpty } from '../utils/validation';
import IResponse from '../types/response';
import * as Sentry from "@sentry/react";

const Registration = () => {
  const navigate = useNavigate();
  const [register, setRegister] = useState<IRegister>({
    userId: "00000000-0000-0000-0000-000000000000",
    email: "",
    password: "",
    fullName: "",
    mobileNo: "",
    referralCode: "",
    countryId: 0,
    timezoneId: ""
  });
  const [usernameError, setUsernameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [formResponseError, setFormResponseError] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<any>();
  //const [countryList, setCountryList] = useState<ICountry[]>([]);
  const [country, setCountry] = useState<ICountry[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const isUpdateCurrentUser = useRef(false);
  const [loading, setLoading] = useState(false);
  const checkValidation = (field: string) => {
    let isValid = true;
    const validateField = (value: string, errorSetter: Function, errorMessage: string) => {
      if (!validateNotEmpty(value)) {
        errorSetter(errorMessage);
        isValid = false;
      } else {
        errorSetter('');
      }
    };
    if (field === "email" || field === "both") {
      validateField(register.email, setEmailError, 'Email is required');
      if (isValid && !validateEmailFormat(register.email)) {
        setEmailError('Invalid email format');
        isValid = false;
      }
    }
    if (!isUpdateCurrentUser.current && (field === "password" || field === "both")) {
      validateField(register.password, setPasswordError, 'Password is required');
    }
    return isValid;
  };

  useEffect(() => {
    let storedCountryList = getCountryList();
    if (storedCountryList && storedCountryList.length > 0) {
      setCountry(storedCountryList);
      const options = storedCountryList.find((country: { countryId: number; }) => country.countryId === 1) || {
        countryName: '',
        dialingCode: '',
        flagImageUrl: ''
      };
      handleCountryCodeChange(options);
    } else {
      fetchCountry();
      storedCountryList = getCountryList();
    }

    if (isNotNullUndefinedBlank(storage.getUserStatusId()) || isNotNullUndefinedBlank(storage.getUserExchangeStatusId())) {
      navigate(statusWiseOnboardingRedirectToPage());
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const setExistingUserData = {
      userId: storage.getUserId() ?? "00000000-0000-0000-0000-000000000000",
      email: storage.getEmailId() ?? "",
      password: "",
      fullName: storage.getUserFullName() ?? "",
      mobileNo: storage.getPhoneNumber() ?? "",
      referralCode: storage.getUserReferralCode() ?? "",
      countryId: parseInt(storage.getCountryId() ?? "1"),
      timezoneId: ""
    };

    isUpdateCurrentUser.current = JSON.stringify(register) !== JSON.stringify(setExistingUserData);
    let userRegister = null;
    if (isUpdateCurrentUser.current) {
      let mobileNo = storage.getPhoneNumber() ?? "";
      setPhoneNumber(mobileNo);
      userRegister = setExistingUserData;
    } else {
      userRegister = register;
    }
    setRegister({ ...userRegister, timezoneId: getTimezoneDisplayName(), referralCode: urlSearchParams.get("referral") ?? "" });
  }, []);

  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRegister({ ...register, [name]: value });
    if (name === "password") {
      setPasswordScore(checkPasswordStrength(value));
    }
  };


  const updateUserProcessStatusMutation = useUpdateUserProcessStatus({
    onSuccess: (response: IResponse) => {
      if (response.statusCode === 200) {
        storage.setUserStatusId(5);
        navigate('/registration/verify');
      }
      else {
        setLoading(false);
        setFormResponseError(response.statusMessage);
      }
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const registerMutation = useRegister({
    onSuccess: (response) => {
      if (response.statusCode === 201) {
        const userId = response.data.userId;
        const email = response.data.email;
        const phoneNumber = response.data.phoneNumber;
        const countryId = response.data.countryId;
        const fullName = response.data.fullName;
        const referralCode = response.data.referralCode;
        updateUserProcessStatusMutation.mutate({ "userId": userId, "statusCode": "REGISTERED" });
        storage.setUserId(userId);
        storage.setEmailId(email);
        storage.setPhoneNumber(phoneNumber);
        storage.setCountryId(countryId);
        storage.setUserFullName(fullName);
        storage.setUserReferralCode(referralCode);
      }
      else {
        setLoading(false);
        setFormResponseError(response.statusMessage);
      }
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const onRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (checkValidation("both")) {
      setLoading(true);
      const updatedRegister = {
        ...register,
        mobileNo: phoneNumber.toString(),
        countryId: selectedCountry.countryId ? selectedCountry.countryId : 1
      };
      storage.setDailingCode(selectedCountry.dialingCode);
      if (!isNotNullUndefinedBlank(register.password) && isUpdateCurrentUser.current) {
        const registerForMutation = { ...updatedRegister, password: "null" };
        registerMutation.mutate({ register: registerForMutation });
      } else {
        registerMutation.mutate({ register: updatedRegister });
      }
    }
  };

  const fetchUserMutations = useFetchUser({
    onSuccess: () => { },
    onError: (error: any) => {
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const loginWithGoogle = () => {
    fetchUserMutations.mutate({
      onSuccess: (response: IResponse) => {
        if (response.statusCode === 200) {
          navigate(statusWiseOnboardingRedirectToPage());
        } else {
          window.localStorage.clear();
        }
      },
      onError: (error: any) => {
        window.localStorage.clear();
        Sentry.captureException(error);
        setFormResponseError(error.message);
      }
    });
    window.location.href = googleAuthenticateUrl();
  };

  const getCountryList = () => {
    const countryListString = storage.getCountryList();
    return countryListString ? JSON.parse(countryListString) : [];
  };

  const fetchCountry = async () => {
    fetchCountryMutation.mutate({});
  };

  const fetchCountryMutation = useFetchCountry({
    onSuccess: (response: IResponse) => {
      if (response != null && response !== undefined) {
        response.data = response;
        setCountry(response.data);
        storage.setCountryList(JSON.stringify(response.data));
        const storedCountryList = getCountryList();
        const options = storedCountryList.find((country: { countryId: number; }) => country.countryId === 1) || {
          countryName: '',
          dialingCode: '',
          flagImageUrl: ''
        };
        handleCountryCodeChange(options);
      }
    },
    onError: (error: any) => {
      alert(error.message);
    },
  });

  const handleCountryCodeChange = (option: any) => {
    if (Array.isArray(option.label)) {
      const slicedLabel = option.label.slice(0, 2);
      const modifiedOption = { ...option, label: slicedLabel };
      setSelectedCountry(modifiedOption);
      setIsDropdownOpen(false);
    } else {
      setSelectedCountry(option);
      setIsDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
    setIsDropdownOpen(false);
  };

  // const handlePhoneNumberChange = (value: any, data: any) => {
  //   const phoneNumber = value.slice(data.dialCode.length);
  //   const updatedRegister = {
  //     ...register,
  //     mobileNo: phoneNumber.toString(),
  //   };
  //   setRegister(updatedRegister);
  //   setPhoneNumber(value);
  // };

  //#region Password
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()-_=+[]{};:<>?';
  const allCharacters = [lowercase, uppercase, numbers, specialChars];

  const getRandomCharacter = (characters: any) => {
    const randomType = characters[Math.floor(Math.random() * characters.length)];
    const randomIndex = Math.floor(Math.random() * randomType.length);
    return randomType[randomIndex];
  };
  const onAutoGeneratePassword = () => {
    const password =
      getRandomCharacter(lowercase) +
      getRandomCharacter(uppercase) +
      getRandomCharacter(numbers) +
      getRandomCharacter(specialChars) +
      Array.from({ length: 4 }, () => getRandomCharacter(allCharacters)).join('');
    register.password = password;
    setPasswordScore(checkPasswordStrength(password));
    checkValidation('password');
  };
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    if (showPassword)
      setShowPassword(false);
    else if (!showPassword)
      setShowPassword(true);
  };
  const [passwordScore, setPasswordScore] = useState<number>(0);

  //#endregion Password
  return (
    <div className="bg-black-russian">
      <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto lg-block max-h-full min-h-screen">
        <div className='hidden lg:block'>
          <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
            <div className='flex justify-center'>
              <img
                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                src={Images.ailtralogo}
                alt="Ailtra Logo" draggable="false" />
            </div>
            <div className='flex justify-center items-center'>
              <div className='w-[562px]'>
                <div className='flex justify-center my-8 lg:my-[100px]'>
                  <img
                    className="bg-contain w-1/2 lg:w-3/5 3xl:w-4/6 bg-no-repeat"
                    src={Images.welcome}
                    alt="welcome Logo" draggable="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right bg-white rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
          <div className='w-full flex justify-center'>
            <div className='w-36 h-1/5'>
              <img
                className="w-full mt-4 lg:hidden block"
                src={Images.mobilelogo}
                alt="Mobile Logo" draggable="false" />
            </div>
          </div>
          <div className='flex justify-center items-center mt-8 lg:mt-0 lg:h-full'>
            <div className='w-[340px] 2xl:w-[388px]'>
              <div>
                <h1 className="text-xl md:text-3xl 2xl:text-[34px] font-semibold subpixel-antialiased">Join Ailtra for Free ✨</h1>
                <p className="text-sm md:text-base font-normal text-blue-gulf sm:leading-6 tracking-0.16 mt-2 lg:mt-3"> Guaranteed Profits, Zero Losses: 13-Level Hedging & Advanced Fund Management Strategy </p>
              </div>
              <div className="mt-6">
                <form onSubmit={onRegister}>
                  <label className={`block relative border-blue-hawkes px-4 py-2 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${usernameError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'} `} >
                    <span className="block text-xs font-semibold text-black-pearl">Fullname</span>
                    <input type="text" name="fullName" className="placeholder-grey-storm  bg-blue-alice focus:outline-none text-sm w-full pt-1"
                      value={register.fullName} onChange={inputChange} onBlurCapture={handleEmptySpaces} placeholder="Enter your fullname" />
                    {/* onBlur={() => checkValidation("fullName")} */}
                  </label>
                  {usernameError && <p className="text-red-500 text-xs font-semibold mt-1 absolute">{usernameError}</p>}
                  <label className={`block relative border-blue-hawkes px-4 py-2 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${emailError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                    <span className="after:content-['*'] after:ml-0.5 after:text-black-pearl block text-xs font-semibold text-black-pearl"> Email </span>
                    <input type="email" name="email" onBlur={() => checkValidation("email")} onKeyDown={handleKeyDown} className="placeholder-grey-storm  bg-blue-alice focus:outline-none text-sm w-full pt-1"
                      value={register.email} onChange={inputChange} placeholder="Enter your email address" />
                  </label>
                  <p className="text-red-500 text-xs font-semibold mt-1 absolute">{emailError}</p>

                  <div className='w-full flex justify-end'>
                    <button type='button' className="mt-6 w-max text-10 2xl:text-xs text-emerald underline mb-1 cursor-pointer select-none outline-none" onClick={onAutoGeneratePassword} >
                      Auto Generate Password
                    </button>
                  </div>


                  <label htmlFor="password" className={`block relative border-blue-hawkes px-4 py-2 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock ${passwordError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                    <div className='flex justify-between items-center'>
                      <div className='w-full'>
                        <span className="after:content-['*'] after:ml-0.5 after:text-black-pearl block text-xs font-semibold text-black-pearl"> Password </span>
                        {showPassword ? (
                          <input
                            type="text"
                            id="password"
                            name="password"
                            className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                            value={register.password}
                            onBlur={() => checkValidation("password")}
                            onChange={inputChange}
                            placeholder="Enter your password"
                            onKeyDown={handleKeyDown}
                          />
                        ) : (
                          <input
                            type="password"
                            id="password"
                            name="password"
                            className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                            value={register.password}
                            onChange={inputChange}
                            onBlur={() => checkValidation("password")}
                            placeholder="Enter your password"
                            autoComplete="new-password"
                            onKeyDown={handleKeyDown}
                          />
                        )}
                      </div>
                      <div className='flex items-center'>
                        {showPassword ? (
                          <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                            <img
                              className="w-6 h-6 outline-none"
                              src={Images.eye}
                              alt="close-eye"
                            />
                          </button>
                        ) : (
                          <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                            <img
                              className="w-6 h-6 outline-none"
                              src={Images.eyeopen}
                              alt="open-eye"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </label>
                  <div className="flex -mx-1 mt-2">
                    {Array.from({ length: 5 }).map((_, i) => (
                      <div key={i} className="w-1/5 px-1">
                        <div
                          className={`h-1 rounded-xl transition-colors ${i < passwordScore
                            ? passwordScore <= 2
                              ? "bg-fire-brick"
                              : passwordScore <= 2
                                ? "bg-burnt-sienna"
                                : passwordScore <= 4
                                  ? "bg-rob-roy"
                                  : "bg-kelly-green"
                            : "bg-blue-hawkes"
                            }`}
                        />
                      </div>
                    ))}
                  </div>
                  {passwordError && <p className="text-red-500 text-xs font-semibold absolute mt-4.5">{passwordError}</p>}
                  <div className="flex justify-between mt-1">
                    <span className='text-10 2xl:text-xs font-light text-grey-storm'>
                      Must be at least 4 character long
                    </span>
                    <span className={`text-10 font-semibold  ${passwordScore <= 2 ? 'text-red-500' : passwordScore <= 4 ? 'text-yellow-500' : 'text-gradient bg-gradient bg-clip-text'
                      }`}>
                      {passwordScore <= 2 ? 'Weak' : passwordScore <= 4 ? 'Medium' : 'Strong'}
                    </span>
                  </div>

                  {/* <label htmlFor='phone-input' className="block border-blue-hawkes px-4 py-2 pb-0 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover">
                    <span className="after:ml-0.5 after:text-red-500 block text-xs font-semibold text-black-pearl"> Mobile number </span>
                    <div className='country-code'>
                      <PhoneInput
                        country={'in'}
                        searchStyle={{
                          width: '248px',
                          margin: '0px 9px 5px 9px',
                          border: '1px solid #d4d7e3',
                          background: '#f7fbff',
                          color: 'black',
                          padding: '10px 8px'
                        }}
                        dropdownStyle={{
                          width: '320px',
                          maxHeight: '285px',
                          left: '-14px'
                        }}
                        inputProps={{
                          id: 'phone-input',
                        }}
                        enableSearch={true}
                        countryCodeEditable={false}
                        searchPlaceholder="Search for your country"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                      />
                    </div>
                  </label> */}

                  <div className="flex items-center w-full mt-6">
                    <div className="relative min-w-[28%]">
                      <div className="bg-blue-alice border-blue-hawkes text-black-pearl text-xs font-semibold cursor-pointer px-2 py-2 h-[60px] border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover flex justify-between items-center" onClick={toggleDropdown}>
                        <span className={`text-black-pearl flex justify-between items-center w-full gap-2 ${selectedCountry ? 'text-black-pearl' : ''}`}>
                          {selectedCountry ? `(${selectedCountry.dialingCode})` : ''}
                          <div className='w-full h-full'>
                            <img src={selectedCountry?.flagImageUrl} className="w-5" alt="Country Flag" />
                          </div>
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z" fill="black" />
                        </svg>
                      </div>
                      {isDropdownOpen && (
                        <div className="absolute mt-1 w-max max-h-56 overflow-auto z-10 bg-blue-alice border-blue-hawkes text-black-pearl text-xs font-semibold cursor-pointer border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover">
                          {country.map((country) => (
                            <div key={country.countryId} className="py-2 px-3 hover:bg-gray-300 hover:text-black-pearl text-sm cursor-pointer flex items-center" onClick={() => handleCountryCodeChange(country)}>
                              <img src={country.flagImageUrl} alt={`${country.countryName} flag`} className="w-5 mr-2" />
                              <span>{country.countryName} ({country.dialingCode})</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <label className="block border-blue-hawkes px-4 py-2 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover w-[72%]">
                      <span className="block text-xs font-semibold text-black-pearl">Mobile number</span>
                      <input
                        type="number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        autoComplete="off"
                        className="placeholder-grey-storm  bg-blue-alice focus:outline-none text-sm w-full pt-1"
                        placeholder="Enter your phone number"
                      />
                    </label>
                  </div>

                  <label className="block border-blue-hawkes px-4 py-2 mt-6 bg-blue-alice border shadow-sm focus:outline-none focus:border-blue-rock focus:ring-blue-rock hover:border-hover">
                    <span className="after:ml-0.5  after:text-red-500 block text-xs font-semibold text-black-pearl"> Referral code </span>
                    <input type="text" name="referralCode" className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1"
                      value={register.referralCode} onChange={inputChange} onKeyDown={handleKeyDown} placeholder="Enter code here" />
                  </label>
                  <button
                    type="submit"
                    disabled={loading}
                    className={`relative w-full flex items-center justify-center py-3.5 cursor-pointer font-bold text-sm transition-all text-white mt-4 sm:mt-8.5 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] outline-none ${loading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                    {loading ? (
                      <p className='flex items-center'>
                        <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                        </span>
                        Creating Account...
                      </p>
                    ) : (
                      'CREATE AN ACCOUNT'
                    )}
                  </button>
                  <p className="text-red-500 text-xs font-semibold mt-1 absolute">{formResponseError}</p>
                </form>
                <div className="relative my-6 flex justify-center items-center">
                  <span className="w-[45%] h-px bg-mabel absolute right-0"></span>
                  <p className="font-roboto text-blue-teal tracking-0.16">Or</p>
                  <span className="w-[45%] h-px bg-mabel absolute left-0"></span>
                </div>
                <div className="flex">
                  <div className="w-full flex gap-6">
                    <button
                      type="button" onClick={loginWithGoogle}
                      className="bg-pink-pig text-cinnabar font-sora text-xs font-semibold w-full py-3.5 flex justify-center items-center uppercase outline-none hover:bg-rose focus-visible:bg-rose btn-hover-animation">
                      <img
                        className="mr-2 w-5"
                        src={Images.googleicon}
                        alt="Google icon" />
                      <span>Google</span>
                    </button>
                  </div>
                </div>
                <div className="mt-9">
                  <p className="text-base font-normal text-center"> Already have an account? <a href="/" className="outline-none"> <span className="text-base font-semibold underline">Log in</span></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;