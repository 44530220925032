import React, { createContext, useContext, useState, ReactNode } from 'react';

interface DataProviderProps {
    children: ReactNode;
}

interface CustomObject {
    id: number;
    message: string;
}

interface Data {
    currentAsset: any;
    updateCurrentAsset: (newObject: any) => void;
    socketMessages: CustomObject[];
    addMessage: (newObject: CustomObject) => void;
    removeMessage: (objectId: number) => void;
}

const DataContext = createContext<Data | undefined>(undefined);

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    const [socketMessages, setSocketMessages] = useState<CustomObject[]>([]);
    const [currentAsset, setCurrentAsset] = useState<any>();

    const updateCurrentAsset = (newObject: any) => {
        setCurrentAsset(newObject);
    };

    const addMessage = (newObject: CustomObject) => {
        setSocketMessages([...socketMessages, newObject]);
    };

    const removeMessage = (objectId: number) => {
        const updatedList = socketMessages.filter((obj) => obj.id !== objectId);
        setSocketMessages(updatedList);
    };

    return (
        <DataContext.Provider value={{ currentAsset, updateCurrentAsset, socketMessages, addMessage, removeMessage }}>
            {children}
        </DataContext.Provider>
    );
};

export const useAssetMarketWatchData = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};