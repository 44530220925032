/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from 'react';
import Images from "../assets/images/index";
import { useFetchUser, useSendVerificationCode } from '../services/user.service';
import { useUpdateUserExchangeAccountProcessStatus, useVerifyExchangeVerificationCode } from '../services/exchange.service';
import { useNavigate } from 'react-router-dom';
import { storage } from '../utils/storage';
import IResponse from '../types/response';
import { IUser } from "../types/user";
import { useOtp } from '../helpers/otp'; // Adjust the path accordingly
import useRenewAccessToken, { statusWiseOnboardingRedirectToPage, isUserExchangeStatusHasPermission, isNotNullUndefinedBlank, isTokenValid, isNotNullUndefined } from '../helpers/common';
import * as Sentry from "@sentry/react";


const CompleteAccount = () => {
    const navigate = useNavigate();
    const userExchangeStatusCode = useRef("");
    const userExchangeAccountId = useRef("");
    const [isVerifying, setIsVerifying] = useState(false);
    const { codes, handleOtpInput, handleOtpKeyDown, resendTimer, resetTimer, handleOtpPaste } = useOtp();
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState<IUser>({
        userId: '',
        userRoleId: 0,
        userRoleName: '',
        email: '',
        userName: '',
        phoneNumber: '',
        countryId: 0,
        dialingCode: '',
        isActive: false,
        exchangeAccounts: []
    });
    const [verifyKeyError, setVerifyKeyError] = useState('');
    const [formResponseError, setFormResponseError] = useState('');
    const isSuccessResponse = useRef(false);
    const checkValidation = (field: string) => {

        let isValid = true;
        if (field === "verifyKeyError") {
            if (codes.every(x => x === "")) {
                setVerifyKeyError('Please enter OTP');
                isValid = false;
            } else {
                setVerifyKeyError('');
            }
        }
        return isValid;
    };
    const renewAccessToken = useRenewAccessToken();

    useEffect(() => {
        fetchUser();
        userExchangeAccountId.current = storage.getUserExchangeAccountId() ?? "";
        if (isUserExchangeStatusHasPermission(8)) {
            navigate(statusWiseOnboardingRedirectToPage());
        } else {
            document.getElementById(`code-0`)?.focus();
        }
        autoVerifyOTP();
    }, [codes]);

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                setUser(response.data);
            }
            if (!isTokenValid(response)) {
                setIsLoading(false);
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    fetchUser();
                }
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const updateExchangeProcessStatusMutation = useUpdateUserExchangeAccountProcessStatus({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                if (userExchangeStatusCode.current === "EXCHANGE_CONSENT_VERIFIED") {
                    storage.setUserExchangeStatusId(6);
                    setTimeout(() => {
                        userExchangeStatusCode.current = "ACTIVE";
                        updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId.current, "statusCode": userExchangeStatusCode.current });
                    }, 100);
                    navigate('/dashboard');
                }
                if (userExchangeStatusCode.current === "ACTIVE") {
                    storage.setUserExchangeStatusId(1);
                }
            }
            if (!isTokenValid(response)) {
                setIsLoading(false);
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId.current, "statusCode": userExchangeStatusCode.current });
                }
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const verifiyVerificationCodeMutation = useVerifyExchangeVerificationCode({
        onSuccess: async (response: IResponse) => {
            if (response.statusCode === 200) {
                userExchangeStatusCode.current = "EXCHANGE_CONSENT_VERIFIED";
                updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId.current, "statusCode": userExchangeStatusCode.current });
            }
            if (!isTokenValid(response)) {
                setIsLoading(false);
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    let code = "";
                    codes.forEach(x => { code += parseInt(x); });
                    verifiyVerificationCodeMutation.mutate({ userId: storage.getUserId(), email: storage.getEmailId(), verificationCode: code.replace(/\s+/g, ''), codeType: "" });
                }
            }
            else {
                setIsLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const onVerify = async () => {
        if (checkValidation("verifyKeyError")) {
            setIsLoading(true);
            let code = codes.join('');
            storage.clearUserReferralCode();
            setIsVerifying(true);
            await verifiyVerificationCodeMutation.mutate({
                userId: storage.getUserId(),
                email: storage.getEmailId(),
                verificationCode: code.replace(/\s+/g, ''),
                codeType: ""
            });
            setIsVerifying(false);
        }
    };

    const reSendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: () => {
            isSuccessResponse.current = true;
            setFormResponseError("OTP sent");
            setTimeout(() => { isSuccessResponse.current = false; setFormResponseError(""); }, 5000);
        },
        onError: (error) => {
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const onResend = () => {
        let codeType = (isNotNullUndefinedBlank(user.phoneNumber) && isNotNullUndefinedBlank(user.email)) ? "both" : isNotNullUndefinedBlank(user.phoneNumber) ? "sms" : isNotNullUndefinedBlank(user.email) ? "email" : "";
        reSendVerificationCodeMutation.mutate({ userId: user.userId, email: user.email, codeType: codeType });
        resetTimer();
    };

    const autoVerifyOTP = async () => {
        if (codes.join('').length === 6 && !isVerifying) {
            await onVerify();
        }
    };

    return (
        <div className="bg-black-russian">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px] flex justify-center'>
                                <div className='flex justify-center bg-contain my-8 w-4/5 xl:w-[90%] lg:my-[100px]'>
                                    <img
                                        className="w-full bg-no-repeat"
                                        src={Images.verify}
                                        draggable="false"
                                        alt=" verify gif" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right bg-white flex justify-center items-center rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className="w-[340px] 2xl:w-[388px]">
                        <div className="flex justify-center">
                            <img src={Images.signin} draggable="false" alt="Sign in" className="md:w-72 w-56" />
                        </div>
                        <form onSubmit={onVerify}>
                            <div className="mt-12 sm:mt-14">
                                <h4 className="text-lg text-center font-bold ">
                                    Complete Your Account
                                </h4>
                                <p className="text-center text-sm md:text-15 font-normal sm:leading-6 text-grey-storm mt-6">
                                    Enter the 6-digit verification code sent to
                                    {user.phoneNumber && <span className='font-semibold mx-1'>{user.dialingCode} {user.phoneNumber}</span>}
                                    {user.email && user.phoneNumber && ' and '}
                                    {user.email && <span className='font-semibold mx-1'>{user.email}</span>}
                                    {user.phoneNumber && !user.email && ' to'}
                                    complete your trading account set up.
                                </p>
                            </div>
                            <div
                                id="otp"
                                className="flex flex-row justify-center text-center px-2 mt-10 relative">
                                {codes.map((digit, index) => (
                                    <input
                                        key={index}
                                        className="m-1 sm:m-2 border border-blue-rock h-9 w-9 md:h-12 md:my-2 md:mx-3 md:w-12 text-center form-control rounded"
                                        type="text"
                                        id={`code-${index}`}
                                        maxLength={1}
                                        value={digit}
                                        onBlur={() => checkValidation("verifyKeyError")}
                                        onChange={(e) => handleOtpInput(e, index)}
                                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                        onPaste={(e) => handleOtpPaste(e, index)}
                                        //onKeyUp={(e) => autoVerifyOTP()}
                                    />
                                ))}
                                {verifyKeyError && <p className="text-red-500 text-xs -bottom-4 font-semibold text-center absolute">{verifyKeyError}</p>}
                            </div>
                            <div className="mt-16 max-[1440px]:mt-8 relative">
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`w-full flex justify-center items-center py-3.5 font-bold text-sm text-white btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] bg-gradient-hover outline-none ${isLoading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                    {isLoading ? (
                                        <p className='flex items-center'>
                                            <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                                            </span>
                                            Verifying OTP...
                                        </p>
                                    ) : (
                                        'VERIFY & CONTINUE'
                                    )}
                                </button>
                                <p className={`${!isSuccessResponse.current ? 'text-persian' : 'text-emerald'} text-xs mt-1 text-center font-semibold absolute w-full`}>{formResponseError}</p>
                                <div className="mt-6 flex justify-center items-center">
                                    {resendTimer > 0 ? (
                                        <p className="font-normal text-13 text-gray-400">
                                            Resend OTP in {resendTimer}s
                                        </p>
                                    ) : (
                                        <button onClick={onResend} type="button"
                                            className="font-normal text-13 text-gray-400 outline-none">
                                            Resend OTP
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompleteAccount;