import { ChangeEvent, useEffect, useRef, useState } from "react";
import Images from "../assets/images";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { IExchangeAccountAssetOrders, IUserExchangeAsset } from "../types/exchange";
import useRenewAccessToken, { handleError, isNotNullUndefined, isTokenValid, isUserStatusHasPermission } from "../helpers/common";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserAssetOrders, useCreateManualCloseTrade, useCreateManualOpenTrade, usePatchUserExchangeAsset, useGetUserExchangeAssets, useGetExchangeAssetByExchangeSymbolAndExchangeId, useRestartCoin } from "../services/exchange.service";
import IResponse from "../types/response";
import WebSocketManager, { MessageHandler } from '../helpers/websocketmanager';
import { storage } from "../utils/storage";
import * as Sentry from "@sentry/react";

interface CoinData {
    coin: string;
    data: any;
}

const Coinaction = () => {
    const assetDotStyle = {
        width: "6px",
        height: "6px",
    };
    const assetActiveDotStyle = {
        width: "8px",
        height: "8px",
    };
    const marks = {
        5: "5x",
        10: "10x",
        15: "15x",
        20: "20x",
    };

    const navigate = useNavigate();
    const { symbol } = useParams();
    const url = useRef("wss://stream.binancefuture.com/stream");
    const aggTrade = useRef("");
    const [coinData, setCoinData] = useState<CoinData[]>([]);
    const marketPrice = useRef("");
    const userExchangeAccountId = useRef("");
    const assetId = useRef("");
    const renewAccessToken = useRenewAccessToken();

    const [isManualActionCollapse, setManualActionCollapse] = useState(false);
    const [isCoinSetupCollapse, setCoinSetupCollapse] = useState(false);
    const [isCoinActionCollapse, setCoinActionCollapse] = useState(false);
    const [isRestartCoinCollapse, setRestartCoinCollapse] = useState(false);
    const [manualClosePosition, setManualClosePosition] = useState("long");
    const [manualOpenPosition, setManualOpenPosition] = useState("long");
    const [longExchangeAccountAssetOrders, setLongExchangeAccountAssetOrders] = useState<IExchangeAccountAssetOrders[]>([]);
    const [shortExchangeAccountAssetOrders, setShortExchangeAccountAssetOrders] = useState<IExchangeAccountAssetOrders[]>([]);
    const longAveragePrice = useRef(0);
    const longOrderLevel = useRef(0);
    const longAssetQuantity = useRef(0);
    const longQuoteQuantity = useRef(0);
    const longTotalAmount = useRef(0);
    const longUnrealizedPnL = useRef(0);
    const longUnrealizedPnLPercentage = useRef(0);

    const shortAveragePrice = useRef(0);
    const shortOrderLevel = useRef(0);
    const shortAssetQuantity = useRef(0);
    const shortQuoteQuantity = useRef(0);
    const shortTotalAmount = useRef(0);
    const shortUnrealizedPnL = useRef(0);
    const shortUnrealizedPnLPercentage = useRef(0);

    const [longAmount, setLongAmount] = useState("");
    const [shortAmount, setShortAmount] = useState("");

    const [originalUserExchangeAsset, setOriginalUserExchangeAsset] = useState<IUserExchangeAsset>({
        userExchangeAccountId: "",
        assetId: "",
        strategyId: 0,
        IsContinuousCycle: false,
        positionSide: "",
        firstLongOrderAmount: 0,
        firstShortOrderAmount: 0,
        stopLoss: 0,
        leverage: 0,
        isDoubleFirstLongOrder: false,
        isDoubleFirstShortOrder: false
    });

    const [userExchangeAsset, setUserExchangeAsset] = useState<IUserExchangeAsset>({
        userExchangeAccountId: "",
        assetId: "",
        strategyId: 0,
        IsContinuousCycle: false,
        positionSide: "",
        firstLongOrderAmount: 0,
        firstShortOrderAmount: 0,
        stopLoss: 0,
        leverage: 0,
        isDoubleFirstLongOrder: false,
        isDoubleFirstShortOrder: false
    });

    const [isInfinite, setInfinite] = useState(true);

    useEffect(() => {
        if (isUserStatusHasPermission(1)) {
            navigate('/');
        } else {
            onGetExchangeAssetByExchangeSymbolAndExchangeId();
            userExchangeAccountId.current = storage.getUserExchangeAccountId()?.toString() ?? "";
        }
        aggTrade.current = symbol?.toLowerCase() + "@aggTrade";
        url.current = url.current + "?streams=" + aggTrade.current;
        binanceWebsockets();
    }, []);

    const binanceWebsockets = () => {
        const webSocketManager = new WebSocketManager();
        webSocketManager.connect(symbol ?? "", url.current);

        const promise = async () => { await webSocketManager.connect(symbol ?? "", url.current); };

        const openSockets = async () => {
            const promises = [promise()];
            await Promise.all(promises);

            webSocketManager.addMessageHandler(symbol ?? "", handleWebSocketMessage);
        };

        openSockets();

        return () => {
            webSocketManager.disconnect(symbol ?? "");
        };
    };

    const handleWebSocketMessage: MessageHandler = (coin, message) => {
        if (message.stream === aggTrade.current) {
            setCoinData((prevCoinData) => {
                const index = prevCoinData.findIndex((data) => data.coin === coin);
                if (index !== -1) {
                    marketPrice.current = parseFloat(message.data.p).toFixed(2);
                    prevCoinData[index].data = message;
                    return [...prevCoinData];
                }
                else {
                    marketPrice.current = parseFloat(message.data.p).toFixed(2);
                    return [...prevCoinData, { coin, data: message }];
                }
            });
        }
    };

    const onGetExchangeAssetByExchangeSymbolAndExchangeId = async () => {
        var param = {
            "exchangeSymbol": symbol?.toUpperCase(),
            "exchangeId": 1
        };
        getExchangeAssetByExchangeSymbolAndExchangeId.mutate(param);
    };

    const getExchangeAssetByExchangeSymbolAndExchangeId = useGetExchangeAssetByExchangeSymbolAndExchangeId({
        onSuccess: async (response: IResponse) => {
            if (response.data !== null && response.data !== undefined) {
                assetId.current = response.data.assetId;
                onGetUserAssetOrders(assetId.current);
                onGetUserExchangeAssets(assetId.current);
            }
            if (!isTokenValid(response)) {
                let tokenRenewed = await renewAccessToken();
                if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
                    onGetExchangeAssetByExchangeSymbolAndExchangeId();
                }
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    //#region Manual Close Start

    const onGetUserAssetOrders = async (assetId: string) => {
        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId
        };
        getUserAssetOrders.mutate(param);
    };

    const getUserAssetOrders = useGetUserAssetOrders({
        onSuccess: (response: IResponse) => {
            if (response.data !== null && response.data !== undefined) {
                const allExchangeAccountAssetOrders = response.data.orders;
                if (allExchangeAccountAssetOrders !== null && allExchangeAccountAssetOrders !== undefined && allExchangeAccountAssetOrders.length > 0) {
                    const longOrders = allExchangeAccountAssetOrders.filter((x: { positionSide: string; }) => x.positionSide.toLowerCase() === "long");
                    const shortOrders = allExchangeAccountAssetOrders.filter((x: { positionSide: string; }) => x.positionSide.toLowerCase() === "short");
                    if (longOrders.length > 0) {
                        longOrders.forEach((x: { isOrderSelected: boolean; }) => x.isOrderSelected = true);
                        longAssetQuantity.current = longOrders.reduce((assetQuantity: any, x: { assetQuantity: any; }) => {
                            return (assetQuantity + x.assetQuantity);
                        }, 0);
                        longQuoteQuantity.current = longOrders.reduce((quoteQuantity: any, x: { quoteQuantity: any; }) => {
                            return (quoteQuantity + x.quoteQuantity);
                        }, 0);
                        longTotalAmount.current = longOrders.reduce((quoteQuantity: any, x: { quoteQuantity: any; }) => {
                            return (quoteQuantity + x.quoteQuantity);
                        }, 0);
                        longAveragePrice.current = longQuoteQuantity.current / longAssetQuantity.current;
                        longOrderLevel.current = parseInt(((longOrders.length * 100) / 12).toString());
                        setLongExchangeAccountAssetOrders(longOrders.sort((a: { orderLevel: number; }, b: { orderLevel: number; }) => a.orderLevel - b.orderLevel));
                    }
                    if (shortOrders.length > 0) {
                        shortOrders.forEach((x: { isOrderSelected: boolean; }) => x.isOrderSelected = true);
                        shortAssetQuantity.current = shortOrders.reduce((assetQuantity: any, x: { assetQuantity: any; }) => {
                            return (assetQuantity + x.assetQuantity);
                        }, 0);
                        shortQuoteQuantity.current = shortOrders.reduce((quoteQuantity: any, x: { quoteQuantity: any; }) => {
                            return (quoteQuantity + x.quoteQuantity);
                        }, 0);
                        shortTotalAmount.current = shortOrders.reduce((quoteQuantity: any, x: { quoteQuantity: any; }) => {
                            return (quoteQuantity + x.quoteQuantity);
                        }, 0);
                        shortAveragePrice.current = shortQuoteQuantity.current / shortAssetQuantity.current;
                        shortOrderLevel.current = parseInt(((shortOrders.length * 100) / 12).toString());
                        setShortExchangeAccountAssetOrders(shortOrders.sort((a: { orderLevel: number; }, b: { orderLevel: number; }) => a.orderLevel - b.orderLevel));
                    }
                }
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    const onChangeOrderLevels = (orderIndex: number) => {
        if (manualClosePosition === "long") {
            const updatedLongExchangeAccountAssetOrders = [...longExchangeAccountAssetOrders];
            updatedLongExchangeAccountAssetOrders[orderIndex].isOrderSelected = !longExchangeAccountAssetOrders[orderIndex].isOrderSelected;
            var selectedLongOrders = updatedLongExchangeAccountAssetOrders.filter(x => x.isOrderSelected);
            longAssetQuantity.current = selectedLongOrders.length > 0 ? (selectedLongOrders.reduce((assetQuantity, x) => {
                return (assetQuantity + x.assetQuantity);
            }, 0)) : 0;
            longQuoteQuantity.current = selectedLongOrders.length > 0 ? (selectedLongOrders.reduce((quoteQuantity, x) => {
                return (quoteQuantity + x.quoteQuantity);
            }, 0)) : 0;
            longTotalAmount.current = selectedLongOrders.length > 0 ? (selectedLongOrders.reduce((quoteQuantity, x) => {
                return (quoteQuantity + x.quoteQuantity);
            }, 0)) : 0;
            longAveragePrice.current = longQuoteQuantity.current === 0 && longAssetQuantity.current === 0 ? 0 : longQuoteQuantity.current / longAssetQuantity.current;
            longOrderLevel.current = selectedLongOrders.length > 0 ? parseInt(((selectedLongOrders.length * 100) / 12).toString()) : 0;
            setLongExchangeAccountAssetOrders(updatedLongExchangeAccountAssetOrders);
        }
        else if (manualClosePosition === "short") {
            var updatedShortExchangeAccountAssetOrders = [...shortExchangeAccountAssetOrders];
            updatedShortExchangeAccountAssetOrders[orderIndex].isOrderSelected = !shortExchangeAccountAssetOrders[orderIndex].isOrderSelected;
            var selectedShortOrders = updatedShortExchangeAccountAssetOrders.filter(x => x.isOrderSelected);
            shortAssetQuantity.current = selectedShortOrders.length > 0 ? (selectedShortOrders.reduce((assetQuantity, x) => {
                return (assetQuantity + x.assetQuantity);
            }, 0)) : 0;
            shortQuoteQuantity.current = selectedShortOrders.length > 0 ? (selectedShortOrders.reduce((quoteQuantity, x) => {
                return (quoteQuantity + x.quoteQuantity);
            }, 0)) : 0;
            shortTotalAmount.current = selectedShortOrders.length > 0 ? (selectedShortOrders.reduce((quoteQuantity, x) => {
                return (quoteQuantity + x.quoteQuantity);
            }, 0)) : 0;
            shortAveragePrice.current = shortQuoteQuantity.current === 0 && shortAssetQuantity.current === 0 ? 0 : shortQuoteQuantity.current / shortAssetQuantity.current;
            shortOrderLevel.current = selectedShortOrders.length > 0 ? parseInt(((selectedShortOrders.length * 100) / 12).toString()) : 0;
            setShortExchangeAccountAssetOrders(updatedShortExchangeAccountAssetOrders);
        }

        if (parseFloat(marketPrice.current) > 0) {
            calculateUnrealizedPrice();
        }
    };

    useEffect(() => {
        if (parseFloat(marketPrice.current) > 0) {
            calculateUnrealizedPrice();
        }
    }, [marketPrice.current]);

    const calculateUnrealizedPrice = () => {
        longUnrealizedPnL.current = (parseFloat(marketPrice.current) - longAveragePrice.current) * longAssetQuantity.current;
        longUnrealizedPnLPercentage.current = longUnrealizedPnL.current === 0 && longQuoteQuantity.current === 0 ? 0 : (longUnrealizedPnL.current / longQuoteQuantity.current) * 100;

        shortUnrealizedPnL.current = (shortAveragePrice.current - parseFloat(marketPrice.current)) * shortAssetQuantity.current;
        shortUnrealizedPnLPercentage.current = shortUnrealizedPnL.current === 0 && shortQuoteQuantity.current === 0 ? 0 : (shortUnrealizedPnL.current / shortQuoteQuantity.current) * 100;
    };

    const areOrdersInDescendingOrder = (orders: any) => {
        const selectedOrders = orders.filter((x: { isOrderSelected: any; }) => x.isOrderSelected).sort((a: { orderLevel: number; }, b: { orderLevel: number; }) => b.orderLevel - a.orderLevel);
        const descendingOrders = orders.filter((x: { isOrderSelected: any; }) => x.isOrderSelected || !x.isOrderSelected).sort((a: { orderLevel: number; }, b: { orderLevel: number; }) => b.orderLevel - a.orderLevel);

        for (let i = 0; i < descendingOrders.length; i++) {
            if (selectedOrders[i] !== null && selectedOrders[i] !== undefined) {
                if (selectedOrders[i].orderLevel < descendingOrders[i].orderLevel) {
                    return false;
                }
            }
        }
        return true;
    };

    const onCreateManualCloseTrade = (isCloseAll: boolean) => {
        if ((longExchangeAccountAssetOrders.length <= 0 && manualClosePosition === "long") || (shortExchangeAccountAssetOrders.length <= 0 && manualClosePosition === "short")) {
            alert("No order to close.")
            return false;
        }
        var orderIds: any = [];
        if (isCloseAll) {
            if (manualClosePosition === "long") {
                orderIds = longExchangeAccountAssetOrders.map((y) => y.orderId);
            }
            else if (manualClosePosition === "short") {
                orderIds = shortExchangeAccountAssetOrders.map((y) => y.orderId);
            }
        }
        else {
            if (manualClosePosition === "long") {
                if (longExchangeAccountAssetOrders.filter((x) => x.isOrderSelected).length <= 0) {
                    alert("No order is selected to close.");
                    return false;
                }
                if (!areOrdersInDescendingOrder(longExchangeAccountAssetOrders)) {
                    alert("Selected order must be in descending order.");
                    return false;
                }
                orderIds = longExchangeAccountAssetOrders.filter((x) => x.isOrderSelected).map((y) => y.orderId);
            }
            else if (manualClosePosition === "short") {
                if (shortExchangeAccountAssetOrders.filter((x) => x.isOrderSelected).length <= 0) {
                    alert("No order is selected to close.");
                    return false;
                }
                if (!areOrdersInDescendingOrder(shortExchangeAccountAssetOrders)) {
                    alert("Selected order must be in descending order.");
                    return false;
                }
                orderIds = shortExchangeAccountAssetOrders.filter((x) => x.isOrderSelected).map((y) => y.orderId);
            }
        }
        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId.current,
            "orders": orderIds
        };
        createManualCloseTrade.mutate(param);
    };

    const createManualCloseTrade = useCreateManualCloseTrade({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                alert(response.statusMessage);
                onGetUserAssetOrders(assetId.current);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        },
    });

    //#endregion  Manual Close End

    //#region Manual Open Start

    const onChangeManualOpenPositionTab = (positionType: string) => {
        setManualOpenPosition(positionType);
    };

    const onChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "longAmount")
            setLongAmount(value);
        else if (name === "shortAmount")
            setShortAmount(value);
    };

    const onCreateManualOpenTrade = () => {
        if ((parseFloat(longAmount) <= 0 && manualOpenPosition === "long") || (parseFloat(shortAmount) <= 0 && manualOpenPosition === "short")) {
            alert("Please Enter Amount.")
            return false;
        }

        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId.current,
            "positionSide": manualOpenPosition.toUpperCase(),
            "orderAmount": manualOpenPosition.toLowerCase() === "long" ? parseFloat(longAmount) : parseFloat(shortAmount)
        };
        createManualOpenTrade.mutate(param);
    };

    const createManualOpenTrade = useCreateManualOpenTrade({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                alert(response.statusMessage);
                manualOpenPosition.toLowerCase() === "long" ? setLongAmount("") : setShortAmount("");
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        },
    });

    //#endregion Manual Open End

    //#region Coin Setup Start

    const onGetUserExchangeAssets = async (assetId: string) => {
        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId
        };
        getUserExchangeAssets.mutate(param);
    };

    const getUserExchangeAssets = useGetUserExchangeAssets({
        onSuccess: (response: IResponse) => {
            if (response.data !== null && response.data !== undefined) {
                setUserExchangeAsset(response.data);
                setOriginalUserExchangeAsset(response.data);
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "isDoubleFirstLongOrder" || name === "isDoubleFirstShortOrder") {
            setUserExchangeAsset({
                ...userExchangeAsset,
                [name]: event.target.checked
            });
        } else {
            const updatedExchangeAssets = {
                ...userExchangeAsset,
                [name]: value
            };
            setUserExchangeAsset(updatedExchangeAssets);
        }
    };

    const handleSlideChange = (value: any) => {
        setUserExchangeAsset({
            ...userExchangeAsset,
            leverage: value
        });
    };

    const getUpdatedKeys = () => {
        const differences: { [key: string]: any } = {};
        for (const key in originalUserExchangeAsset) {
            if (originalUserExchangeAsset[key as keyof IUserExchangeAsset] !== userExchangeAsset[key as keyof IUserExchangeAsset]) {
                differences[key as keyof IUserExchangeAsset] = userExchangeAsset[key as keyof IUserExchangeAsset];
            }
        }
        return differences;
    };

    const onSaveSettings = () => {
        const bodyParam = getUpdatedKeys();

        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId.current,
            "bodyParam": bodyParam,
        };
        saveSettings.mutate(param);
    };

    const saveSettings = usePatchUserExchangeAsset({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                alert(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        },
    });

    const onDiscardChanges = () => {
        setUserExchangeAsset(originalUserExchangeAsset);
    };

    //#endregion Coin Setup End

    //#region Change Strategy Start

    const onChangeStrategy = (strategyValue: boolean) => {
        setInfinite(strategyValue);
        var bodyParam = {
            "IsContinuousCycle": strategyValue,
        };

        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId.current,
            "bodyParam": bodyParam,
        };
        changeStrategy.mutate(param);
    };

    const changeStrategy = usePatchUserExchangeAsset({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                alert(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        },
    });

    //#endregion Change Strategy End

    //#region Control Next Order Start

    const onStopBot = (positionside: string) => {
        var bodyParam = {
            "StopPositionSide": positionside.toUpperCase(),
        };

        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId.current,
            "bodyParam": bodyParam,
        };
        stopBot.mutate(param);
    };

    const stopBot = usePatchUserExchangeAsset({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                alert(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        },
    });

    //#endregion Control Next Order End

    //#region Restart Coin Start

    const onRestartCoin = async (positionSide: string) => {
        var param = {
            "userExchangeAccountId": userExchangeAccountId.current,
            "assetId": assetId.current,
            "positionSide": positionSide,
            "currentPrice": 0
        };
        restartCoin.mutate(param);
    };

    const restartCoin = useRestartCoin({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                alert("Coin has been restarted.");
                onGetUserAssetOrders(assetId.current);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        },
    });

    //#endregion Restart Coin End
    return (
        <div className="py-8 px-9">
            <div className="3xl:grid 3xl:grid-cols-3 block gap-6">
                <div className="col-span-2 w-full">
                    <h2>
                        <button type="button" className="flex items-center justify-between w-full tap-highlight-color-transparent" onClick={() => setManualActionCollapse(!isManualActionCollapse)}>
                            <span className="text-2xl text-white font-semibold">Manual Action</span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-250px)]"></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`transform transition duration-700 ${isManualActionCollapse ? 'rotate-90' : 'rotate-360'}`}>
                                <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="white" />
                            </svg>
                        </button>
                    </h2>
                    <div className={`collaps-content ${longExchangeAccountAssetOrders.length <= 0 && shortExchangeAccountAssetOrders.length <= 0 ? 'h-full flex items-center justify-center' : ''} ${!isManualActionCollapse ? "collaps-open" : ""}`}>
                        {longExchangeAccountAssetOrders.length <= 0 && shortExchangeAccountAssetOrders.length <= 0 ? (
                            <div className="flex gap-4 text-white">No Orders.</div>
                        ) : (
                            <div className="flex gap-4">
                                <div className="bg-white bg-opacity-3 border-white border-opacity-20 border rounded mt-9 w-1/2">
                                    <div className="border-b-2 border-white border-opacity-10">
                                        <ul
                                            className="flex flex-wrap -mb-px text-sm font-bold text-center"
                                            id="myTab"
                                            data-tabs-toggle="#myTabContent"
                                            role="tablist">
                                            <li className="w-full">
                                                <div
                                                    className="flex p-4 w-full justify-center">
                                                    <p className="text-white w-max text-xl select-none">Manual close</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="myTabContent">
                                        <div className="border-b-2 border-white border-opacity-10">
                                            <ul
                                                className="flex flex-wrap text-base font-bold text-center"
                                                id="myTab"
                                                data-tabs-toggle="#myTabContent"
                                                role="tablist">
                                                {longExchangeAccountAssetOrders.length > 0 ? (
                                                    <li className={`${shortExchangeAccountAssetOrders.length > 0 && longExchangeAccountAssetOrders.length > 0 ? 'w-1/2' : 'w-full'}`} onClick={() => setManualClosePosition("long")}>
                                                        <button
                                                            type="button" className={`p-4 w-full ${manualClosePosition === "long" ? 'flex justify-center border-bottom bg-gradient text-gradient bg-clip-text border-b-2' : ''}`}>
                                                            <p className={`${manualClosePosition === "long" ? 'bg-gradient text-gradient bg-clip-text w-max' : 'text-white font-normal'}`}>Long</p>
                                                        </button>
                                                    </li>
                                                ) : ("")}
                                                {shortExchangeAccountAssetOrders.length > 0 ? (
                                                    <li className={`${shortExchangeAccountAssetOrders.length > 0 && longExchangeAccountAssetOrders.length > 0 ? 'w-1/2' : 'w-full'}`} onClick={() => setManualClosePosition("short")}>
                                                        <button
                                                            type="button" className={`p-4 w-full ${manualClosePosition === "short" ? 'flex justify-center border-bottom bg-gradient text-gradient bg-clip-text border-b-2' : ''}`}>
                                                            <p className={`${manualClosePosition === "short" ? 'bg-gradient text-gradient bg-clip-text w-max' : 'text-white font-normal'}`}>Short</p>
                                                        </button>
                                                    </li>
                                                ) : ("")}
                                            </ul>
                                        </div>
                                    </div>
                                    {manualClosePosition === "long" ? (
                                        <div className="p-5">
                                            <div className="mb-4">
                                                <p className="text-base text-white font-semibold text-center">Order levels</p>
                                            </div>
                                            <div className="flex gap-3.5 flex-wrap">
                                                {longExchangeAccountAssetOrders.map((order: any, index) => (
                                                    <p key={index} className={`w-8.5 h-8.5 rounded-full flex items-center justify-center text-white text-15 font-medium cursor-pointer ${order.isOrderSelected ? 'bg-gradient' : 'bg-grey-tuna'}`}
                                                        onClick={() => onChangeOrderLevels(index)}>{order.orderLevel}</p>
                                                ))}
                                            </div>
                                            <div className="mt-5 grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Total amount
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        ${longTotalAmount.current}
                                                    </p>
                                                </div>
                                                <div className="col-span-2">
                                                    <p className="text-sm font-normal text-grey-suit">
                                                        Order level
                                                    </p>
                                                    <div>
                                                        <Slider
                                                            className="custom-slider order-level coin-renge mt-2 mb-4 w-[97%]"
                                                            min={1}
                                                            max={100}
                                                            value={longOrderLevel.current}
                                                            step={1}
                                                            dotStyle={{ display: 'none' }}
                                                            activeDotStyle={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Average price
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        ${longAveragePrice.current.toFixed(2)}
                                                    </p>
                                                </div>
                                                <div className="col-span-2">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Floating P & L
                                                    </p>
                                                    {
                                                        longUnrealizedPnL.current >= 0 ? (
                                                            <p className="text-sm font-semibold text-emerald mt-2.5 flex items-center">
                                                                ${longUnrealizedPnL.current.toFixed(2)}
                                                                <span className="bg-holly text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                                    {longUnrealizedPnL.current.toFixed(2)}%
                                                                </span>
                                                            </p>) : (
                                                            <p className="text-sm font-semibold text-persian mt-2.5 flex items-center">
                                                                ${longUnrealizedPnL.current.toFixed(2)}
                                                                <span className="bg-jazz  text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                                    {longUnrealizedPnL.current.toFixed(2)}%
                                                                </span>
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="mt-5 grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Order amount($)
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        ${longQuoteQuantity.current.toFixed(2)}
                                                    </p>
                                                </div>
                                                <div className="col-span-2">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Order amount(coin)
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        {longAssetQuantity.current.toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-11 mt-9">
                                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2.5 text-15 text-white font-semibold" onClick={() => onCreateManualCloseTrade(false)}>CLOSE SELECTED</button>
                                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2.5 text-15 text-white font-semibold" onClick={() => onCreateManualCloseTrade(true)}>CLOSE ALL</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="p-5">
                                            <div className="mb-4">
                                                <p className="text-base text-white font-semibold text-center">Order levels</p>
                                            </div>
                                            <div className="flex gap-3.5 flex-wrap">
                                                {shortExchangeAccountAssetOrders.map((order: any, index) => (
                                                    <p key={index} className={`w-8.5 h-8.5 rounded-full flex items-center justify-center text-white text-15 font-medium cursor-pointer ${order.isOrderSelected ? 'bg-gradient' : 'bg-grey-tuna'}`}
                                                        onClick={() => onChangeOrderLevels(index)}>{order.orderLevel}</p>
                                                ))}
                                            </div>
                                            <div className="mt-5 grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Total amount
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        ${shortTotalAmount.current}
                                                    </p>
                                                </div>
                                                <div className="col-span-2">
                                                    <p className="text-sm font-normal text-grey-suit">
                                                        Order level
                                                    </p>
                                                    <div>
                                                        <Slider
                                                            className="custom-slider order-level coin-renge mt-2 mb-4 w-[97%]"
                                                            min={1}
                                                            max={100}
                                                            value={shortOrderLevel.current}
                                                            step={1}
                                                            dotStyle={{ display: 'none' }}
                                                            activeDotStyle={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Average price
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        ${shortAveragePrice.current.toFixed(2)}
                                                    </p>
                                                </div>
                                                <div className="col-span-2">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Floating P & L
                                                    </p>
                                                    {
                                                        shortUnrealizedPnL.current >= 0 ? (
                                                            <p className="text-sm font-semibold text-emerald mt-2.5 flex items-center">
                                                                ${shortUnrealizedPnL.current.toFixed(2)}
                                                                <span className="bg-holly text-emerald text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                                    {shortUnrealizedPnL.current.toFixed(2)}%
                                                                </span>
                                                            </p>) : (
                                                            <p className="text-sm font-semibold text-persian mt-2.5 flex items-center">
                                                                ${shortUnrealizedPnL.current.toFixed(2)}
                                                                <span className="bg-jazz  text-10 py-1 px-1.5 rounded-full ml-2.5">
                                                                    {shortUnrealizedPnL.current.toFixed(2)}%
                                                                </span>
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="mt-5 grid grid-cols-3 gap-4">
                                                <div className="col-span-1">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Order amount($)
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        ${shortQuoteQuantity.current.toFixed(2)}
                                                    </p>
                                                </div>
                                                <div className="col-span-2">
                                                    <p className="text-xs font-normal text-grey-suit">
                                                        Order amount(coin)
                                                    </p>
                                                    <p className="text-sm font-semibold text-white mt-2.5">
                                                        {shortAssetQuantity.current.toFixed(2)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-11 mt-9">
                                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2.5 text-15 text-white font-semibold" onClick={() => onCreateManualCloseTrade(false)}>CLOSE SELECTED</button>
                                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2.5 text-15 text-white font-semibold" onClick={() => onCreateManualCloseTrade(true)}>CLOSE ALL</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="bg-white bg-opacity-3 border-white border-opacity-20 border rounded mt-9 w-1/2">
                                    <div className="border-b-2 border-white border-opacity-10">
                                        <ul
                                            className="flex flex-wrap -mb-px text-sm font-bold text-center"
                                            id="myTab"
                                            data-tabs-toggle="#myTabContent"
                                            role="tablist">
                                            <li className="w-full">
                                                <div
                                                    className="flex p-4 w-full justify-center">
                                                    <p className="text-white w-max text-xl select-none">Manual open</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="myTabContent">
                                        <div className="border-b-2 border-white border-opacity-10">
                                            <ul
                                                className="flex flex-wrap text-base font-bold text-center"
                                                id="myTab"
                                                data-tabs-toggle="#myTabContent"
                                                role="tablist">
                                                <li className="w-1/2" onClick={() => onChangeManualOpenPositionTab("long")}>
                                                    <button
                                                        type="button" className={`p-4 w-full ${manualOpenPosition === "long" ? 'flex justify-center border-bottom bg-gradient text-gradient bg-clip-text border-b-2' : ''}`}>
                                                        <p className={`${manualOpenPosition === "long" ? 'bg-gradient text-gradient bg-clip-text w-max' : 'text-white font-normal'}`}>Long</p>
                                                    </button>
                                                </li>
                                                <li className="w-1/2" onClick={() => onChangeManualOpenPositionTab("short")}>
                                                    <button
                                                        type="button" className={`p-4 w-full ${manualOpenPosition === "short" ? 'flex justify-center border-bottom bg-gradient text-gradient bg-clip-text border-b-2' : ''}`}>
                                                        <p className={`${manualOpenPosition === "short" ? 'bg-gradient text-gradient bg-clip-text w-max' : 'text-white font-normal'}`}>Short</p>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {manualOpenPosition === "long" ? (
                                        <div className="p-5">
                                            <div className="">
                                                <p className="text-base text-white font-semibold text-center">Open manual order</p>
                                            </div>
                                            <div className="w-64 h-40 mx-auto my-0">
                                                <img src={Images.manualopen} alt="manualopen" className="w-full h-full" />
                                            </div>
                                            <div className="w-full xl:mr-4 mr-4 mt-2.5">
                                                <div className="flex items-center mb-4">
                                                    <p className="block text-sm font-normal text-grey-nobel mr-2">
                                                        Please Note: Making manual coin purchases can disrupt your automatic order cycle
                                                    </p>
                                                </div>
                                                <div className="flex items-center justify-end">
                                                    <input
                                                        type="number"
                                                        name="longAmount"
                                                        value={longAmount}
                                                        onChange={onChangeAmount}
                                                        placeholder="Enter amount in USDT"
                                                        className="bg-black-blackRock w-full text-grey-storm font-normal text-sm block pl-2.5 pr-2.5 py-3 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-11 mt-7">
                                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2.5 text-15 text-white font-semibold" onClick={onCreateManualOpenTrade}>PLACE ORDER</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="p-5">
                                            <div className="">
                                                <p className="text-base text-white font-semibold text-center">Open manual order</p>
                                            </div>
                                            <div className="w-64 h-40 mx-auto my-0">
                                                <img src={Images.manualopen} alt="manualopen" className="w-full h-full" />
                                            </div>
                                            <div className="w-full xl:mr-4 mr-4 mt-2.5">
                                                <div className="flex items-center mb-4">
                                                    <p className="block text-sm font-normal text-grey-nobel mr-2">
                                                        Please Note: Making manual coin purchases can disrupt your automatic order cycle
                                                    </p>
                                                </div>
                                                <div className="flex items-center justify-end">
                                                    <input
                                                        type="number"
                                                        name="shortAmount"
                                                        value={shortAmount}
                                                        onChange={onChangeAmount}
                                                        placeholder="Enter amount in USDT"
                                                        className="bg-black-blackRock w-full text-grey-storm font-normal text-sm block pl-2.5 pr-2.5 py-3 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-11 mt-7">
                                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2.5 text-15 text-white font-semibold" onClick={onCreateManualOpenTrade}>PLACE ORDER</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="3xl:mt-0 mt-7">
                    <h2>
                        <button type="button" className="flex items-center justify-between w-full tap-highlight-color-transparent" onClick={() => setCoinSetupCollapse(!isCoinSetupCollapse)}>
                            <span className="text-2xl text-white font-semibold">Individual Coin Setup</span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-312px)]"></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`transform transition duration-700 ${isCoinSetupCollapse ? 'rotate-90' : 'rotate-360'}`}>
                                <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="white" />
                            </svg>
                        </button>
                    </h2>
                    <div className={`collaps-content ${!isCoinSetupCollapse ? "collaps-open" : ""}`}>
                        <div className="bg-white bg-opacity-3 border-white border-opacity-20 border rounded mt-9">
                            <div className="flex justify-center border-b-2 border-white border-opacity-10 p-4">
                                <p className="text-xl font-semibold text-white w-max">
                                    BTC coin setup
                                </p>
                            </div>
                            <div className="p-5">
                                <div className="grid grid-cols-1 gap-8">
                                    <div>
                                        <div className="grid grid-cols-2 gap-5">
                                            <div className="w-full xl:mr-4 mr-4">
                                                <div className="flex items-center mb-3">
                                                    <label htmlFor="long-order" className="block text-sm font-normal text-white mr-2">
                                                        Long order
                                                    </label>
                                                </div>
                                                <div className="flex items-center justify-end">
                                                    <span className="relative text-white left-5 font-semibold -ml-2">$</span>
                                                    <input
                                                        id="long-order"
                                                        type="number"
                                                        min={0}
                                                        name="firstLongOrderAmount"
                                                        value={userExchangeAsset.firstLongOrderAmount}
                                                        onChange={onInputChange}
                                                        className="bg-black-blackRock w-full text-white font-semibold text-sm block pl-5 pr-2.5 py-2.5 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full xl:mr-4 mr-4">
                                                <div className="flex items-center mb-3">
                                                    <label htmlFor="short-order" className="block text-sm font-normal text-white mr-2">
                                                        Short order
                                                    </label>
                                                </div>
                                                <div className="flex items-center justify-end">
                                                    <span className="relative text-white left-5 font-semibold -ml-2">$</span>
                                                    <input
                                                        id="short-order"
                                                        type="number"
                                                        min={0}
                                                        value={userExchangeAsset.firstShortOrderAmount}
                                                        onChange={onInputChange}
                                                        name="firstShortOrderAmount"
                                                        className="bg-black-blackRock w-full text-white font-semibold text-sm block pl-5 pr-2.5 py-2.5 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full xl:mr-4 mr-4">
                                                <div className="flex items-center mb-3">
                                                    <label htmlFor="stop-loss" className="block text-sm font-normal text-white mr-2">
                                                        Stop loss
                                                    </label>
                                                </div>
                                                <div className="flex items-center justify-end">
                                                    <span className="relative text-white left-5 font-semibold -ml-2">$</span>
                                                    <input
                                                        id="stop-loss"
                                                        type="number"
                                                        min={0}
                                                        value={userExchangeAsset.stopLoss}
                                                        onChange={onInputChange}
                                                        name="stopLoss"
                                                        className="bg-black-blackRock w-full text-white font-semibold text-sm block pl-5 pr-2.5 py-2.5 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-8">
                                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-3 text-xs text-white font-semibold">AUTO FILL</button>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center mt-4">
                                            <h2 className="text-sm font-normal text-white">
                                                Adjust leverage
                                            </h2>
                                            <p className="bg-gradient text-white text-xs text-center font-semibold w-9 h-5 flex items-center justify-center">{userExchangeAsset.leverage}x</p>
                                        </div>
                                        <Slider
                                            className="custom-slider range-slider coin-renge mt-2 mb-4 w-[97%]"
                                            min={5}
                                            max={20}
                                            value={userExchangeAsset.leverage}
                                            step={1}
                                            marks={marks}
                                            dotStyle={assetDotStyle}
                                            activeDotStyle={assetActiveDotStyle}
                                            onChange={(e) => handleSlideChange(e)}
                                            reverse={false} />
                                    </div>
                                </div>
                                <div className="mt-7">
                                    <p className="flex items-center justify-between w-full tap-highlight-color-transparent" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                        <span className="text-sm text-white">Enable first order sizing doubling</span>
                                        <span className="border border-t-0 border-white border-opacity-10 w-[calc(100%-258px)]"></span>
                                    </p>
                                    <div className="flex items-center mt-4">
                                        <div className="flex items-center">
                                            <span className="text-xs font-semibold text-white mr-3">
                                                For long
                                            </span>
                                            <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    name="isDoubleFirstLongOrder"
                                                    checked={userExchangeAsset.isDoubleFirstLongOrder}
                                                    onChange={onInputChange}
                                                    className="sr-only peer"
                                                />
                                                <div className="w-[32px] h-[21px] rounded-full peer bg-grey-storm peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <span className="ml-3 text-xs font-semibold text-white mr-3">
                                                For Short
                                            </span>
                                            <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={userExchangeAsset.isDoubleFirstShortOrder}
                                                    onChange={onInputChange}
                                                    name="isDoubleFirstShortOrder"
                                                    className="sr-only peer"
                                                />
                                                <div className="w-[32px] h-[21px] rounded-full peer bg-grey-storm  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-8 mt-[54px]">
                                    <button
                                        type="button" onClick={onSaveSettings}
                                        className="w-full py-2.5 border-2 border-bottom flex justify-center relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                        <p className="font-extrabold bg-gradient tracking-[0.28px] text-gradient bg-clip-text text-sm w-max flex items-center">
                                            Save Settings</p>
                                    </button>
                                    <button
                                        type="button" onClick={onDiscardChanges}
                                        className="w-full py-2.5 border-2 deactive-border flex justify-center relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-deactive-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                        <p className="deactive-btn tracking-[0.28px] text-sm font-extrabold w-max flex items-center">
                                            Discard</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="3xl:grid 3xl:grid-cols-3 block gap-6 mt-12">
                <div className="col-span-2">
                    <h2>
                        <button type="button" className="flex items-center justify-between w-full tap-highlight-color-transparent" onClick={() => setCoinActionCollapse(!isCoinActionCollapse)} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                            <span className="text-2xl text-white font-semibold">BTC coin action</span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-250px)]"></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`transform transition duration-700 ${isCoinActionCollapse ? 'rotate-90' : 'rotate-360'}`}>
                                <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="white" />
                            </svg>
                        </button>
                    </h2>
                    <div className={`mt-9 collaps-content ${!isCoinActionCollapse ? "collaps-open" : ""}`}>
                        <div className="block lg:flex gap-4">
                            <div className="bg-white bg-opacity-3 border-white border-opacity-20 border rounded w-full lg:w-1/2">
                                <div className="flex justify-center border-b-2 border-white border-opacity-10 p-4">
                                    <p className="text-xl font-semibold text-white w-max">
                                        Change strategy
                                    </p>
                                </div>
                                <div className="p-5">
                                    <div className="text-center flex justify-between items-center px-5 gap-3">
                                        <p className="text-sm text-emerald font-semibold">Infinite <span className="text-white font-normal">: Keep bot trading continuously.</span></p>
                                        <p className="text-sm text-emerald font-semibold">Single <span className="text-white font-normal">: Stop bot after current positions.</span></p>
                                    </div>
                                    <div id="myTabContent" className="mt-4">
                                        <div className="">
                                            <ul className="flex flex-wrap text-sm font-bold text-center bg-black-blackRock p-1"
                                                id="myTab"
                                                data-tabs-toggle="#myTabContent"
                                                role="tablist">
                                                <li className="w-1/2">
                                                    <button
                                                        onClick={() => onChangeStrategy(true)} role="tab"
                                                        aria-selected={isInfinite} className={`text-white text-15 font-normal p-2.5 w-full ${isInfinite ? 'bg-gradient font-semibold' : ''}`}>
                                                        Infinite
                                                    </button>
                                                </li>
                                                <li className="w-1/2">
                                                    <button
                                                        onClick={() => onChangeStrategy(false)} role="tab"
                                                        aria-selected={!isInfinite} className={`text-white text-15 font-normal p-2.5 w-full ${!isInfinite ? 'bg-gradient font-semibold' : ''}`}>
                                                        Single
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white bg-opacity-3 border-white border-opacity-20 border rounded mt-5 lg:mt-0 w-full lg:w-1/2">
                                <div className="flex justify-center border-b-2 border-white border-opacity-10 p-4">
                                    <p className="text-xl font-semibold text-white w-max">
                                        Control next orders
                                    </p>
                                </div>
                                <div className="p-5">
                                    <div className="text-center flex justify-between items-center gap-3">
                                        <p className="text-xs text-emerald font-semibold">STOP Bot <span className="text-white font-normal">: Halt all trading on this coin.</span></p>
                                        <p className="text-xs text-emerald font-semibold">STOP Long  <span className="text-white font-normal">: Cease new long orders.</span></p>
                                        <p className="text-xs text-emerald font-semibold">STOP Short <span className="text-white font-normal">: Cease new short orders.</span></p>
                                    </div>
                                    <button type="button"
                                        className="w-full mt-7 py-2.5 font-bold text-15 text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover tracking-[0.28px]" onClick={() => onStopBot("both")}>
                                        STOP BOT ON BTC
                                    </button>
                                    <div className="flex items-center gap-8 mt-8">
                                        <button type="button"
                                            className="w-full py-1.5 border-2 border-bottom flex justify-center relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100" onClick={() => onStopBot("long")}>
                                            <p className="w-max flex items-center">
                                                <img className="mr-3" src={Images.shieldactive} alt="Shield active" />
                                                <span className="font-extrabold bg-gradient tracking-[0.28px] text-gradient bg-clip-text text-13">Stop Long</span> </p>
                                        </button>
                                        <button type="button"
                                            className="w-full py-1.5 border-2 deactive-border flex justify-center relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-deactive-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100" onClick={() => onStopBot("short")}>
                                            <p className="flex items-center">
                                                <img className="mr-3" src={Images.shielddeactive} alt="Shield deactive" />
                                                <span className="deactive-btn tracking-[0.28px] text-13 font-extrabold w-max ">Stop Short</span> </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="3xl:mt-0 mt-7 pointer-events-none">
                    <h2>
                        <button type="button" className="flex items-center justify-between w-full tap-highlight-color-transparent" onClick={() => setRestartCoinCollapse(!isRestartCoinCollapse)} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                            <span className="text-2xl text-white font-semibold">Restart coin</span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-210px)]"></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`transform transition duration-700 ${isRestartCoinCollapse ? 'rotate-90' : 'rotate-360'}`}>
                                <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="white" />
                            </svg>
                        </button>
                    </h2>
                    <div className={`collaps-content ${!isRestartCoinCollapse ? "collaps-open" : ""}`}>
                        <div className="bg-white bg-opacity-3 border-white border-opacity-20 border rounded p-5 mt-9">
                            <div>
                                <p className="text-sm text-grey-mischka">Restarting BTC Coin will clear all existing short position logs for BTC on Ailtra. A fresh order will be placed based on the selected strategy type. Please enter "Restart" to confirm. When user is trying to STOP bot by clicking STOP Bot on BTC button at that point of time this warning will appear.</p>
                            </div>
                            <div className="gap-5 mt-5 flex">
                                {longExchangeAccountAssetOrders.length > 0 ? (<button
                                    type="button" onClick={() => onRestartCoin("LONG")}
                                    className="w-full py-2 font-bold text-15 text-white border-2 border-bottom tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                    RESTART LONG
                                </button>) : ("")}

                                {shortExchangeAccountAssetOrders.length > 0 ? (
                                    <button
                                        type="button" onClick={() => onRestartCoin("SHORT")}
                                        className="w-full py-2 font-bold text-15 text-white border-2 border-bottom tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                        RESTART SHORT
                                    </button>
                                ) : ("")}
                            </div>
                            {longExchangeAccountAssetOrders.length > 0 && shortExchangeAccountAssetOrders.length > 0 ? (
                                <div className="mt-6">
                                    <button
                                        type="button" onClick={() => onRestartCoin("BOTH")}
                                        className="w-full py-2 font-bold text-15 text-white border-2 border-bottom tracking-[0.28px] btn-hover-animation hover:bg-[right_center] bg-gradient-hover">
                                        RESTART SHORT & LONG
                                    </button>
                                </div>
                            ) : longExchangeAccountAssetOrders.length <= 0 && shortExchangeAccountAssetOrders.length <= 0 ? (
                                <div>
                                    <div className="gap-5 mt-5 flex">
                                        <button
                                            type="button" disabled
                                            className="w-full py-2 font-bold text-15 text-white border-2 border-bottom tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                            RESTART LONG
                                        </button>
                                        <button
                                            type="button" disabled
                                            className="w-full py-2 font-bold text-15 text-white border-2 border-bottom tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                            RESTART SHORT
                                        </button>
                                    </div>
                                    <div className="mt-6">
                                        <button
                                            type="button" disabled
                                            className="w-full py-2 font-bold text-15 text-white border-2 border-bottom tracking-[0.28px] btn-hover-animation hover:bg-[right_center] bg-gradient-hover">
                                            RESTART SHORT & LONG
                                        </button>
                                    </div>
                                </div>
                            ) : ("")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Coinaction;