import Images from "../assets/images";
import { useState, useMemo, useEffect, useRef, ChangeEvent } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { createChart, ColorType, IChartApi } from 'lightweight-charts';
import GaugeChart from 'react-gauge-chart';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from "../helpers/pagination";
import { useOtp } from '../helpers/otp';
import { webSocketUrl } from "../helpers/config";
import { formatDateWithOrdinal, getDateOnly, isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { formatNumberWithCommas } from "../helpers/commaformattednumber";
import { validateNotEmpty, validateEmailFormat } from '../utils/validation';
import { useSendVerificationCode, useUpdateUser, useVerifyVerificationCodeV2 } from "../services/user.service";
import { useCreateBackTestingAccount, useUpdateUserExchangeAccountProcessStatus } from "../services/exchange.service";
import IResponse from "../types/response";
import { IUserExchangeTestAccount } from "../types/exchange";
import { storage } from "../utils/storage";
import * as Sentry from "@sentry/react";

const Backtesting = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const pageSize = useMemo(() => 5, [])
    const recordCount = useMemo(() => 0, []);
    const totalPages = Math.ceil(recordCount / pageSize);
    const currentPage = 1;
    const loading = useRef(true);
    const [isVerifying, setIsVerifying] = useState(false);
    const [loadingStates, setLoadingStates] = useState({
        loadingAuthenticate: false,
        loadingStartbot: false,
        loadingOtpverify: false,
    });
    const [isDailyProfitOverviewCollapse, setIsDailyProfitOverviewCollapse] = useState(false);
    const pageSizeOptions = ["10", "20", "30"];
    const [dailyProfitPageSize, setDailyProfitPageSize] = useState(null);
    const [userAssetPageSize, setUserAssetPageSize] = useState(10);
    const [isDailyProfitPageSizeOpen, setIsDailyProfitPageSizeOpen] = useState(false);
    const [isUserAssetPageSizeOpen, setIsUserAssetPageSizeOpen] = useState(false);
    const [dailyProfit, setDailyProfit] = useState<any>([]);
    const [userAssets, setUserAssets] = useState<any>([]);
    const [userAssetsPagination, setUserAssetsPagination] = useState<any>([]);
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const [isFocused, setIsFocused] = useState(false);
    const [userExchangeAccountId, setUserExchangeAccountId] = useState("");
    const [backTestingModel, setBackTestingModel] = useState<any>({
        startedAt: "",
        totalFloatingValue: 0,
        year: 0,
        month: 0,
        day: 0,
        hour: 0,
        totalProfit: 0,
        totalProfitPercentage: 0,
        last7DaysProfit: 0,
        currentMonthProfit: 0,
        currentAccountBalance: 0
    });

    const [modalId, setModalId] = useState(0);
    const [isChangeEmail, setIsChangeEmail] = useState(false);
    // const risklevaloptions = [{ id: 1, name: "Low" }, { id: 2, name: "Medium" }, { id: 3, name: "High" }];
    // const [isRiskLevelOpen, setIsRiskLevelOpen] = useState(false);
    const { codes, handleOtpInput, handleOtpKeyDown, resendTimer, resetTimer, handleOtpPaste } = useOtp();
    const [model, setModel] = useState<any>({
        userExchangeAccountId: "",
        userId: "",
        status: ""
    });

    const [userExchangeTestAccount, setUserExchangeTestAccount] = useState<IUserExchangeTestAccount>({
        email: "",
        startDate: null,
        walletBalanceAmount: "",
        riskLevelId: 0,
        riskLevel: ""
    });

    const [error, setError] = useState('');

    const handlePageChange = (newPage: number) => {
        setPageNumber(newPage);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    function toggleDailyProfitDropdown() {
        setIsDailyProfitPageSizeOpen(!isDailyProfitPageSizeOpen);
    }

    const handleDailyProfitChange = (option: any) => {
        setDailyProfitPageSize(option);
        setIsDailyProfitPageSizeOpen(false);
    };

    function toggleUserAssetDropdown() {
        setIsUserAssetPageSizeOpen(!isUserAssetPageSizeOpen);
    }

    const handleUserAssetChange = (option: any) => {
        setUserAssetPageSize(option);
        setIsUserAssetPageSizeOpen(false);
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const id = urlSearchParams.get('id');
        const email = urlSearchParams.get("email".toLowerCase()) ?? "";
        const emailId = urlSearchParams.get("emailId".toLowerCase()) ?? "";
        const emailAddress = urlSearchParams.get("emailAddress".toLowerCase()) ?? "";

        const userEmail = isNotNullUndefinedBlank(email) ? email : isNotNullUndefinedBlank(emailId) ? emailId : isNotNullUndefinedBlank(emailAddress) ? emailAddress : "";
        const userExchangeTestAccountUpdated = {
            ...userExchangeTestAccount,
            email: userEmail
        };
        setUserExchangeTestAccount(userExchangeTestAccountUpdated);
        storage.setEmailId(userEmail);

        if (validateNotEmpty(id) && id !== null) {
            setUserExchangeAccountId(id);
            setModalId(0);
            storage.clearBacktestingModal();
            storage.clearInvetedAmount();
            storage.clearStartedAt();
            storage.clearIsChangeEmail();
        }
        else {
            let modalId = storage.getBacktestingModal() ?? "1";
            if (isNotNullUndefinedBlank(modalId)) {
                setModalId(parseInt(modalId));
                setIsChangeEmail(storage.getIsChangeEmail()?.toLowerCase() === "true" ? true : false ?? false);

                const userExchangeTestAccountUpdated = {
                    email: storage.getEmailId() ?? "",
                    startDate: storage.getStartedAt() ?? null,
                    walletBalanceAmount: storage.getInvetedAmount() ?? "",
                    riskLevelId: 0,
                    riskLevel: ""
                };
                setUserExchangeTestAccount(userExchangeTestAccountUpdated);

                const model = {
                    userExchangeAccountId: storage.getUserExchangeAccountId() ?? "",
                    userId: storage.getUserId() ?? "",
                    status: parseInt(modalId) === 2 ? "BACKTESTING_ACCOUNT_ADDED" : parseInt(modalId) === 3 ? "EXCHANGE_CONSENT_SENT" : ""
                };
                setModel(model);
            }
            else {
                storage.clearBacktestingModal();
                storage.clearInvetedAmount();
                storage.clearStartedAt();
                storage.clearIsChangeEmail();
                storage.clearUserId();
                storage.clearEmailId();
                storage.clearUserExchangeAccountId();
                setModalId(1);
                //setTimeout(() => { document.getElementById(`dtpStartingDate`)?.focus(); }, 100);
            }
        }
    }, []);

    useEffect(() => {
        if (validateNotEmpty(userExchangeAccountId)) {
            // Initialize WebSocket connection
            const webSocket = new WebSocket(webSocketUrl("stream?userExchangeAccountId=" + userExchangeAccountId));

            webSocket.onopen = () => {
                console.log('WebSocket Connected');
            };

            webSocket.onmessage = (event) => {
                if (isNotNullUndefinedBlank(event.data)) {
                    const data = JSON.parse(event.data);
                    let startedAtDate = formatDateWithOrdinal(data.startedAt);
                    const backTestingModel = {
                        startedAt: startedAtDate,
                        totalFloatingValue: data.totalFloatingValue,
                        year: data.year,
                        month: data.month,
                        day: data.day,
                        hour: data.hour,
                        totalProfit: isNotNullUndefined(data.totalProfit) ? data.totalProfit : 0,
                        totalProfitPercentage: isNotNullUndefined(data.totalProfitPercentage) ? data.totalProfitPercentage : 0,
                        last7DaysProfit: isNotNullUndefined(data.last7DaysProfit) ? data.last7DaysProfit : 0,
                        currentMonthProfit: isNotNullUndefined(data.currentMonthProfit) ? data.currentMonthProfit : 0,
                        currentAccountBalance: isNotNullUndefined(data.currentAccountBalance) ? data.currentAccountBalance : 0
                    };

                    if (data.dailyProfit != null && data.dailyProfit.length > 0) {
                        setDailyProfit(data.dailyProfit);
                    }

                    if (data.userAssets != null && data.userAssets.length > 0) {
                        setUserAssets(data.userAssets);
                    }
                    setBackTestingModel(backTestingModel);
                };
                loading.current = false;
                setTimeout(() => {
                    bindChart();
                }, 50);
            }

            webSocket.onclose = () => {
                console.log('WebSocket Disconnected');
            };

            return () => {
                webSocket.close();
            };
        }
    }, [userExchangeAccountId]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * userAssetPageSize;
        const endIndex = startIndex + userAssetPageSize;
        const newData = userAssets.slice(startIndex, endIndex);
        setUserAssetsPagination(newData);
        autoVerifyOTP();
    }, [currentPage, userAssetPageSize, userAssets, codes]);

    //#region verification start
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === "walletBalanceAmount") {
            const inputValue = value;
            const numericValue = inputValue.replace(/[^0-9]/g, '');
            event.target.value = numericValue;
            if (value !== numericValue) return;
        }

        const userExchangeTestAccountUpdated = {
            ...userExchangeTestAccount,
            [name]: value
        };
        setUserExchangeTestAccount(userExchangeTestAccountUpdated);
    };

    const handleStartDateChange = (date: Date) => {
        const userExchangeTestAccountUpdated = {
            ...userExchangeTestAccount,
            startDate: date
        };
        setUserExchangeTestAccount(userExchangeTestAccountUpdated);
    };

    const checkBackTestingAccountValidation = () => {
        let isValid = true;
        if (getDateOnly(userExchangeTestAccount.startDate) >= getDateOnly(new Date())) {
            setError('Starting date must be less than today');
            isValid = false;
        }
        else if (userExchangeTestAccount.walletBalanceAmount <= 0) {
            setError('Initial investment is required');
            isValid = false;
        }
        else if (userExchangeTestAccount.walletBalanceAmount < 5000) {
            setError('Initial investment must be at least 5000');
            isValid = false;
        }
        // else if (userExchangeTestAccount.riskLevelId <= 0) {
        //     setError('Risk level is required');
        //     isValid = false;
        // } 
        else {
            setError('');
        }
        return isValid;
    };

    function onBeginBackTest() {
        setError("");
        if (checkBackTestingAccountValidation()) {
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingStartbot: true,
            }));
            setTimeout(() => {
                setLoadingStates((prevState) => ({
                    ...prevState,
                    loadingStartbot: false,
                }));
            }, 100);
            setModalId(2);
            storage.setBacktestingModal(2);
            storage.setStartedAt(userExchangeTestAccount.startDate);
            storage.setInvetedAmount(userExchangeTestAccount.walletBalanceAmount);
            setTimeout(() => { document.getElementById(`txtEmail`)?.focus(); }, 100);
        }
    };

    const checkEmailValidation = () => {
        let isValid = true;
        if (!validateNotEmpty(userExchangeTestAccount.email)) {
            setError('Email is required');
            isValid = false;
        } else if (!validateEmailFormat(userExchangeTestAccount.email)) {
            setError('Invalid email format');
            isValid = false;
        } else {
            setError('');
        }
        return isValid;
    };

    // function toggleRiskLevelDropdown() {
    //     setIsRiskLevelOpen(!isRiskLevelOpen);
    // }

    // const handleRiskLevelChange = (riskLevel: any) => {
    //     const userExchangeTestAccountUpdated = {
    //         ...userExchangeTestAccount,
    //         riskLevelId: riskLevel.id,
    //         riskLevel: riskLevel.name
    //     };
    //     setUserExchangeTestAccount(userExchangeTestAccountUpdated);
    //     setIsRiskLevelOpen(false);
    // };

    const onCreateBackTestingAccount = async () => {
        setError("");
        if (checkEmailValidation()) {
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingAuthenticate: true,
            }));
            if (!isChangeEmail) {
                let param = {
                    "email": userExchangeTestAccount.email,
                    "startDate": userExchangeTestAccount.startDate.getTime(),
                    "walletBalanceAmount": userExchangeTestAccount.walletBalanceAmount,
                    "riskLevelID": userExchangeTestAccount.riskLevelId
                };
                storage.setEmailId(userExchangeTestAccount.email);
                createBackTestingAccount.mutate(param);
            }
            else if (isChangeEmail) {
                let changeParam = {
                    "userId": model.userId,
                    "email": userExchangeTestAccount.email
                };
                updateUser.mutate(changeParam);
            }
        }
    };

    const createBackTestingAccount = useCreateBackTestingAccount({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                const model = {
                    userExchangeAccountId: response.data.userExchangeAccountId,
                    userId: response.data.userId,
                    status: "BACKTESTING_ACCOUNT_ADDED"
                };
                setModel(model);
                storage.setUserId(response.data.userId);
                storage.setUserExchangeAccountId(response.data.userExchangeAccountId);
                updateUserExchangeAccountProcessStatusMutation.mutate({ "userExchangeAccountId": model.userExchangeAccountId, "statusCode": model.status });
                sendVerificationCodeMutation.mutate({ userId: model.userId, codeType: 'email' });
                resetTimer();
            }
            else if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 400) {
                alert(response.statusMessage);
                setModalId(2);
                storage.setBacktestingModal(2);
            }
            else {
                alert(response.statusMessage);
            }
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingAuthenticate: false,
            }));
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingAuthenticate: false,
            }));
        },
    });

    const updateUser = useUpdateUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 200) {
                setIsChangeEmail(false);
                storage.setIsChangeEmail(false);
                updateUserExchangeAccountProcessStatusMutation.mutate({ "userExchangeAccountId": model.userExchangeAccountId, "statusCode": model.status });
                sendVerificationCodeMutation.mutate({ userId: model.userId, codeType: 'email' });
                resetTimer();
            }
            else if (response.statusCode !== null && response.statusCode !== undefined && response.statusCode === 400) {
                alert(response.statusMessage);
                setModalId(2);
                storage.setBacktestingModal(2);
            }
            else {
                alert(response.statusMessage);
            }
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingAuthenticate: false,

            }));
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingAuthenticate: false,
            }));
        },
    });

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                const modelUpdated = {
                    ...model,
                    status: "EXCHANGE_CONSENT_SENT"
                };
                setModel(modelUpdated);
                updateUserExchangeAccountProcessStatusMutation.mutate({ "userExchangeAccountId": model.userExchangeAccountId, "statusCode": modelUpdated.status });
                setModalId(3);
                storage.setBacktestingModal(3);
                setTimeout(() => { document.getElementById(`code-0`)?.focus(); }, 100);
            }
            else {
                alert(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            alert(error.message);
        }
    });

    const checkOtpValidation = () => {
        let isValid = true;
        if (codes.filter(x => x === "").length > 0) {
            setError('Please enter OTP');
            isValid = false;
        } else {
            setError('');
        }
        return isValid;
    };

    const autoVerifyOTP = async () => {
        if (codes.join('').length === 6 && !isVerifying) {
            await onVerifyCode();
        }
    };

    const onVerifyCode = async () => {
        if (checkOtpValidation()) {
            let code = codes.join('');
            storage.clearUserReferralCode();
            setIsVerifying(true);
            await verifiyVerificationCodeMutation.mutate({
                userId: model.userId,
                verificationCode: code.replace(/\s+/g, ''),
                codeType: "email"
            });
            setIsVerifying(false);
        }
    };


    const verifiyVerificationCodeMutation = useVerifyVerificationCodeV2({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                const modelUpdated = {
                    ...model,
                    status: "EXCHANGE_CONSENT_VERIFIED"
                };
                setModel(modelUpdated);
                updateUserExchangeAccountProcessStatusMutation.mutate({ "userExchangeAccountId": model.userExchangeAccountId, "statusCode": modelUpdated.status });
            }
            else {
                setError(response.statusMessage);
            }
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingOtpverify: false,
            }));
        },
        onError: (error) => {
            setError(error.message);
            Sentry.captureException(error);
            setLoadingStates((prevState) => ({
                ...prevState,
                loadingOtpverify: false,
            }));
        }
    });

    const updateUserExchangeAccountProcessStatusMutation = useUpdateUserExchangeAccountProcessStatus({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                if (model.status === "EXCHANGE_CONSENT_VERIFIED") {
                    setModalId(0);
                    navigate(location.pathname + "?id=" + model.userExchangeAccountId);
                    setUserExchangeAccountId(model.userExchangeAccountId);
                    storage.clearBacktestingModal();
                    storage.clearInvetedAmount();
                    storage.clearStartedAt();
                    storage.clearIsChangeEmail();
                }
            }
            else {
                setError(response.statusMessage);
            }
        },
        onError: (error) => {
            setError(error.message);
            Sentry.captureException(error);
        }
    });

    const onResend = () => {
        sendVerificationCodeMutation.mutate({ userId: model.userId, codeType: 'email' });
        resetTimer();
    };

    const onChangeEmail = () => {
        setIsChangeEmail(true);
        storage.setIsChangeEmail(true);
        setModalId(2);
        storage.setBacktestingModal(2);
        setTimeout(() => { document.getElementById(`txtEmail`)?.focus(); }, 100);
    };

    //#endregion verification end

    //#region chart start

    const chartContainerRef = useRef<HTMLDivElement>(null);

    const data = useMemo(() => [
        { time: '2021-03-28', value: 154 },
        { time: '2021-03-29', value: 154.2 },
        { time: '2021-03-30', value: 155.60001 },
        { time: '2021-04-02', value: 156.25 },
        { time: '2021-04-03', value: 156.25 },
        { time: '2021-04-04', value: 156.05 },
        { time: '2021-04-05', value: 158.05 },
        { time: '2021-04-06', value: 157.3 },
        { time: '2021-04-09', value: 144 },
        { time: '2021-04-10', value: 144.8 },
        { time: '2021-04-11', value: 143.5 },
        { time: '2021-04-12', value: 147.05 },
        { time: '2021-04-13', value: 144.85001 },
        { time: '2021-04-16', value: 145.39999 },
        { time: '2021-04-17', value: 147.3 },
        { time: '2021-04-18', value: 153.55 },
        { time: '2021-04-19', value: 150.95 },
        { time: '2021-04-20', value: 149.39999 },
        { time: '2021-04-23', value: 148.5 },
        { time: '2021-04-24', value: 146.60001 },
        { time: '2021-04-25', value: 144.45 },
        { time: '2021-04-26', value: 145.60001 },
        { time: '2021-04-27', value: 144.3 },
        { time: '2021-04-30', value: 144 },
        { time: '2021-05-02', value: 147.3 },
        { time: '2021-05-03', value: 144.2 },
        { time: '2021-05-04', value: 141.64999 },
        { time: '2021-05-07', value: 141.89999 },
        { time: '2021-05-08', value: 140.39999 },
        { time: '2021-05-10', value: 139.8 },
        { time: '2021-05-11', value: 137.5 },
        { time: '2021-05-14', value: 136.14999 },
        { time: '2021-05-15', value: 138 },
        { time: '2021-05-16', value: 137.95 },
        { time: '2021-05-17', value: 137.95 },
        { time: '2021-05-18', value: 136.75 },
        { time: '2021-05-21', value: 136.2 },
        { time: '2021-05-22', value: 135 },
        { time: '2021-05-23', value: 132.55 },
        { time: '2021-05-24', value: 132.7 },
        { time: '2021-05-25', value: 132.2 },
        { time: '2021-05-28', value: 131.55 },
        { time: '2021-05-29', value: 131.85001 },
        { time: '2021-05-30', value: 139.85001 },
        { time: '2021-05-31', value: 140.8 },
        { time: '2021-06-01', value: 139.64999 },
        { time: '2021-06-04', value: 137.10001 },
        { time: '2021-06-05', value: 139.25 },
        { time: '2021-06-06', value: 141.3 },
        { time: '2021-06-07', value: 145 },
        { time: '2021-06-08', value: 143.89999 },
        { time: '2021-06-11', value: 142.7 },
        { time: '2021-06-13', value: 144.05 },
        { time: '2021-06-14', value: 143.55 },
        { time: '2021-06-15', value: 140.5 },
        { time: '2021-06-18', value: 139.64999 },
        { time: '2021-06-19', value: 138 },
        { time: '2021-06-20', value: 141 },
        { time: '2021-06-21', value: 140.55 },
        { time: '2021-06-22', value: 140.55 },
        { time: '2021-06-25', value: 140.75 },
        { time: '2021-06-26', value: 140.64999 },
        { time: '2021-06-27', value: 141.14999 },
        { time: '2021-06-28', value: 139.8 },
        { time: '2021-06-29', value: 139.8 },
        { time: '2021-07-02', value: 140.14999 },
        { time: '2021-07-03', value: 143.05 },
        { time: '2021-07-04', value: 140 },
        { time: '2021-07-05', value: 129.2 },
        { time: '2021-07-06', value: 129.55 },
        { time: '2021-07-09', value: 128.3 },
        { time: '2021-07-10', value: 126.55 },
        { time: '2021-07-11', value: 124.3 },
        { time: '2021-07-12', value: 124.8 },
        { time: '2021-07-13', value: 123.25 },
        { time: '2021-07-16', value: 123 },
        { time: '2021-07-17', value: 124.25 },
        { time: '2021-07-18', value: 123 },
        { time: '2021-07-19', value: 121 },
        { time: '2021-07-20', value: 121.45 },
        { time: '2021-07-23', value: 123.8 },
        { time: '2021-07-24', value: 122.15 },
        { time: '2021-07-25', value: 121.3 },
        { time: '2021-07-26', value: 122.7 },
        { time: '2021-07-27', value: 122.2 },
        { time: '2021-07-30', value: 121.7 },
        { time: '2021-07-31', value: 122.95 },
        { time: '2021-08-01', value: 121 },
        { time: '2021-08-02', value: 116 },
        { time: '2021-08-03', value: 118.1 },
        { time: '2021-08-06', value: 114.3 },
        { time: '2021-08-07', value: 114.25 },
        { time: '2021-08-08', value: 111.85 },
        { time: '2021-08-09', value: 109.7 },
        { time: '2021-08-10', value: 105 },
        { time: '2021-08-13', value: 105.75 },
        { time: '2021-08-14', value: 107.25 },
        { time: '2021-08-15', value: 107.4 },
        { time: '2021-08-16', value: 110.5 },
        { time: '2021-08-17', value: 109 },
        { time: '2021-08-20', value: 108.95 },
        { time: '2021-08-21', value: 108.35 },
        { time: '2021-08-22', value: 108.6 },
        { time: '2021-08-23', value: 105.65 },
        { time: '2021-08-24', value: 104.45 },
        { time: '2021-08-27', value: 106.2 },
        { time: '2021-08-28', value: 106.55 },
        { time: '2021-08-29', value: 111.8 },
        { time: '2021-08-30', value: 115.5 },
        { time: '2021-08-31', value: 115.5 },
        { time: '2021-09-03', value: 114.55 },
        { time: '2021-09-04', value: 112.75 },
        { time: '2021-09-05', value: 111.5 },
        { time: '2021-09-06', value: 108.1 },
        { time: '2021-09-07', value: 108.55 },
        { time: '2021-09-10', value: 106.5 },
        { time: '2021-09-11', value: 105.1 },
        { time: '2021-09-12', value: 107.2 },
        { time: '2021-09-13', value: 107.1 },
        { time: '2021-09-14', value: 105.75 },
        { time: '2021-09-17', value: 106.05 },
        { time: '2021-09-18', value: 109.15 },
        { time: '2021-09-19', value: 111.4 },
        { time: '2021-09-20', value: 111 },
        { time: '2021-09-21', value: 111 },
        { time: '2021-09-24', value: 108.95 },
        { time: '2021-09-25', value: 106.65 },
        { time: '2021-09-26', value: 106.7 },
        { time: '2021-09-27', value: 107.05 },
        { time: '2021-09-28', value: 106.55 },
        { time: '2021-10-01', value: 106.2 },
        { time: '2021-10-02', value: 106.4 },
        { time: '2021-10-03', value: 107.1 },
        { time: '2021-10-04', value: 106.4 },
        { time: '2021-10-05', value: 104.65 },
        { time: '2021-10-08', value: 102.65 },
        { time: '2021-10-09', value: 102.1 },
        { time: '2021-10-10', value: 102.15 },
        { time: '2021-10-11', value: 100.9 },
        { time: '2021-10-12', value: 102 },
        { time: '2021-10-15', value: 100.15 },
        { time: '2021-10-16', value: 99 },
        { time: '2021-10-17', value: 98 },
        { time: '2021-10-18', value: 96 },
        { time: '2021-10-19', value: 95.5 },
        { time: '2021-10-22', value: 92.400002 },
        { time: '2021-10-23', value: 92.300003 },
        { time: '2021-10-24', value: 92.900002 },
        { time: '2021-10-25', value: 91.849998 },
        { time: '2021-10-26', value: 91.599998 },
        { time: '2021-10-29', value: 94.050003 },
        { time: '2021-10-30', value: 98.25 },
        { time: '2021-10-31', value: 97.25 },
        { time: '2021-11-01', value: 96.879997 },
        { time: '2021-11-02', value: 101.78 },
        { time: '2021-11-06', value: 102.4 },
        { time: '2021-11-07', value: 100.6 },
        { time: '2021-11-08', value: 98.5 },
        { time: '2021-11-09', value: 95.139999 },
        { time: '2021-11-12', value: 96.900002 },
        { time: '2021-11-13', value: 97.400002 },
        { time: '2021-11-14', value: 103.3 },
        { time: '2021-11-15', value: 102.74 },
        { time: '2021-11-16', value: 101.2 },
        { time: '2021-11-19', value: 98.720001 },
        { time: '2021-11-20', value: 102.2 },
        { time: '2021-11-21', value: 108.8 },
        { time: '2021-11-22', value: 109.9 },
        { time: '2021-11-23', value: 113.74 },
        { time: '2021-11-26', value: 110.9 },
        { time: '2021-11-27', value: 113.28 },
        { time: '2021-11-28', value: 113.6 },
        { time: '2021-11-29', value: 113.14 },
        { time: '2021-11-30', value: 114.4 },
        { time: '2021-12-03', value: 111.84 },
        { time: '2021-12-04', value: 106.7 },
        { time: '2021-12-05', value: 107.8 },
        { time: '2021-12-06', value: 106.44 },
        { time: '2021-12-07', value: 103.7 },
        { time: '2021-12-10', value: 101.02 },
        { time: '2021-12-11', value: 102.72 },
        { time: '2021-12-12', value: 101.8 },
        { time: '2021-12-13', value: 102 },
        { time: '2021-12-14', value: 102.1 },
        { time: '2021-12-17', value: 101.04 },
        { time: '2021-12-18', value: 101.6 },
        { time: '2021-12-19', value: 102 },
        { time: '2021-12-20', value: 102.02 },
        { time: '2021-12-21', value: 102.2 },
        { time: '2021-12-24', value: 102.1 },
        { time: '2021-12-25', value: 100.8 },
        { time: '2021-12-26', value: 101.02 },
        { time: '2021-12-27', value: 100.5 },
        { time: '2021-12-28', value: 101 },
        { time: '2022-01-03', value: 101.5 },
        { time: '2022-01-04', value: 101.1 },
        { time: '2022-01-08', value: 101.1 },
        { time: '2022-01-09', value: 102.06 },
        { time: '2022-01-10', value: 105.14 },
        { time: '2022-01-11', value: 104.66 },
        { time: '2022-01-14', value: 106.22 },
        { time: '2022-01-15', value: 106.98 },
        { time: '2022-01-16', value: 108.4 },
        { time: '2022-01-17', value: 109.06 },
        { time: '2022-01-18', value: 107 },
        { time: '2022-01-21', value: 105.8 },
        { time: '2022-01-22', value: 105.24 },
        { time: '2022-01-23', value: 105.4 },
        { time: '2022-01-24', value: 105.38 },
        { time: '2022-01-25', value: 105.7 },
        { time: '2022-01-28', value: 105.8 },
        { time: '2022-01-29', value: 106.1 },
        { time: '2022-01-30', value: 108.6 },
        { time: '2022-01-31', value: 107.92 },
        { time: '2022-02-01', value: 105.22 },
        { time: '2022-02-04', value: 102.44 },
        { time: '2022-02-05', value: 102.26 },
        { time: '2022-02-06', value: 102 },
        { time: '2022-02-07', value: 101.62 },
        { time: '2022-02-08', value: 101.9 },
        { time: '2022-02-11', value: 101.78 },
        { time: '2022-02-12', value: 102.7 },
        { time: '2022-02-13', value: 100.14 },
        { time: '2022-02-14', value: 101.36 },
        { time: '2022-02-15', value: 101.62 },
        { time: '2022-02-18', value: 100.74 },
        { time: '2022-02-19', value: 100 },
        { time: '2022-02-20', value: 100.04 },
        { time: '2022-02-21', value: 100 },
        { time: '2022-02-22', value: 100.12 },
        { time: '2022-02-25', value: 100.04 },
        { time: '2022-02-26', value: 98.980003 },
        { time: '2022-02-27', value: 97.699997 },
        { time: '2022-02-28', value: 97.099998 },
        { time: '2022-03-01', value: 96.760002 },
        { time: '2022-03-04', value: 98.360001 },
        { time: '2022-03-05', value: 96.260002 },
        { time: '2022-03-06', value: 98.120003 },
        { time: '2022-03-07', value: 99.68 },
        { time: '2022-03-11', value: 102.1 },
        { time: '2022-03-12', value: 100.22 },
        { time: '2022-03-13', value: 100.5 },
        { time: '2022-03-14', value: 99.660004 },
        { time: '2022-03-15', value: 100.08 },
        { time: '2022-03-18', value: 99.919998 },
        { time: '2022-03-19', value: 99.459999 },
        { time: '2022-03-20', value: 98.220001 },
        { time: '2022-03-21', value: 97.300003 },
        { time: '2022-03-22', value: 97.660004 },
        { time: '2022-03-25', value: 97 },
        { time: '2022-03-26', value: 97.019997 },
        { time: '2022-03-27', value: 96.099998 },
        { time: '2022-03-28', value: 96.699997 },
        { time: '2022-03-29', value: 96.300003 },
        { time: '2022-04-01', value: 97.779999 },
        { time: '2022-04-02', value: 98.360001 },
        { time: '2022-04-03', value: 98.5 },
        { time: '2022-04-04', value: 98.360001 },
        { time: '2022-04-05', value: 99.540001 },
        { time: '2022-04-08', value: 98.580002 },
        { time: '2022-04-09', value: 97.239998 },
        { time: '2022-04-10', value: 97.32 },
        { time: '2022-04-11', value: 98.400002 },
        { time: '2022-04-12', value: 98.220001 },
        { time: '2022-04-15', value: 98.720001 },
        { time: '2022-04-16', value: 99.120003 },
        { time: '2022-04-17', value: 98.620003 },
        { time: '2022-04-18', value: 98 },
        { time: '2022-04-19', value: 97.599998 },
        { time: '2022-04-22', value: 97.599998 },
        { time: '2022-04-23', value: 96.800003 },
        { time: '2022-04-24', value: 96.32 },
        { time: '2022-04-25', value: 96.339996 },
        { time: '2022-04-26', value: 97.120003 },
        { time: '2022-04-29', value: 96.980003 },
        { time: '2022-04-30', value: 96.32 },
        { time: '2022-05-02', value: 96.860001 },
        { time: '2022-05-03', value: 96.699997 },
        { time: '2022-05-06', value: 94.940002 },
        { time: '2022-05-07', value: 94.5 },
        { time: '2022-05-08', value: 94.239998 },
        { time: '2022-05-10', value: 92.900002 },
        { time: '2022-05-13', value: 91.279999 },
        { time: '2022-05-14', value: 91.599998 },
        { time: '2022-05-15', value: 90.080002 },
        { time: '2022-05-16', value: 91.68 },
        { time: '2022-05-17', value: 91.959999 },
        { time: '2022-05-20', value: 91.080002 },
        { time: '2022-05-21', value: 90.760002 },
        { time: '2022-05-22', value: 91 },
        { time: '2022-05-23', value: 90.739998 },
        { time: '2022-05-24', value: 91 },
        { time: '2022-05-27', value: 91.199997 },
        { time: '2022-05-28', value: 90.68 },
        { time: '2022-05-29', value: 91.120003 },
        { time: '2022-05-30', value: 90.419998 },
        { time: '2022-05-31', value: 93.800003 },
        { time: '2022-06-03', value: 96.800003 },
        { time: '2022-06-04', value: 96.34 },
        { time: '2022-06-05', value: 95.94 },
        { time: '2022-12-22', value: 32.51 },
        { time: '2022-12-23', value: 31.11 },
    ], []);

    const bindChart = () => {
        if (chartContainerRef.current) {
            const container = chartContainerRef.current;
            const chart: IChartApi = createChart(container, {
                width: chartContainerRef.current.clientWidth,
                height: chartContainerRef.current.clientHeight,
                layout: {
                    background: {
                        type: ColorType.Solid,
                        color: 'transparent',
                    },
                    textColor: 'white',
                },
                grid: {
                    horzLines: {
                        color: '#2e2e38', //border color
                    },
                    vertLines: {
                        visible: false,
                    },
                },
                rightPriceScale: {
                    visible: false,
                },
                leftPriceScale: {
                    visible: true,
                    borderVisible: true,
                },
                timeScale: {
                    borderVisible: true,
                },
                crosshair: {
                    horzLine: {
                        visible: false,
                        labelVisible: false,
                    },
                },
            });

            const series = chart.addAreaSeries({
                topColor: 'rgba(141, 198, 63, 0.26)',
                bottomColor: 'rgba(0, 174, 239, 0)',
                lineColor: 'rgba(141, 198, 63, 1)',
                lineWidth: 2,
                priceLineVisible: false,
                lastValueVisible: false,
            });

            series.setData(data);

            document.body.style.position = 'relative';
            const toolTip = document.createElement('div');
            toolTip.style.cssText = `position: absolute; display: none; padding: 2px; font-size: 3px; text-align: center; z-index: 1000; top: 12px; pointer-events: none;`;
            container.appendChild(toolTip);

            chart.subscribeCrosshairMove((param) => {
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > container.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > container.clientHeight
                ) {
                    toolTip.style.display = 'none';
                } else {
                    toolTip.style.display = 'block';
                    const data: any = param.seriesData.get(series);
                    const price = data.value !== undefined ? data.value : 0;
                    toolTip.innerHTML = `<div class="relative -top-1 block items-center rounded-full bg-gradient px-2 py-0.5 text-10 font-semibold text-white after:absolute after:bottom-[-8px] after:left-[50%] after:right-auto after:top-auto after:-translate-x-1/2 after:border-4 after:border-solid after:border-b-transparent after:border-l-transparent after:border-r-transparent after:border-t-[#34b8ac] first-letter:after:content-['*']">
                ${Math.round(100 * price) / 100}
                </div>`;
                    let left: any = param.point.x + 40;
                    toolTip.style.left = left + 'px';
                    toolTip.style.top = 0 + 'px';
                }
            });
            const resizeObserver = new ResizeObserver(() => {
                if (chartContainerRef.current) {
                    chart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
                }
            });
            resizeObserver.observe(chartContainerRef.current);

            return () => {
                resizeObserver.disconnect();
                chart.remove();
            };
        }
    };

    useEffect(() => {
        bindChart();
    }, [data]);

    //#endregion chart end

    //#region gauge chart start

    const [gaugeValue] = useState<number>(0.3);
    const chartStyle: React.CSSProperties = {
        height: 80,
        width: 120,
    };

    //#endregion gauge chart end

    return (
        <div className="p-6 sm:py-8 xl:px-9 bg-black-darkBlack">
            <div className="w-full block md:flex gap-3">
                <div className="w-full 3xl:w-[36%]">
                    {loading.current ? (
                        <div className="right-0 h-full bg-[#1A1A20] relative p-4 animate-pulse rounded">
                            <div className="h-3 bg-black-blackRock rounded mb-2 cover-image-skeleton"></div>
                            <div className="flex justify-end w-[90%]">
                                <span className="h-6 bg-black-blackRock rounded-full w-1/4 sm:w-[10%] mt-5"></span>
                            </div>
                            <div className="h-24 bg-black-blackRock rounded-3xl w-7/12 sm:w-2/5 mt-5"></div>
                            <div className="h-6 bg-black-blackRock rounded-3xl w-[30%] mt-5"></div>
                        </div>

                    ) : (
                        <div className="h-full">
                            <div className="flex items-center justify-between w-full">
                                <span className="text-[11px] sm:text-base text-white font-semibold">
                                    Total Profit
                                </span>
                                <div className="border border-t-0 border-white border-opacity-12 w-[calc(100%-70px)] sm:w-[calc(100%-108px)]"></div>
                            </div>
                            <div className=" w-full sm:w-[90%] top-5 relative h-full">
                                <div className="flex justify-end">
                                    <span className="bg-emerald text-white leading-none text-xs sm:leading-none sm:text-base py-1 px-2 sm:px-3.5 rounded-full">+{formatNumberWithCommas(parseFloat(backTestingModel.totalProfitPercentage.toString()).toFixed(2))}%</span>
                                </div>
                                <div>
                                    <p className="font-normal">
                                        <span className="text-2xl lg:text-4xl 3xl:text-[55px] text-white">$</span>
                                        <span className="text-6xl lg:text-[80px] 3xl:text-[108px] text-emerald">{formatNumberWithCommas(parseFloat(backTestingModel.totalProfit.toString()).toFixed(2)).split(".")[0]}</span>
                                        <span className="text-2xl lg:text-4xl 3xl:text-[55px] text-emerald">.{formatNumberWithCommas(parseFloat(backTestingModel.totalProfit.toString()).toFixed(2)).split(".")[1]}</span>
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <div className="mt-7">
                                        <span className="bg-[#7B6AFE33] bg-opacity-3 py-2 px-4 rounded-full">
                                            <span className="text-grey-suit text-[11px] xl:text-sm 2xl:text-base">Last 7D PNL</span>
                                            <span className="text-emerald ml-2 text-sm xl:text-sm  2xltext-base">
                                                +${formatNumberWithCommas(parseFloat(backTestingModel.last7DaysProfit.toString()).toFixed(2))}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="mt-7">
                                        <span className="bg-[#7B6AFE33] bg-opacity-3 py-2 px-4 rounded-full">
                                            <span className="text-grey-suit text-[11px] xl:text-sm 2xl:text-base">This Month PNL</span>
                                            <span className="text-emerald ml-2 text-sm xl:text-sm 2xl:text-base">
                                                +${formatNumberWithCommas(parseFloat(backTestingModel.currentMonthProfit.toString()).toFixed(2))}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full 3xl:w-[64%] mt-14 md:mt-0 bg-black-darkBlack">
                    {loading.current ? (
                        <div className="animate-pulse h-full relative">
                            <div className="right-0 h-full bg-[#1A1A20] w-full flex justify-center rounded">
                                <div className="w-full justify-center flex">
                                    <div className="top-0 h-full w-full flex items-center justify-center">
                                        <div className="w-full md:w-[70%] lg:w-3/5 xl:w-1/2">
                                            <div className="w-full flex justify-center">
                                                <div className="h-6 bg-black-blackRock  xl:w-full mb-3 rounded-full"></div>
                                            </div>
                                            <div className="w-full flex justify-center">
                                                <div className="h-6 bg-black-blackRock w-[90%] mb-3 rounded-full"></div>
                                            </div>
                                            <div className="w-full flex justify-center">
                                                <div className="h-6 bg-black-blackRock w-[30%] mb-3 rounded-full"></div>
                                            </div>
                                            <div className="w-full flex justify-center">
                                                <div className="h-4 bg-black-blackRock w-1/6 mb-3 rounded-full"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : (
                        <div className="h-full relative">
                            <div className="absolute right-0 h-full">
                                <img src={Images.accbalancerightlayer} alt="Acc balance right layer" className="h-full" />
                            </div>
                            <div className="h-full">
                                <img src={Images.accbalanceleftlayer} alt="Acc balance left layer" className="h-full" />
                            </div>
                            <div className="absolute top-0 h-full w-full">
                                <img src={Images.accbalancemainlayer} alt="Acc balance main layer" className="w-full h-full" />
                            </div>
                            <div className="absolute bottom-0 h-20 lg:h-20 2xl:h-[213px]">
                                <img src={Images.accbalanceleftcoin} alt="Acc balance left coin" className="w-full h-full" />
                            </div>
                            <div className="absolute top-0 right-0 h-14 lg:h-16 2xl:h-[170px]">
                                <img src={Images.accbalancerightcoin} alt="Acc balance right coin" className="w-full h-full" />
                            </div>
                            <div className="absolute top-0 h-full w-full flex items-center justify-center text-center">
                                <div className="w-[90%] 3xl:w-[58%]">
                                    <p className="text-white text-sm md:text-15 xl:text-[26px] font-normal leading-6 xl:leading-[38px]">Satisfied? Start your own AI Bot start
                                        making real money as you can see here!</p>
                                    <button type="button" className="mt-3 leading-none xl:leading-none lg:mt-7 font-bold text-15 xl:text-xl text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover py-2 px-[54px] rounded-full">
                                        Get FREE $100
                                    </button>
                                    <p className="mt-3 text-13 font-normal text-white">As a credit in Ailtra's Gas fees</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="w-full block xl:flex gap-3 mt-10">
                <div className="w-full 3xl:w-[36%]">
                    {loading.current ? (
                        <div className="animate-pulse h-full bg-[#1A1A20] relative p-4 rounded">
                            <div className="h-3 bg-black-blackRock rounded mb-2"></div>
                            <div className="flex items-center justify-between w-full">
                            </div>
                            <div className="flex gap-4 mt-2 3xl:mt-7 w-full">
                                <div className="w-1/3">
                                    <div className="h-4 bg-black-blackRock  rounded"></div>
                                    <div className="h-8 bg-black-blackRock  rounded mt-2"></div>
                                </div>
                                <div className="w-1/4">
                                    <div className="h-4 bg-black-blackRock  rounded"></div>
                                    <div className="h-8 bg-black-blackRock  rounded mt-2"></div>
                                </div>
                                <div className="w-1/3 flex justify-center items-center">
                                    <div className="h-12 w-24 bg-black-blackRock rounded-t-full"></div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="flex items-center justify-between w-full">
                                <span className="text-xs lg:text-base text-white font-semibold">
                                    Current Floating P&L & Risk level
                                </span>
                                <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-214px)] lg:w-[calc(100%-284px)]"></p>
                            </div>
                            <div className="flex gap-4 mt-4 3xl:mt-7 w-full">
                                <div className="w-1/3">
                                    <p className="text-10 lg:text-xs font-normal text-grey-suit">
                                        Floating P&L
                                    </p>
                                    <p className={`text-[22px] lg:text-3xl font-semibold text-white ${backTestingModel.floatingLoss < 0} mt-[23px]`}>
                                        {formatNumberWithCommas(parseFloat(backTestingModel.totalFloatingValue.toString()).toFixed(2))}
                                    </p>
                                </div>
                                <div className="w-1/3">
                                    <p className="text-10 lg:text-xs font-normal text-grey-suit">
                                        Account Balance
                                    </p>
                                    <p className={`text-[22px] lg:text-3xl font-semibold text-white ${backTestingModel.floatingLoss < 0} mt-[23px]`}>
                                        {formatNumberWithCommas(parseFloat(backTestingModel.currentAccountBalance.toString()).toFixed(2))}
                                    </p>

                                </div>
                                <div className="w-1/3">
                                    <p className="text-10 lg:text-xs  font-normal text-grey-suit">
                                        Account Risk Level
                                    </p>
                                    <p className="text-[22px] text-3xl font-semibold text-emerald mt-[11px]">
                                        <GaugeChart
                                            style={chartStyle}
                                            nrOfLevels={10}
                                            colors={["#00AEEF", "#8DC63F"]}
                                            arcWidth={0.1}
                                            percent={gaugeValue}
                                            textColor={"#444444"}
                                            needleColor={"#00AEEF"}
                                            needleBaseColor={"#00AEEF"}
                                            hideText={true}
                                            animate={false}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className=" w-full 3xl:w-[64%] lg:mt-5 xl:mt-0">
                    {loading.current ? (
                        <div className="animate-pulse bg-[#1A1A20] relative p-4 rounded">
                            <div className="h-3 bg-black-blackRock rounded mb-2"></div>
                            <div className="gap-7 mt-4 3xl:mt-7 flex justify-end">
                                <div className="flex 3xl:block items-center mb-3 3xl:mb-0">
                                    <div className="h-11 w-11 sm:h-[50px] sm:w-[50px] lg:h-[68px] lg:w-[75px] bg-black-blackRock relative rounded-2xl">
                                        <div className="bg-black-blackRock w-full h-full rounded-2xl"></div>
                                    </div>
                                </div>
                                {[...Array(3)].map((_, index) => (
                                    <div key={index} className="">
                                        <div className="h-11 w-11 sm:h-[50px] sm:w-[50px] lg:h-[68px] lg:w-[75px] bg-black-blackRock relative rounded-2xl">
                                            <div className="bg-black-blackRock w-full h-full rounded-2xl"></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="flex items-center justify-between w-full">
                                <span className="text-xs lg:text-base text-white font-semibold">
                                    Bot Uptime Since
                                </span>
                                <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-155px)]"></p>
                            </div>
                            <div className="gap-4 mt-4 3xl:mt-7 block 3xl:flex">
                                <div className="w-full 3xl:w-1/5 flex 3xl:block items-center mb-3 3xl:mb-0">
                                    <p className="text-10 lg:text-xs font-normal text-grey-suit">
                                        Started on
                                    </p>
                                    <p className="text-xs lg:text-[19px] font-normal text-white mt-0 3xl:mt-6 ml-3 3xl:ml-0">
                                        {backTestingModel.startedAt}
                                    </p>
                                </div>
                                <div className="w-full 3xl:w-4/5">
                                    <div>
                                        <div className="flex flex-wrap sm:flex-nowrap gap-1 sm:gap-2 lg:gap-5 justify-between sm:justify-end items-center w-full">
                                            <div className="lg:w-[15%]">
                                                <div className="h-11 w-11 sm:h-[50px] sm:w-[50px] lg:h-[68px] lg:w-[75px] relative">
                                                    <img src={Images.year} alt="Year" className="w-full h-full" />
                                                    <div className="absolute top-1/4 flex justify-center w-full">
                                                        <p className="text-xs lg:text-xl text-white font-semibold">{backTestingModel.year}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lg:w-[15%]">
                                                <div className="h-11 w-11 sm:h-[50px] sm:w-[50px] lg:h-[68px] lg:w-[75px] relative">
                                                    <img src={Images.months} alt="Months" className="w-full h-full" />
                                                    <div className="absolute top-1/4 flex justify-center w-full">
                                                        <p className="text-xs lg:text-xl text-white font-semibold">{backTestingModel.month}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lg:w-[15%]">
                                                <div className="h-11 w-11 sm:h-[50px] sm:w-[50px] lg:h-[68px] lg:w-[75px] relative">
                                                    <img src={Images.days} alt="Days" className="w-full h-full" />
                                                    <div className="absolute top-1/4 flex justify-center w-full">
                                                        <p className="text-xs lg:text-xl text-white font-semibold">{backTestingModel.day}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lg:w-[15%]">
                                                <div className="h-11 w-11 sm:h-[50px] sm:w-[50px] lg:h-[68px] lg:w-[75px] relative">
                                                    <img src={Images.hours} alt="Hours" className="w-full h-full" />
                                                    <div className="absolute top-1/4 flex justify-center w-full">
                                                        <p className="text-xs lg:text-xl text-white font-semibold">{backTestingModel.hour}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="lg:w-1/5">
                                                <div className="h-11 w-11 sm:h-[50px] sm:w-[50px] lg:h-[68px] lg:w-[75px] relative">
                                                    <img src={Images.minutes} alt="Minutes" className="w-full h-full" />
                                                    <div className="absolute top-1/4 flex justify-center w-full">
                                                        <p className="text-xs lg:text-xl text-white font-semibold">{backTestingModel.minute}</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-10">
                {loading.current ? (
                    <div className="animate-pulse h-full bg-[#1A1A20] p-4 relative rounded">
                        <div>
                            <img src={Images.skeletonchart} className="w-full" alt="skeletonchart" />
                        </div>
                    </div>
                ) : (
                    <div>
                        <button className="flex items-center justify-between w-full" onClick={() => setIsDailyProfitOverviewCollapse(!isDailyProfitOverviewCollapse)}>
                            <span className="text-xs lg:text-base text-white font-semibold">
                                Daily Profit Overview
                            </span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-211px)]"></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`transform transition duration-700 ${isDailyProfitOverviewCollapse ? 'rotate-90' : 'rotate-360'}`}>
                                <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="white" />
                            </svg>
                        </button>
                        <div className={`collaps-content ${!isDailyProfitOverviewCollapse ? "collaps-open" : ""}`}>
                            <div className="mt-3 lg:mt-7 border-2 border-grey-bunker w-full p-2 sm:px-4 sm:py-6 bg-blue-slate">
                                <div className="relative w-full h-36 sm:h-80" ref={chartContainerRef} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="block md:flex mt-5 md:gap-2 lg:gap-6">
                <div className=" w-full md:w-1/2">
                    <div className="overflow-auto max-h-[508px]">
                        {loading.current ? (
                            <table className="border-2 border-grey-bunker w-full border-collapse">
                                <thead className="animate-pulse">
                                    <tr className="bg-[#1A1A20] w-full border-b-2 border-grey-bunker">
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-6 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16 border-x border-grey-bunker">
                                            <div className="flex justify-center">
                                                <div className="h-6 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-6 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="bg-[#1A1A20] w-full border-b-2 border-grey-bunker">
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16 border-x border-grey-bunker">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-1/3 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-2/5 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="bg-[#1A1A20] w-full border-b-2 border-grey-bunker">
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16 border-x border-grey-bunker">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-1/3 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-2/5 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="bg-[#1A1A20] w-full border-b-2 border-grey-bunker">
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16 border-x border-grey-bunker">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-1/3 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-2/5 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="bg-[#1A1A20] w-full border-b-2 border-grey-bunker">
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16 border-x border-grey-bunker">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-1/3 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-2/5 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="bg-[#1A1A20] w-full border-b-2 border-grey-bunker">
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-[70%] bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16 border-x border-grey-bunker">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-1/3 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16">
                                            <div className="flex justify-center">
                                                <div className="h-3.5 w-2/5 bg-black-blackRock rounded-md">

                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        ) : (
                            <table className="border-2 border-grey-bunker w-full border-collapse">
                                <thead>
                                    <tr className="bg-[#161522] w-full">
                                        <th className="w-1/3 px-2 py-[11px] md:px-1 lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16"> <span>Trading Day</span></th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1  lg:px-5 text-cneter text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16 border-x border-grey-bunker"> <span>Daily Profit</span></th>
                                        <th className="w-1/3 px-2 py-[11px] md:px-1  lg:px-5 text-center text-xs lg:text-base whitespace-nowrap font-semibold text-white tracking-0.16"> <span>Account Balance</span></th>
                                    </tr>
                                </thead>
                                <tbody className="bg-blue-slate">
                                    {dailyProfit.map((profit: any, index: number) => (
                                        <tr className="border-b-2 border-grey-bunker" key={index}>
                                            <td className="text-grey-suit text-xs py-3 text-center lg:backtesting-table">{formatDateWithOrdinal(new Date(profit.tradingDate))}</td>
                                            <td className={`${profit.realizedEarning < 0 ? 'text-persian' : 'text-emerald'} text-xs py-3 text-center lg:backtesting-table border-x border-grey-bunker`}>${formatNumberWithCommas(parseFloat(profit.realizedEarning.toString()).toFixed(2))}</td>
                                            <td className="text-grey-suit text-xs py-3 text-center lg:backtesting-table">${formatNumberWithCommas(parseFloat(profit.accountBalance.toString()).toFixed(2))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div className="block sm:flex items-center justify-between pl-2 pt-[3px]">
                        <div className="flex">
                            <Pagination
                                currentPage={pageNumber}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        <div className="flex items-center justify-end gap-2.5 pt-3.5 sm:pt-5">
                            <p className="text-white font-normal text-10 sm:text-sm">Page</p>
                            <div className="relative">
                                <button
                                    className="border bg-grey-jaguar border-grey-tuna rounded-md py-1 px-2 text-10 sm:text-sm cursor-pointer text-white mt-1"
                                    onClick={toggleDailyProfitDropdown}
                                >
                                    {dailyProfitPageSize ?? "10"}
                                    <svg
                                        className="h-5 w-5 ml-2 inline-block"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                {isDailyProfitPageSizeOpen && (
                                    <div className="absolute w-full text-white bg-black-darkBlack border border-grey-tuna rounded-md shadow-lg -top-28">
                                        {pageSizeOptions.map((option) => (
                                            <button key={option} className="py-1.5 px-4 hover:bg-grey-tuna cursor-pointer w-full" onClick={() => handleDailyProfitChange(option)}>
                                                {option}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <p className="text-white font-normal text-10 sm:text-sm">of 10</p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 mt-5 md:mt-0">
                    <div>
                        {loading.current ? (
                            <div>
                                <div className="animate-pulse mt-1.5">
                                    <div className=" flex relative items-center">
                                        <div className="2xl:border-8 border-black-darkBlack rounded-full absolute">
                                            <div className="xl:h-[82px] xl:w-[82px] w-14 h-14 xl:p-4 bg-[#1A1A20] rounded-full flex justify-center items-center -mt-9 ml-3 xl:mt-0 xl:ml-0">
                                                <div className="w-9 h-9 xl:h-11 xl:w-11 rounded-full bg-black-blackRock"></div>
                                            </div>
                                        </div>
                                        <div className="active-coin-table-responsive 2xl:active-coin-table skeleton-bg">
                                            <div className="W-1/2 md:w-full flex">
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full "></div>
                                                </div>
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full"></div>
                                                </div>
                                            </div>
                                            <div className="W-1/2 md:w-full flex mt-5 2xl:mt-0">
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full "></div>
                                                </div>
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="animate-pulse mt-1.5">
                                    <div className=" flex relative items-center">
                                        <div className="2xl:border-8 border-black-darkBlack rounded-full absolute">
                                            <div className="xl:h-[82px] xl:w-[82px] w-14 h-14 xl:p-4 bg-[#1A1A20] rounded-full flex justify-center items-center -mt-9 ml-3 xl:mt-0 xl:ml-0">
                                                <div className="w-9 h-9 xl:h-11 xl:w-11 rounded-full bg-black-blackRock"></div>
                                            </div>
                                        </div>
                                        <div className="active-coin-table-responsive 2xl:active-coin-table skeleton-bg">
                                            <div className="W-1/2 md:w-full flex">
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full "></div>
                                                </div>
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full"></div>
                                                </div>
                                            </div>
                                            <div className="W-1/2 md:w-full flex mt-5 2xl:mt-0">
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full "></div>
                                                </div>
                                                <div className="w-1/2">
                                                    <div className="w-[36%] h-4 bg-black-blackRock rounded-full mb-[7px]"></div>
                                                    <div className="w-4/5 h-5 bg-black-blackRock rounded-full"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="flex items-center justify-between w-full">
                                    <span className="text-xs lg:text-base text-white font-semibold">
                                        Active Coins
                                    </span>
                                    <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-86px)] lg:w-[calc(100%-117px)]"></p>
                                </div>
                                <div className="max-h-[490px] min-h-max">
                                    <div className="py-[7px] xl:py-2">
                                        <div className="overflow-auto max-h-[490px] min-h-max">
                                            {userAssetsPagination.map((userAsset: any, index: number) => (
                                                <div className="py-[7px] xl:py-2" key={index}>
                                                    <div className="flex relative items-center">
                                                        <div className="xl:border-8 border-black-darkBlack rounded-full absolute">
                                                            <div className="xl:h-[82px] xl:w-[82px] w-14 h-14 xl:p-4 bg-blue-dark-jaguar xl:bg-blue-slate rounded-full flex justify-center items-center
                                             -mt-9 ml-3 xl:mt-0 xl:ml-0">
                                                                <div className="w-9 h-9 xl:h-11 xl:w-11">
                                                                    <img src={userAsset.assetLogoUrl} alt={userAsset.assetSymbol} className="w-full h-full rounded-full" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="active-coin-table-responsive xl:active-coin-table">
                                                            <div className="W-1/2 md:w-full flex">
                                                                <div className="w-1/2">
                                                                    <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                                        {userAsset.assetSymbol}
                                                                    </p>
                                                                    <p className="text-sm lg:text-base font-semibold text-white">
                                                                        {userAsset.assetName}
                                                                    </p>
                                                                </div>
                                                                <div className="w-1/2">
                                                                    <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                                        Current Holding
                                                                    </p>
                                                                    <p className="text-sm lg:text-base font-semibold text-white">
                                                                        ${formatNumberWithCommas(parseFloat(userAsset.quoteQuantity.toString()).toFixed(2))}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="W-1/2 md:w-full flex mt-5 xl:mt-0">
                                                                <div className="w-1/2">
                                                                    <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                                        Floting P&L
                                                                    </p>
                                                                    <p className={`text-sm lg:text-base font-semibold ${userAsset.floatingValue < 0 ? 'text-persian' : 'text-emerald'}`}>
                                                                        ${formatNumberWithCommas(parseFloat(userAsset.floatingValue.toString()).toFixed(2))}
                                                                    </p>
                                                                </div>
                                                                <div className="w-1/2">
                                                                    <p className="text-xs lg:text-sm font-normal text-grey-suit">
                                                                        Closed P&L
                                                                    </p>
                                                                    <p className={`text-sm lg:text-base font-semibold ${userAsset.realizedEarning < 0 ? 'text-persian' : 'text-emerald'}`}>
                                                                        ${formatNumberWithCommas(parseFloat(userAsset.realizedEarning.toString()).toFixed(2))}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="block sm:flex items-center justify-between pl-2 pt-[3px]">
                        <div className="flex">
                            <Pagination
                                currentPage={pageNumber}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        <div className="flex items-center justify-end gap-2.5 pt-3.5 sm:pt-5">
                            <p className="text-white font-normal text-10 sm:text-sm">Page</p>
                            <div className="relative">
                                <button
                                    className="border bg-grey-jaguar border-grey-tuna rounded-md py-1 px-2 text-10 sm:text-sm cursor-pointer text-white mt-1"
                                    onClick={toggleUserAssetDropdown}
                                >
                                    {userAssetPageSize ?? "10"}
                                    <svg
                                        className="h-5 w-5 ml-2 inline-block"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                {isUserAssetPageSizeOpen && (
                                    <div className="absolute w-full text-white bg-black-darkBlack border border-grey-tuna rounded-md shadow-lg -top-28">
                                        {pageSizeOptions.map((option) => (
                                            <button key={option} className="py-1.5 px-4 hover:bg-grey-tuna cursor-pointer w-full" onClick={() => handleUserAssetChange(option)}>
                                                {option}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <p className="text-white font-normal text-10 sm:text-sm">of 10</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    appElement={document.getElementById('root') as HTMLElement}
                    isOpen={modalId > 0}
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            backdropFilter: 'blur(5px)',
                            zIndex: '99'
                        }
                    }}
                    contentLabel="Example Modal" className="w-full h-full flex justify-center items-center backtesting-modal">
                    <div style={{ display: modalId === 1 ? 'block' : 'none' }} className="sm:mx-0 w-[270px] sm:w-[85%] md:w-[624px] 2xl:w-[964px] relative z-[1] after:opacity-5 after:['*'] after:absolute after:z-[-1] after:top-2 after:left-1.5 after:w-[98%] after:h-[98%] after:bg-gradient after:blur-[2.5rem] before:['*'] before:absolute before:z-[-1] before:top-2 before:left-1.5 before:w-[98%] before:h-[98%] before:bg-gradient before:blur-[2.5rem]">
                        <div className="w-full lg:w-[624px] 2xl:w-[964px] bg-[#000] border-4 border-bottom relative">
                            <div className="bg-popup-backtesting bg-no-repeat bg-right h-full" style={{ backgroundSize: '80%' }}>
                                <div className="px-4 py-[26px] sm:p-7 2xl:p-12 flex w-full">
                                    <div className="hidden md:block md:w-[35%] lg:w-[25%] 2xl:w-[26%]">
                                        <div>
                                            <img src={Images.popupbot} draggable="false" alt="popup bot" className="absolute lg:-left-32 lg:-top-[35px] top-[49px] 2xl:-top-[57px] 2xl:-left-40 w-[184px] 2xl:w-[411px] lg:w-[280px]" />
                                        </div>
                                    </div>
                                    <div className="w-full md:w-[65%] lg:w-[75%] 2xl:w-[74%]">
                                        <div>
                                            <div className="flex justify-center items-center">
                                                <p className="text-xs w-3/4 2xl:w-4/5 sm:leading-6 2xl:leading-10 sm:text-xl 2xl:text-[31px] font-semibold text-white text-center">Experience Ailtra in Action:
                                                    <span className="text-gradient bg-gradient bg-clip-text"> Start Backtesting</span></p>
                                            </div>
                                            <div className="block md:hidden">
                                                <div className="flex justify-center my-5">
                                                    <img src={Images.popupbot} draggable="false" alt="popup bot" className=" w-[72px] sm:w-40" />
                                                </div>
                                            </div>
                                            <p className="text-center text-white sm:leading-5 2xl:mt-4 sm:mt-2 text-[11px] sm:text-xs 2xl:text-17">Start by choosing a past date and your preferred investment amount to see how much you could have earned with Ailtra. Set a start date and investment to simulate Ailtra's past performance.</p>
                                        </div>
                                        <div className="w-full">
                                            <div className="">
                                                <div className={`popup-datepicker relative mt-4 date-label-input w-full ${userExchangeTestAccount.startDate ? "date-selected" : ""}`}>
                                                    <DatePicker
                                                        id="dtpStartingDate"
                                                        tabIndex={1}
                                                        selected={userExchangeTestAccount.startDate}
                                                        onChange={handleStartDateChange}
                                                        className="bg-[#000] date text-white font-normal border border-bottom text-10 sm:text-xs 2xl:text-sm block px-5 py-2.5 sm:py-[11px] 2xl:py-4 focus:outline-none w-full date-autofill caret-white"
                                                        autoComplete="off"
                                                        placeholderText=""
                                                        dateFormat="do MMM yyyy"
                                                        maxDate={yesterday} />
                                                    <label htmlFor="name" className="absolute top-[11px] 2xl:top-[16px] left-0 text-grey-nobel text-10 sm:text-xs 2xl:text-sm  pointer-events-none transition duration-200 ease-in-outbg-white px-5 text-grey-darker">Choose Your Start Date</label>
                                                </div>
                                                <div className="relative amount-label-input mt-3 2xl:mt-5 w-full">
                                                    {(isFocused || userExchangeTestAccount.walletBalanceAmount) && (
                                                        <span className="absolute top-0 bottom-0 left-0 pl-5 flex items-center text-white z-10">$</span>
                                                    )}
                                                    <input
                                                        tabIndex={2}
                                                        type="text"
                                                        name="walletBalanceAmount"
                                                        value={userExchangeTestAccount.walletBalanceAmount}
                                                        onChange={onInputChange}
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter")
                                                                onBeginBackTest();
                                                        }}
                                                        placeholder=""
                                                        className="bg-[#000] text-white font-normal border border-bottom text-10 sm:text-xs 2xl:text-sm px-5 py-2.5 sm:py-[11px] 2xl:py-4 focus:outline-none w-full placeholder-grey-nobel date-autofill caret-white relative" autoComplete="off"
                                                        style={{ paddingLeft: (isFocused || userExchangeTestAccount.walletBalanceAmount) ? '30px' : '20px' }}
                                                    />
                                                    <label htmlFor="name" className="absolute top-[11px] 2xl:top-[16px] left-0 text-grey-nobel text-10 sm:text-xs 2xl:text-sm pointer-events-none transition duration-200 ease-in-outbg-white px-5 text-grey-darker">Set Your Initial Investment</label>
                                                </div>

                                                {/* <div className="relative">
                                                        <div tabIndex={3}
                                                            className=" bg-black-blackRock text-white font-normal text-sm cursor-pointer pl-2.5 pr-2.5 py-2.5 2xl:py-4 focus:outline-none mt-5 w-full flex justify-between items-center"
                                                            onClick={toggleRiskLevelDropdown}>
                                                            <span className={`text-grey-nobel ${userExchangeTestAccount.riskLevelId ? 'text-white' : ''}`}>{userExchangeTestAccount.riskLevel || "Risk level"}</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path
                                                                    d="M11.9465 5.45331H7.79316H4.05317C3.41317 5.45331 3.09317 6.22664 3.5465 6.67997L6.99983 10.1333C7.55317 10.6866 8.45317 10.6866 9.0065 10.1333L10.3198 8.81997L12.4598 6.67997C12.9065 6.22664 12.5865 5.45331 11.9465 5.45331Z"
                                                                    fill="white" />
                                                            </svg>
                                                        </div>
                                                        {isRiskLevelOpen && (
                                                            <div className="absolute mt-1 w-full text-white bg-black-blackRock shadow-lg cursor-pointer">
                                                                {risklevaloptions.map((risklevaloption: any) => (
                                                                    <div tabIndex={3}
                                                                        key={risklevaloption.id}
                                                                        className="py-1 px-3 hover:bg-white hover:text-black-pearl text-sm cursor-pointer"
                                                                        onClick={() => handleRiskLevelChange(risklevaloption)}>
                                                                        {risklevaloption.name}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div> */}
                                                <button onClick={() => onBeginBackTest()}
                                                    type="button"
                                                    disabled={loadingStates.loadingStartbot}
                                                    className={` py-2.5 sm:py-3 2xl:py-[15px] w-full mt-5 font-bold text-10 sm:text-xs 2xl:text-base text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover tracking-[0.28px] flex justify-center items-center gap-4 ${loadingStates.loadingStartbot ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`}>
                                                    {loadingStates.loadingStartbot ? (
                                                        <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'></span>Backtesting begun...</p>
                                                    ) : (
                                                        <div className="flex gap-4 items-center">
                                                            BEGIN BACKTEST
                                                        </div>
                                                    )}
                                                </button>
                                                {error && <p className="text-red-500 text-xs mt-1 text-start font-semibold absolute w-full">{error}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: modalId === 2 ? 'block' : 'none' }} className="sm:mx-0 w-[270px] sm:w-[85%] md:w-[653px] 2xl:w-[964px] relative z-[1] after:opacity-5 after:['*'] after:absolute after:z-[-1] after:top-2 after:left-1.5 after:w-[98%] after:h-[98%] after:bg-gradient after:blur-[2.5rem] before:['*'] before:absolute before:z-[-1] before:top-2 before:left-1.5 before:w-[98%] before:h-[98%] before:bg-gradient before:blur-[2.5rem]">
                        <div className="w-full lg:w-[653px] 2xl:w-[964px] bg-[#000] border-4 border-bottom">
                            <div className="bg-popup-backtesting bg-no-repeat bg-right h-full" style={{ backgroundSize: '80%' }}>
                                <div className="px-4 py-[26px] sm:p-7 2xl:p-12 flex w-full">
                                    <div className="hidden md:block w-[40%] 2xl:w-[42%]">
                                        <img src={Images.backtesingemail} draggable="false" className="absolute lg:-left-32 lg:-top-[69px] top-[46px] left-3 2xl:-top-[96px]  2xl:-left-52 w-[220px] 2xl:w-[594px] lg:w-[388px]" alt="Back testingemail" />
                                    </div>
                                    <div className="w-full md:w-[70%] lg:w-3/5 2xl:w-[58%]">
                                        <div>
                                            <p className="sm:leading-6 2xl:leading-10 text-xs sm:text-xl 2xl:text-[31px] font-semibold text-center text-white">Final Step: <span className="text-gradient bg-gradient bg-clip-text">Provide Your Email</span> to Secure Progress</p>
                                            <div className="block md:hidden">
                                                <div className="flex justify-center my-5">
                                                    <img src={Images.backtesingemail} draggable="false" alt="popup bot" className=" w-[97px] sm:w-40" />
                                                </div>
                                            </div>
                                            <p className="text-center text-white mt-[19px] 2xl:mt-[32px] text-[11px] sm:text-xs 2xl:text-17">Please provide your email address to proceed. We’ll use it to save your backtesting results, allowing you to access them anytime on Ailtra. Plus, we'll notify you via email with a very detailed report once your backtesting is complete. Your email is key to starting this insightful journey!</p>
                                        </div>
                                        <div className="w-full mt-4 2xl:mt-8">
                                            <div className="backtesting-email">
                                                <div className="relative float-label-input w-full">
                                                    <input type="email"
                                                        placeholder=""
                                                        name="email"
                                                        id="txtEmail"
                                                        value={userExchangeTestAccount.email}
                                                        onChange={onInputChange}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter")
                                                                onCreateBackTestingAccount();
                                                        }}
                                                        autoComplete="email"
                                                        className="bg-[#000] text-white font-normal text-sm border border-bottom lg:text-sm block pl-2.5 pr-2.5 py-[7px] sm:py-[9px] 2xl:py-4 w-full focus:outline-none focus:border-hover caret-white" />
                                                    <label htmlFor="name" className="absolute top-[12px] 2xl:top-4 left-0 text-grey-nobel text-[9px] sm:text-xs 2xl:text-sm pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Enter Your Email to Begin Backtesting Now!</label>
                                                </div>
                                                <button
                                                    onClick={() => onCreateBackTestingAccount()}
                                                    type="button"
                                                    disabled={loadingStates.loadingAuthenticate}
                                                    className={`py-2.5 sm:py-3 2xl:py-[15px] w-full mt-3.5 sm:mt-4 2xl:mt-9 font-bold text-10 sm:text-xs 2xl:text-base text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover tracking-[0.28px] flex justify-center items-center gap-4 ${loadingStates.loadingAuthenticate ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`}>
                                                    {loadingStates.loadingAuthenticate ? (
                                                        <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'></span>Generating OTP...</p>
                                                    ) : (
                                                        <div className="flex gap-4 items-center">
                                                            GENERATE OTP
                                                            <div>
                                                                <img src={Images.generateotp} alt="Generate-otp" className="w-7 2xl:w-11" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </button>
                                            </div>
                                            {error && <p className="text-red-500 text-xs font-semibold absolute w-full text-start mt-1">{error}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: modalId === 3 ? 'block' : 'none' }} className="sm:mx-0 w-[270px] sm:w-[85%] md:w-[574px] 2xl:w-[889px] relative z-[1] after:opacity-5 after:['*'] after:absolute after:z-[-1] after:top-2 after:left-1.5 after:w-[98%] after:h-[98%] after:bg-gradient after:blur-[2.5rem] before:['*'] before:absolute before:z-[-1] before:top-2 before:left-1.5 before:w-[98%] before:h-[98%] before:bg-gradient before:blur-[2.5rem]">
                        <div className="w-full lg:w-[574px] 2xl:w-[889px] bg-[#000] border-4 border-bottom">
                            <div className="bg-popup-backtesting bg-no-repeat bg-right h-full" style={{ backgroundSize: '80%' }}>
                                <div className="px-4 py-[26px] sm:p-7 2xl:p-12 flex w-full">
                                    <div className="hidden md:block w-[30%] 2xl:w-1/5">
                                        <img src={Images.optbacktesting} className="absolute lg:-left-32 lg:-top-[32px] top-[30px] left-4 2xl:-top-[62px] 2xl:-left-52 w-[160px] 2xl:w-[360px] lg:w-[222px]" draggable="false" alt="OTP backtesting" />
                                    </div>
                                    <div className="w-full md:w-[70%] lg:w-3/5 2xl:w-4/5">
                                        <div className="w-full">
                                            <div>
                                                <p className="sm:leading-6 2xl:leading-10 text-xs sm:text-xl 2xl:text-[31px] font-semibold text-center text-white"> Verify Your <span className="text-gradient bg-gradient bg-clip-text">Authorization </span></p>
                                                <div className="block md:hidden">
                                                    <div className="flex justify-center my-5">
                                                        <img src={Images.optbacktesting} draggable="false" alt="popup bot" className=" w-[72px] sm:w-40" />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <p className="text-center text-white mt-4 2xl:mt-8 text-[11px] sm:text-xs lg:text-xs 2xl:text-xl">Enter the 6-digit code sent to <span className="border-b border-white">{userExchangeTestAccount.email} </span> to verify your email and secure your backtesting history.</p>
                                                </div>
                                            </div>
                                            <div className="w-full flex justify-center mt-5 2xl:mt-[40px]">
                                                <div className="relative">
                                                    <div className="flex justify-center items-center">
                                                        <form className="flex gap-2 sm:gap-3 2xl:gap-5">
                                                            {codes.map((digit, index) => (
                                                                <input
                                                                    key={index}
                                                                    className="w-[30px] h-[30px] sm:w-12 sm:h-12 2xl:w-[72px] 2xl:h-[72px] text-xs sm:text-xl lg:text-4xl bg-[#000] text-white text-center border border-image-radius rounded-lg focus:outline-none"
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    id={`code-${index}`}
                                                                    maxLength={1}
                                                                    value={digit}
                                                                    onChange={(e) => handleOtpInput(e, index)}
                                                                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                                                    onPaste={(e) => handleOtpPaste(e, index)}
                                                                    //onKeyUp={(e) => autoVerifyOTP()}
                                                                />
                                                            ))}
                                                        </form>
                                                    </div>
                                                    <button onClick={() => onVerifyCode()}
                                                        type="button"
                                                        disabled={loadingStates.loadingOtpverify}
                                                        className={`py-2.5 sm:py-3 w-full font-bold text-10 sm:text-xs 2xl:text-base text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover tracking-[0.28px] flex justify-center items-center gap-4 mt-[25px] 2xl:mt-10 outline-none ${loadingStates.loadingOtpverify ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`}>
                                                        {loadingStates.loadingOtpverify ? (
                                                            <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'></span>Verifing...</p>
                                                        ) : (
                                                            <div className="flex gap-2 sm:gap-4 items-center">
                                                                VERIFY & START BACKTESTING
                                                                <div>
                                                                    <img src={Images.generateotp} alt="Generate-otp" className="w-7 sm:w-11" />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </button>
                                                    {error && <p className="text-red-500 text-xs mt-1 text-center font-semibold absolute w-full">{error}</p>}
                                                </div>
                                            </div>
                                            <div className="flex justify-center items-center mt-[17px] sm:mt-[23px] 2xl:mt-8.5 gap-14">
                                                <div onClick={onChangeEmail}>
                                                    <p className="text-10 sm:text-xs 2xl:text-17 text-blue-rock cursor-pointer border-b border-bottom">Change Email</p>
                                                </div>
                                                <div className="flex justify-center">
                                                    {resendTimer > 0 ? (
                                                        <p className="text-10 sm:text-xs 2xl:text-17 text-blue-rock border-b border-bottom w-[98px] sm:w-[113px] 2xl:w-[165px]">Resend OTP in {resendTimer}s</p>
                                                    ) : (
                                                        <button onClick={onResend} type="button"
                                                            className="font-normal text-10 sm:text-xs 2xl:text-17 text-blue-rock outline-none border-b border-bottom">
                                                            Resend OTP
                                                        </button>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: modalId === 4 ? 'block' : 'none' }} className="sm:mx-0 w-[270px] sm:w-[85%] md:w-[609px] 2xl:w-[889px] relative z-[1] after:opacity-5 after:['*'] after:absolute after:z-[-1] after:top-2 after:left-1.5 after:w-[98%] after:h-[98%] after:bg-gradient after:blur-[2.5rem] before:['*'] before:absolute before:z-[-1] before:top-2 before:left-1.5 before:w-[98%] before:h-[98%] before:bg-gradient before:blur-[2.5rem]">
                        <div className="w-full lg:w-[609px] 2xl:w-[889px] bg-[#000] border-4 border-bottom relative">
                            <div className="bg-popup-backtesting bg-no-repeat bg-right h-full" style={{ backgroundSize: '80%' }}>
                                <div className="px-4 py-7 sm:p-7 2xl:p-12 flex w-full">
                                    <div className="hidden md:block w-[35%] lg:w-[30%] 2xl:w-[26%]">
                                        <div>
                                            <img src={Images.alertbacktesting} draggable="false" alt="popup bot" className="absolute lg:-left-32 lg:-top-[41px] top-[18px] left-8 2xl:-top-[57px] 2xl:-left-40 w-[178px] 2xl:w-[380px] lg:w-[250px]" />
                                        </div>
                                    </div>
                                    <div className="w-full md:w-[65%] lg:w-[70%] 2xl:w-[74%]">
                                        <div className="mt-1">
                                            <p className="sm:leading-6 2xl:leading-10 text-xs sm:text-xl 2xl:text-[31px] font-semibold text-white text-center w-full">Existing  Backtesting <span className="text-gradient bg-gradient bg-clip-text"> Session Alert</span></p>
                                            <div className="block md:hidden">
                                                <div className="flex justify-center my-5">
                                                    <img src={Images.alertbacktesting} draggable="false" alt="popup bot" className="w-[72px] sm:w-40" />
                                                </div>
                                            </div>
                                            <p className="text-center text-white mt-4 2xl:mt-8.5 text-[11px] sm:text-xs lg:text-xs 2xl:text-xl">We found a backtesting session with similar parameters that was started recently. The initial started amount was [amount] and the session began on [datetime]. Please review the details below before proceeding.</p>
                                        </div>
                                        <div className="w-full">
                                            <div className="">
                                                <button onClick={() => onBeginBackTest()}
                                                    type="button"
                                                    disabled={loadingStates.loadingStartbot}
                                                    className={`py-2.5 sm:py-3 w-full mt-4.5 2xl:mt-10 font-bold text-10 sm:text-xs 2xl:text-base text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover tracking-[0.28px] flex justify-center items-center gap-4 ${loadingStates.loadingStartbot ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`}>
                                                    {loadingStates.loadingStartbot ? (
                                                        <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'></span>Backtesting begun...</p>
                                                    ) : (
                                                        <div className="flex gap-1 sm:gap-4 items-center">
                                                            TAKE ME TO THAT BACKTESTING
                                                            <div>
                                                                <img src={Images.generateotp} alt="Generate-otp" className="w-7 sm:w-11" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </button>
                                                {error && <p className="text-red-500 text-xs mt-1 text-start font-semibold absolute w-full">{error}</p>}
                                            </div>
                                            <div className="">
                                                <button onClick={() => onBeginBackTest()}
                                                    type="button"
                                                    disabled={loadingStates.loadingStartbot}
                                                    className={`py-2.5 sm:py-3 w-full mt-[15px] 2xl:mt-[27px] font-bold text-10 sm:text-xs 2xl:text-base border-2 border-bottom tracking-[0.28px] relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100 flex justify-center items-center gap-4 ${loadingStates.loadingStartbot ? 'bg-gradient-hover opacity-60' : ''}`}>
                                                    {loadingStates.loadingStartbot ? (
                                                        <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'></span>Starting BOT...</p>
                                                    ) : (
                                                        <div className="flex gap-1 sm:gap-4 items-center text-gradient bg-gradient bg-clip-text">
                                                            START A NEW BOT
                                                            <div>
                                                                <img src={Images.startbot} alt="Generate-otp" className="w-7 sm:w-11" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </button>
                                                {error && <p className="text-red-500 text-xs mt-1 text-start font-semibold absolute w-full">{error}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Backtesting;