import Images from "../assets/images";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useGetUserExchangeAccount, useGetExchangeAssetSuggestOrderSize, useUserExchangeAccountAutoGenerateOrderSize, useUserExchangeAccountManualOrderSize, usePatchUserExchangeAccount, useGetExchangeAssetsConfigure, useUpdateUserExchangeAccountProcessStatus } from "../services/exchange.service";
import { IExchangeAsset, IExchangeAccountOrderSizeSuggestion } from "../types/exchange";
import IResponse from "../types/response";
import useRenewAccessToken, { isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage, isUserExchangeStatusHasPermission, isNotNullUndefined, isTokenValid, handleError } from '../helpers/common';
import { useNavigate } from "react-router-dom";
import { useGetGeneralSettings } from "../services/generalsettings.service";
import { IGeneralSetting } from "../types/generalsettings";
import { storage } from "../utils/storage";
import * as Sentry from "@sentry/react";

const Setup = () => {
  const dotStyle = {
    width: "8px",
    height: "8px",
  };

  const activeDotStyle = {
    width: "10px",
    height: "10px",
  };

  const assetDotStyle = {
    width: "6px",
    height: "6px",
  };

  const assetActiveDotStyle = {
    width: "8px",
    height: "8px",
  };

  const marks = {
    5: "5x",
    10: "10x",
    15: "15x",
    20: "20x",
  };

  const userExchangeStatusCode = useRef("");
  const navigate = useNavigate();
  const [userExchangeAccountId, setUserExchangeAccountId] = useState("");
  //const [isCollpase, setIsCollpase] = useState(false);
  const [riskLevelId, setRiskLevel] = useState(1);
  const [futureWalletBalance, setFutureWalletBalance] = useState(0);
  const [isEnableAllCoins, setEnableAllCoins] = useState(true);
  //const [isApplyToAllCoinsVisible, setApplyToAllCoins] = useState(false);
  const [sortByType, setSortByType] = useState("ascending");
  const [searchTerm, setSearchTerm] = useState("");
  //const [isTradingSetupCollapse, setIsTradingSetupCollapse] = useState(false);
  const [exchangeAccountOrder, setExchangeAccountOrder] =
    useState<IExchangeAccountOrderSizeSuggestion>({
      userExchangeAccountId: "",
      // strategyId: 0,
      // positionSide: "",
      // firstLongOrderAmount: 0,
      // firstShortOrderAmount: 0,
      // stopLoss: 0,
      // leverage: 0,
      // isDoubleFirstLongOrder: false,
      // isDoubleFirstShortOrder: false,
      isAutoMode: false,
    });
  const [exchangeAssets, setExchangeAssets] = useState<IExchangeAsset[]>([]);
  const [riskLevels, setRiskLevels] = useState<IGeneralSetting[]>([]);
  const [formResponseError, setFormResponseError] = useState('');
  const renewAccessToken = useRenewAccessToken();

  useEffect(() => {
    if (isUserExchangeStatusHasPermission(10)) {
      navigate(statusWiseOnboardingRedirectToPage());
    } else {
      const urlSearchParams = new URLSearchParams(window.location.search);
      setUserExchangeAccountId(urlSearchParams.get("id") ?? "");
      onGetExchangeAssetSuggestOrderSize(urlSearchParams.get("id") ?? "");
      onGetUserExchangeAccount();
      onGetRiskLevel();
      //onGetExchangeAssets(urlSearchParams.get("id") ?? "");
    }
  }, []);

  const onGetUserExchangeAccount = async () => {
    getUserExchangeAccount.mutate({});
  };

  const updateExchangeProcessStatusMutation = useUpdateUserExchangeAccountProcessStatus({
    onSuccess: async (response: IResponse) => {
      if (response.statusCode === 200) {
        if (userExchangeStatusCode.current === "TRADING_MODE_UPDATED") {
          storage.setUserExchangeStatusId(8);
          navigate('/connect/binance/consent');
        }
      }
      if (!isTokenValid(response)) {
        let tokenRenewed = await renewAccessToken();
        if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
          updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId, "statusCode": userExchangeStatusCode.current });
        }
      }
      else {
        setFormResponseError(response.statusMessage);
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const getUserExchangeAccount = useGetUserExchangeAccount({
    onSuccess: (response: IResponse) => {
      const userExchangeAccount = (response !== null) ? response.data[0] : null;
      if (isNotNullUndefined(userExchangeAccount)) {
        setRiskLevel(userExchangeAccount.riskLevelId);
        setFutureWalletBalance(userExchangeAccount.futureWalletBalance);
        if (userExchangeAccount.statusId <= 0) {
          updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccount.userExchangeAccountId, "statusCode": "TRADING_MODE_UPDATED" });
          setTimeout(() => { onGetUserExchangeAccount() }, 3500);
        }
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const onGetExchangeAssetSuggestOrderSize = async (userExchangeAccountId: string) => {
    const updatedExchangeAssets: IExchangeAsset[] = [];
    setExchangeAssets(updatedExchangeAssets);
    if (isNotNullUndefinedBlank(storage.getToken())) {
      getExchangeAssetSuggestOrderSize.mutate(userExchangeAccountId);
    }
  };

  const getExchangeAssetSuggestOrderSize = useGetExchangeAssetSuggestOrderSize({
    onSuccess: (response: IResponse) => {
      if (isNotNullUndefined(response.data) && response.data.length > 0) {
        response.data = response.data.sort(
          (a: { assetName: string }, b: { assetName: any }) => {
            return a.assetName.localeCompare(b.assetName);
          }
        );
        setExchangeAccountOrder({
          ...exchangeAccountOrder,
          isAutoMode: response.data[0].isAutoMode,
        });
        const updatedExchangeAssets = response.data.map((x: any) => {
          return {
            ...x,
            firstLongOrderAmount: x.firstLongOrderAmount,
            firstShortOrderAmount: x.firstShortOrderAmount,
            stopLoss: x.stopLoss,
            leverage: x.leverage,
            isDoubleFirstLongOrder: x.isDoubleFirstLongOrder,
            isDoubleFirstShortOrder: x.isDoubleFirstShortOrder,
            isEnable: true
          };
        });
        setExchangeAssets(updatedExchangeAssets);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  // const onGetExchangeAssets = async (userExchangeAccountId: string) => {
  //   const updatedExchangeAssets: IExchangeAsset[] = [];
  //   setExchangeAssets(updatedExchangeAssets);
  //   if (isNotNullUndefinedBlank(storage.getToken())) {
  //     getExchangeAssets.mutate(userExchangeAccountId);
  //   }
  // };

  // const getExchangeAssets = useGetExchangeAssetsConfigure({
  //   onSuccess: (response: IResponse) => {
  //     if (isNotNullUndefined(response.data) && response.data.length > 0) {
  //       response.data = response.data.sort(
  //         (a: { assetName: string }, b: { assetName: any }) => {
  //           return a.assetName.localeCompare(b.assetName);
  //         }
  //       );
  //       setExchangeAccountOrder({
  //         ...exchangeAccountOrder,
  //         isAutoMode: response.data[0].isAutoMode,
  //       });
  //       const updatedExchangeAssets = response.data.map((x: any) => {
  //         return {
  //           ...x,
  //           firstLongOrderAmount: x.firstLongOrderAmount,
  //           firstShortOrderAmount: x.firstShortOrderAmount,
  //           stopLoss: x.stopLoss,
  //           leverage: x.leverage,
  //           isDoubleFirstLongOrder: x.isDoubleFirstLongOrder,
  //           isDoubleFirstShortOrder: x.isDoubleFirstShortOrder,
  //           isEnable: true
  //         };
  //       });
  //       setExchangeAssets(updatedExchangeAssets);
  //     }
  //   },
  //   onError: (error) => {
  //     handleError(error);
  //   },
  // });

  const onGetRiskLevel = async () => {
    getRiskLevel.mutate('exchangeaccount_risklevel');
  };

  const getRiskLevel = useGetGeneralSettings({
    onSuccess: async (response: IResponse) => {
      if (!isTokenValid(response)) {
        let tokenRenewed = await renewAccessToken();
        if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
          onGetRiskLevel();
        }
      }
      if (isNotNullUndefined(response.data) && response.data.length > 0) {
        const updateGeneralSetting = response.data.map((x: any) => {
          return {
            ...x,
            settingId: x.settingId,
            settingName: x.settingName,
            settingType: x.settingType,
            settingValue: x.settingValue,
            description: x.description,
            dataType: x.dataType
          };
        });
        setRiskLevels(updateGeneralSetting);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const UpdateUserExchangeAccount = usePatchUserExchangeAccount({
    onSuccess: (response: IResponse) => {
    },
    onError: (error) => {
      Sentry.captureException(error);
      alert(error.message);
    },
  });

  const onPatchUserExchangeAccount = async (bodyParam: any) => {
    let userExchangeAccountDetails: any = [];
    userExchangeAccountDetails.push(userExchangeAccountId);
    userExchangeAccountDetails.push(bodyParam);

    UpdateUserExchangeAccount.mutate(userExchangeAccountDetails);
  };

  // const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   if (name === "isDoubleFirstLongOrder" || name === "isDoubleFirstShortOrder") {
  //     setExchangeAccountOrder({
  //       ...exchangeAccountOrder,
  //       [name]: event.target.checked,
  //       isAutoMode: false,
  //     });
  //   } else {
  //     const updatedExchangeAssets = {
  //       ...exchangeAccountOrder,
  //       [name]: value,
  //       isAutoMode: false,
  //     };
  //     setExchangeAccountOrder(updatedExchangeAssets);
  //   }
  //   setApplyToAllCoins(true);
  // };

  const assetInputChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value, checked } = event.target;
    const updatedExchangeAssets = [...exchangeAssets];
    if (name === "isDoubleFirstLongOrder" || name === "isDoubleFirstShortOrder" || name === "isEnable") {
      updatedExchangeAssets[index][name] = checked;
    } else {
      updatedExchangeAssets[index][name] = value;
    }
    setExchangeAssets(updatedExchangeAssets);
    setExchangeAccountOrder({ ...exchangeAccountOrder, isAutoMode: false });

    if (name === "isEnable") {
      if (exchangeAssets.filter((x) => !x.isEnable).length > 0) {
        setEnableAllCoins(false);
      }
      else { setEnableAllCoins(true); }
    }
  };

  // const applyChangeToAllCoins = () => {
  //   const updatedExchangeAssets = exchangeAssets.map((x: any) => {
  //     return {
  //       ...x,
  //       firstLongOrderAmount: exchangeAccountOrder.firstLongOrderAmount,
  //       firstShortOrderAmount: exchangeAccountOrder.firstShortOrderAmount,
  //       stopLoss: exchangeAccountOrder.stopLoss,
  //       leverage: exchangeAccountOrder.leverage,
  //       isDoubleFirstLongOrder: exchangeAccountOrder.isDoubleFirstLongOrder,
  //       isDoubleFirstShortOrder: exchangeAccountOrder.isDoubleFirstShortOrder,
  //     };
  //   });
  //   setExchangeAssets(updatedExchangeAssets);
  // };

  const enableAllCoins = () => {
    setEnableAllCoins(!isEnableAllCoins);
    const updatedExchangeAssets = exchangeAssets.map((x: any) => {
      return {
        ...x,
        isEnable: !isEnableAllCoins,
      };
    });
    setExchangeAssets(updatedExchangeAssets);
  };

  // const onResetCoins = () => {
  //   onGetUserExchangeAccount();
  //   onGetExchangeAssetSuggestOrderSize(userExchangeAccountId);
  //   //onGetExchangeAssets(userExchangeAccountId);
  //   setExchangeAccountOrder({ ...exchangeAccountOrder, isAutoMode: true });
  //   setApplyToAllCoins(false);
  // };

  // const handleSlideChange = (value: any) => {
  //   setExchangeAccountOrder({
  //     ...exchangeAccountOrder,
  //     leverage: value,
  //     isAutoMode: false,
  //   });
  //   setApplyToAllCoins(true);
  // };

  const handleAssetSlideChange = (value: any, index: number) => {
    const updatedExchangeAssets = [...exchangeAssets];
    updatedExchangeAssets[index].leverage = value;
    setExchangeAssets(updatedExchangeAssets);
  };

  // const onCollapseAndExpand = () => {
  //   setIsCollpase(!isCollpase);
  // };

  const onRiskLevelChange = (event: any) => {
    const { value } = event.target;
    setRiskLevel(value);
    var param = {
      'riskLevelId': parseInt(value)
    };
    onPatchUserExchangeAccount(param);
    onGetExchangeAssetSuggestOrderSize(userExchangeAccountId);
  };

  // const onModeChange = () => {
  //   const updatedExchangeAssetsCoin = exchangeAccountOrder;
  //   updatedExchangeAssetsCoin.isAutoMode = false;
  //   setExchangeAccountOrder(updatedExchangeAssetsCoin);
  //   setApplyToAllCoins(true);
  // };

  const sortedExchangeAssetsAscending = [...exchangeAssets].sort((a, b) => {
    return a.assetName.localeCompare(b.assetName);
  });

  const sortedExchangeAssetsDescending = [...exchangeAssets].sort((a, b) => {
    return b.assetName.localeCompare(a.assetName);
  });

  const sortBy = (event: any) => {
    const { value } = event.target;
    setSortByType(value);
    if (value === "ascending") setExchangeAssets(sortedExchangeAssetsAscending);
    else if (value === "descending")
      setExchangeAssets(sortedExchangeAssetsDescending);
  };

  const filteredExchangeAssets = exchangeAssets.filter((exchangeAsset) => {
    const assetName = exchangeAsset.assetName.toLowerCase();
    const baseSymbol = exchangeAsset.baseSymbol.toLowerCase();
    const searchTermLowerCase = searchTerm.toLowerCase();
    return assetName.includes(searchTermLowerCase) || baseSymbol.includes(searchTermLowerCase);
  });

  const onStartAIBot = async () => {
    if (exchangeAccountOrder.isAutoMode) {
      userExchangeAccountAutoGenerateOrderSize.mutate(userExchangeAccountId);
    }
    else if (!exchangeAccountOrder.isAutoMode) {
      let userExchangeAccountManualOrderSizeRequest: any = [];
      exchangeAssets.forEach(x => {
        let asset = {
          "assetId": x.assetId,
          "firstLongOrderAmount": x.firstLongOrderAmount,
          "firstShortOrderAmount": x.firstShortOrderAmount,
          "leverage": x.leverage,
          "isDoubleFirstLongOrder": x.isDoubleFirstLongOrder,
          "isDoubleFirstShortOrder": x.isDoubleFirstShortOrder,
          "stopLoss": x.stopLoss,
          "userExchangeAccountId": userExchangeAccountId
        };
        userExchangeAccountManualOrderSizeRequest.push(asset);
      });
      userExchangeAccountManualOrderSize.mutate(userExchangeAccountManualOrderSizeRequest);
    }
    userExchangeStatusCode.current = "TRADING_MODE_UPDATED";
    updateExchangeProcessStatusMutation.mutate({ "userExchangeAccountId": userExchangeAccountId, "statusCode": userExchangeStatusCode.current });
  };

  const userExchangeAccountAutoGenerateOrderSize = useUserExchangeAccountAutoGenerateOrderSize({
    onSuccess: (response: IResponse) => {
      if (!isTokenValid(response)) {
        userExchangeAccountAutoGenerateOrderSize.mutate(userExchangeAccountId);
      } else {
        alert(response.statusMessage);
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      alert(error.message);
    },
  });

  const userExchangeAccountManualOrderSize = useUserExchangeAccountManualOrderSize({
    onSuccess: (response: IResponse) => {
      if (response.statusCode === 200 || response.statusCode === 201) {
        alert(response.statusMessage);
      } else {
        console.error(response.statusMessage);
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      alert(error.message);
    },
  });


  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // const toggleMenu = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };

  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleModeChange = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className="py-8 px-9 overflow-y-scroll scroll-smooth">
      <div>
        <h2 className="text-3xl text-white font-semibold">
          AI Trade Setup ✨
        </h2>
        <p className="text-15 font-normal leading-6 tracking-wide text-white mt-5 hidden">
          Ailtra's AI suggests an optimized trading strategy based on your
          Low risk level and future wallet balance. Enabled coins are listed
          below. Switch to manual mode for custom strategy setup.
        </p>
      </div>
      <div className="3xl:w-1/4 w-full bg-grey-bunker mt-6 p-5 flex items-center justify-between xl:w-1/3 sm:w-1/2 pointer-events-none">
        <div>
          <p className="text-15 text-white">Future Wallet</p>
          <h2 className="text-lg font-semibold text-emerald mt-2">
            ${futureWalletBalance}
          </h2>
        </div>
        <div className="ml-3">
          <div className="flex items-center">
            <p className="text-base font-normal text-white">Risk level</p>
          </div>
          {/* <Select options={options} /> */}
          <span className=" flex relative">
            <select
              id="underline_select"
              value={riskLevelId}
              onChange={onRiskLevelChange}
              className="px-0 pr-3 mt-2 bg-grey-bunker text-lg font-semibold text-blue-deep-sky border-0 focus:outline-none focus:ring-0 appearance-none z-10 bg-transparent risk-option"
            >
              {riskLevels.map((option) => (
                <option
                  key={option.settingValue}
                  value={option.settingValue}
                >
                  {option.settingName}
                </option>
              ))}
            </select>
            <img
              className={`ml-1 absolute ${riskLevelId == 2 ? "left-17.5" : "left-11"
                } right-0 bottom-1`}
              src={Images.arrowdown}
              alt="Arrowdown"
            />
          </span>
        </div>
      </div>

      <div className="mt-9">
        {/* <div className="mb-4 border-b-2 border-grey-ebony-Clay">
          <ul
            className="flex flex-wrap -mb-px text-sm font-bold text-center hidden"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li className="after:content-[''] after:w-[2px] after:h-6 after:bg-grey-ebony-Clay after:relative after:top-1.5 after:inline-flex" role="presentation" key={"automatic"}>
              <button
                className={`inline-block p-4 text-white mr-10 ${exchangeAccountOrder.isAutoMode ? 'border-b-2 border-bottom' : ''}`}
                id="profile-tab"
                data-tabs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Automatic
              </button>
            </li>
            <li role="presentation" key={"manual"}>
              <button
                className={`inline-block p-4 text-white ml-10 ${!exchangeAccountOrder.isAutoMode ? 'border-b-2 border-bottom' : ''}`}
                id="dashboard-tab"
                data-tabs-target="#dashboard"
                type="button"
                role="tab"
                onClick={onModeChange}
                aria-controls="dashboard"
                aria-selected="false">
                Manual
              </button>
            </li>
          </ul>
        </div> */}

        <div id="myTabContentParent">
          {/* <div id="myTabContent">
            <div>
              <h2>
                <button
                  type="button"
                  className="flex items-center justify-between cursor-pointer w-full mt-6 pb-3 font-normal border-b border-grey-ebony-Clay text-lg text-left text-white rounded-t-xl"
                  onClick={() => setIsTradingSetupCollapse(!isTradingSetupCollapse)}>
                  <span>Trading setting</span>
                  <img src={Images.roundarrowdown} className={`transform transition duration-700 ${isTradingSetupCollapse ? 'rotate-90' : 'rotate-360'}`} alt="Roundarrowdown" />
                </button>
              </h2>
            </div>
          </div> */}

          {/* {!isCollpase ? (
            <div className={`collaps-content ${!isTradingSetupCollapse ? "collaps-open" : ""} pointer-events-none`}>
              <div className="justify-between gap-[68px] 2xl:flex xl:block items-center mt-5">
                <div className="left w-full flex">
                  <div className="w-4/5 xl:mr-4 mr-4">
                    <div className="flex items-center mb-4">
                      <label htmlFor="setup-long-order" className="block text-sm font-normal text-grey-nobel mr-2">
                        Long order
                      </label>
                      <div className="relative flex flex-col items-center group">
                        <img
                          className="cursor-pointer"
                          src={Images.infocircle}
                          alt="Infocircle"
                        />
                        <div className="absolute bottom-0 flex-col items-center hidden mb-6 mr-2 group-hover:flex">
                          <span className="relative z-10 p-3  text-xs leading-4 text-white whitespace-no-wrap rounded-md bg-grey-bunker shadow-lg w-37.5">
                            A top aligned tooltip A top aligned tooltip A
                            top aligned tooltip.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      <span className="relative text-white left-5 font-semibold -ml-2">$</span>
                      <input
                        id="setup-long-order"
                        type="number"
                        name="firstLongOrderAmount"
                        value={exchangeAccountOrder.firstLongOrderAmount}
                        onChange={inputChange}
                        className="bg-grey-ebony-Clay border  w-full text-white font-semibold border-grey-tuna  text-sm block pl-5 pr-2.5 py-2.5 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="w-4/5">
                    <div className="flex items-center mb-4">
                      <label htmlFor="setup-short-order" className="block text-sm font-normal text-grey-nobel mr-2">
                        Short order
                      </label>
                      <div className="relative flex flex-col items-center group">
                        <img
                          className="cursor-pointer"
                          src={Images.infocircle}
                          alt="Infocircle"
                        />
                        <div className="absolute bottom-0 flex-col items-center hidden mb-6 mr-2 group-hover:flex">
                          <span className="relative z-10 p-3  text-xs leading-4 text-white whitespace-no-wrap rounded-md bg-grey-bunker shadow-lg w-37.5">
                            A top aligned tooltip A top aligned tooltip A
                            top aligned tooltip.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      <span className="relative text-white font-semibold left-5 -ml-2">$</span>
                      <input
                        id="setup-short-order"
                        type="number"
                        name="firstShortOrderAmount"
                        value={exchangeAccountOrder.firstShortOrderAmount}
                        onChange={inputChange}
                        className="bg-grey-ebony-Clay border w-full  text-white font-semibold border-grey-tuna text-sm block pl-5 pr-2.5 py-2.5 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="w-4/5 xl:mr-4 ml-4 lg:mr-0">
                    <div className="flex items-center mb-4">
                      <label htmlFor="setup-stop-loss" className="block text-sm font-normal text-grey-nobel mr-2">
                        Stop loss
                      </label>
                    </div>
                    <div className="flex items-center justify-end">
                      <span className="relative font-semibold text-white left-5 -ml-2">$</span>
                      <input
                        id="setup-stop-loss"
                        type="number"
                        name="stopLoss"
                        min={0}
                        value={exchangeAccountOrder.stopLoss}
                        onChange={inputChange}
                        className="bg-grey-ebony-Clay w-full border text-white font-semibold border-grey-tuna text-sm block pl-5 pr-2.5 py-2.5 focus:outline-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="right w-full">
                  <div className="w-full">
                    <div className="flex justify-between items-center 2xl:-mt-5 mt-5">
                      <h2 className="text-sm font-semibold text-white">
                        Adjust leverage
                      </h2>
                      <p className="bg-gradient text-white text-sm text-center font-semibold py-[7px] px-[11px] w-11">
                        {exchangeAccountOrder.leverage}x
                      </p>
                    </div>
                    <Slider
                      className="range-slider mt-3.5 w-[97%]"
                      min={5}
                      max={20}
                      value={exchangeAccountOrder.leverage}
                      step={1}
                      marks={marks}
                      dotStyle={dotStyle}
                      activeDotStyle={activeDotStyle}
                      onChange={handleSlideChange}
                      reverse={false}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-black-russian-800 w-full mt-8 py-7 px-3 flex items-center justify-between">
                <div className="flex items-center">
                  <h2 className="text-sm font-semibold text-white mr-14">
                    Enable first order sizing doubling
                  </h2>
                  <div className="flex items-center">
                    <span className="ml-3 text-xs font-semibold text-white mr-3">
                      For long
                    </span>
                    <label className="relative inline-flex items-center mr-5 cursor-pointer">
                      <input
                        type="checkbox"
                        name="isDoubleFirstLongOrder"
                        checked={exchangeAccountOrder.isDoubleFirstLongOrder}
                        onChange={inputChange}
                        className="sr-only peer"
                      />
                      <div className="w-[32px] h-5 rounded-full peer bg-grey-storm peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <span className="ml-3 text-xs font-semibold text-white mr-3">
                      For Short
                    </span>
                    <label className="relative inline-flex items-center mr-5 cursor-pointer">
                      <input
                        type="checkbox"
                        name="isDoubleFirstShortOrder"
                        checked={exchangeAccountOrder.isDoubleFirstShortOrder}
                        onChange={inputChange}
                        className="sr-only peer"
                      />
                      <div className="w-[32px] h-5 rounded-full peer bg-grey-storm  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                    </label>
                  </div>
                </div>
                <div>
                  <img
                    className="-mt-7"
                    src={Images.dashboardbitcoin}
                    alt="Dashboardbitcoin"
                  />
                </div>
              </div>
              {isApplyToAllCoinsVisible ? (
                <div>
                  <div className="w-full text-center">
                    <button
                      type="button"
                      onClick={applyChangeToAllCoins}
                      className="w-80 mt-11 py-3.5 3xl:py-5 font-bold text-sm text-white bg-gradient tracking-[0.28px]"
                    >
                      APPLY TO ALL ENABLED COIN
                    </button>
                  </div>
                  <button
                    className="flex justify-center items-center mt-5 mx-auto cursor-pointer"
                    onClick={onResetCoins}
                  >
                    <img src={Images.rotateleft} alt="Rotateleft" />
                    <p className="text-xs font-semibold text-white ml-2">
                      Reset coins
                    </p>
                  </button>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div></div>
          )} */}
          <div className="flex items-center mt-10 justify-between 2xl:flex-nowrap xl:flex-nowrap lg:flex-wrap">
            <div className="2xl:flex xl:flex lg:block 2xl:w-auto xl:w-auto lg:w-full justify-between">
              <h2 className="text-sm font-semibold text-white 2xl:mb-0 xl:mb-0 lg:mb-4">
                Coin selection
              </h2>
            </div>
            <div className="flex 2xl:bg-black-russian xl:bg-black-russian lg:bg-black-russian-800 2xl:p-0 xl:p-0 lg:p-4 2xl:w-auto xl:w-auto lg:w-full justify-between">
              <div className="flex items-center 3xl:mr-44 hidden">
                <span className="text-xs font-semibold text-white mr-3">
                  Enable all coins
                </span>
                <label className="relative inline-flex items-center mr-5 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isEnableAllCoins}
                    onChange={enableAllCoins}
                    className="sr-only peer"
                  />
                  <div className="w-[28px] h-[19px] bg-grey-storm rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[5px] after:left-1.5 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                </label>
              </div>
              <div className="hidden md:flex relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-5 ml-2">
                  <img src={Images.magnifer} alt="Magnifer" />
                </div>
                <input
                  id="search"
                  type="text"
                  name="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="text-xs text-white font-normal pl-10 pr-4 bg-black-blackRock xl:w-80 h-10 focus:outline-none lg:w-72"
                  placeholder="Start typing to search coin"
                />
              </div>
              <div className="ml-3 flex items-center">
                <p className="text-xs text-white font-normal mr-3 ml-6">
                  Sort by
                </p>
                <select
                  value={sortByType}
                  onChange={sortBy}
                  id="underline_select"
                  className="block py-2.5 px-2 bg-black-blackRock text-sm  border-0 text-white  focus:outline-none focus:ring-0"
                >
                  <option value="ascending">Asset Name (A-Z)</option>
                  <option value="descending">Asset Name (Z-A)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {
          (filteredExchangeAssets.length > 0) ?
            <div id="coinGrids" className="grid grid-cols-1 gap-10 3xl:gap-5 mt-8 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 pointer-events-none">
              {
                filteredExchangeAssets.map((exchangeAsset: any, index) => (
                  <div key={index} className="bg-black-black-russian p-6">
                    <div className="flex justify-between mb-5 items-center">
                      <div className="flex items-center">
                        <div className="w-[30px]">
                          <img src={exchangeAsset.assetLogoUrl} alt="Coin logo" />
                        </div>
                        <h2 className="text-xs text-white font-normal ml-2">
                          {exchangeAsset.assetName}
                          <span className="font-semibold">
                            ({exchangeAsset.baseSymbol})
                          </span>
                        </h2>
                      </div>
                      <div className="flex">
                        <label className="relative inline-flex items-center  cursor-pointer">
                          <input
                            type="checkbox"
                            checked={exchangeAsset.isEnable}
                            name="isEnable"
                            onChange={(e) => assetInputChange(e, index)}
                            className="sr-only peer"
                          />
                          <div className="w-[32px] h-5 bg-grey-storm rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-col">
                        <table className="min-w-full text-center text-sm font-light">
                          <thead className="border-b-2 font-medium border-black-blackRock">
                            <tr>
                              <th className="border-r-2 align-baseline border-black-blackRock pb-5 pt-0.5 leading-[5px] text-xs font-normal text-white">
                                Long
                              </th>
                              <th className=" px-6 py-4 align-baseline text-xs pb-5 pt-0.5 leading-[5px] font-normal text-white">
                                Short
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="whitespace-nowrap border-r-2 pr-4  pb-2 py-4 border-black-blackRock">
                                <div className="flex items-center justify-between">
                                  <div className="whitespace-nowrap">
                                    <label htmlFor="setup-long-lot-size" className=" text-xs font-normal text-white mr-2">
                                      Lot size
                                    </label>
                                  </div>
                                  <div className="flex items-center justify-end">
                                    <span className="text-xs font-semibold relative text-white left-4">$</span>
                                    <input
                                      id="setup-long-lot-size"
                                      type="number"
                                      min={0}
                                      name="firstLongOrderAmount"
                                      value={exchangeAsset.firstLongOrderAmount}
                                      onChange={(e) => assetInputChange(e, index)}
                                      className="bg-black-blackRock text-white  border-grey-tuna text-xs font-semibold block pl-[17px] pr-2.5 py-2.5 focus:outline-none w-[70%] float-right"
                                    />
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap pl-4  py-4 pb-2">
                                <div className="flex items-center justify-between">
                                  <div className="whitespace-nowrap">
                                    <label htmlFor="setup-short-lot-size" className=" text-xs font-normal text-white mr-2">
                                      Lot size
                                    </label>
                                  </div>
                                  <div className="flex items-center justify-end">
                                    <span className="text-xs font-semibold relative text-white left-4">$</span>
                                    <input
                                      id="setup-short-lot-size"
                                      type="number"
                                      min={0}
                                      name="firstShortOrderAmount"
                                      value={exchangeAsset.firstShortOrderAmount}
                                      onChange={(e) => assetInputChange(e, index)}
                                      className="bg-black-blackRock text-white  border-grey-tuna text-xs font-semibold block pl-[17px] pr-2.5 py-2.5 focus:outline-none w-[70%] float-right"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="border-r-2 pr-4  pb-2 py-4 border-black-blackRock">
                                <div className="flex items-center justify-between">
                                  <span className="text-xs font-normal text-white mr-3 text-start">
                                    Double 1st order
                                  </span>
                                  <label className="relative inline-flex items-center  cursor-pointer">
                                    <input
                                      type="checkbox"
                                      name="isDoubleFirstLongOrder"
                                      checked={exchangeAsset.isDoubleFirstLongOrder}
                                      onChange={(e) => assetInputChange(e, index)}
                                      className="sr-only peer"
                                    />
                                    <div className="w-[32px] h-5 rounded-full peer bg-grey-storm peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all 0 peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                                  </label>
                                </div>
                              </td>
                              <td className="pl-4 py-4 pb-2">
                                <div className="flex items-center justify-between">
                                  <span className="text-xs font-normal text-white mr-3 text-start">
                                    Double 1st order
                                  </span>
                                  <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                      type="checkbox"
                                      name="isDoubleFirstShortOrder"
                                      checked={exchangeAsset.isDoubleFirstShortOrder}
                                      onChange={(e) => assetInputChange(e, index)}
                                      className="sr-only peer"
                                    />
                                    <div className="w-[32px] h-5 rounded-full peer bg-grey-storm peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1.5 after:left-2 after:bg-black-russian-800 after:rounded-full after:h-2 after:w-2 after:transition-all  peer-checked:bg-gradient-to-tr from-blue-deep-sky to-atlantis"></div>
                                  </label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="xl:flex xl:justify-between flex justify-between items-center">
                          <div className="2xl:w-[47%] xl:w-[47%] w-[47%]">
                            <div className="flex items-center mb-4 mt-6">
                              <label htmlFor="coin-list-stop-loss" className="block text-sm font-normal text-white mr-2">
                                Stop loss
                              </label>
                            </div>
                            <div className="flex items-center justify-end">
                              <span className="text-xs font-semibold relative text-white left-4 -ml-2">$</span>
                              <input
                                id="coin-list-stop-loss"
                                type="number"
                                name="stopLoss"
                                min={0}
                                value={exchangeAsset.stopLoss}
                                onChange={(e) => assetInputChange(e, index)}
                                className="bg-black-blackRock w-full text-white  border-grey-tuna text-xs font-semibold block pl-[17px] pr-2.5 py-2.5 focus:outline-none"
                              />
                            </div>
                          </div>
                          <div className="2xl:w-[44%] xl:w-[47%] xl:mr-[10px] mt-4 w-[48%]">
                            <div className="flex justify-between items-center">
                              <h2 className="text-sm font-normal text-white">
                                leverage
                              </h2>
                            </div>
                            <Slider
                              className="custom-slider range-slider coin-renge mt-4 mb-4"
                              min={5}
                              max={20}
                              value={exchangeAsset.leverage}
                              step={1}
                              marks={marks}
                              dotStyle={assetDotStyle}
                              activeDotStyle={assetActiveDotStyle}
                              onChange={(e) => handleAssetSlideChange(e, index)}
                              reverse={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div> : <div><p className="mt-3 w-full text-xl font-normal text-white text-center">No records found</p></div>
        }
      </div>

      <div className="w-full text-center">
        {
          (filteredExchangeAssets.length > 0) ?
            <button type="button"
              className="w-80 mt-11 py-3.5 3xl:py-5 font-bold text-sm text-white btn-hover-animation hover:bg-[right_center] bg-gradient-hover tracking-[0.28px]"
              onClick={onStartAIBot}>
              START AI BOT
            </button> : <span></span>
        }
      </div>
      {formResponseError && <p className="text-red-500 text-xs font-semibold mt-1 absolute">{formResponseError}</p>}
    </div>
  );
};

export default Setup;