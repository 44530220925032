import { isValidJSON } from "../helpers/common";

const storagePrefix = 'ailtra_';

const storage = {
    getToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}token`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}token`)) : "";
    },
    setToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
    },
    clearToken: () => {
        window.localStorage.removeItem(`${storagePrefix}token`);
    },
    getRefreshToken: () => {
        return (isValidJSON(window.localStorage.getItem(`${storagePrefix}refreshToken`))) ? JSON.parse(window.localStorage.getItem(`${storagePrefix}refreshToken`)) : "";
    },
    setRefreshToken: (token) => {
        window.localStorage.setItem(`${storagePrefix}refreshToken`, JSON.stringify(token));
    },
    clearRefreshToken: () => {
        window.localStorage.removeItem(`${storagePrefix}refreshToken`);
    },
    isAuthorized: () => {
        const token = storage.getToken();
        return !!token;
    },
    setUserRoleId: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}userRoleId`, statusId);
    },
    getUserRoleId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userRoleId`));
    },
    clearUserRoleId: () => {
        return (window.localStorage.removeItem(`${storagePrefix}userRoleId`));
    },
    setUserStatusId: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}statusId`, statusId);
    },
    getUserStatusId: () => {
        return (window.localStorage.getItem(`${storagePrefix}statusId`));
    },
    clearUserStatusId: () => {
        return (window.localStorage.removeItem(`${storagePrefix}statusId`));
    },
    setUserReferralCode: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}referralCode`, statusId);
    },
    getUserReferralCode: () => {
        return (window.localStorage.getItem(`${storagePrefix}referralCode`));
    },
    clearUserReferralCode: () => {
        return (window.localStorage.removeItem(`${storagePrefix}referralCode`));
    },
    setUserFullName: (fullName) => {
        window.localStorage.setItem(`${storagePrefix}fullName`, fullName);
    },
    getUserFullName: () => {
        return (window.localStorage.getItem(`${storagePrefix}fullName`));
    },
    clearUserFullName: () => {
        return (window.localStorage.removeItem(`${storagePrefix}fullName`));
    },
    setUserExchangeStatusId: (statusId) => {
        window.localStorage.setItem(`${storagePrefix}userExchangeStatusId`, statusId);
    },
    getUserExchangeStatusId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userExchangeStatusId`));
    },
    clearUserExchangeStatusId: () => {
        return (window.localStorage.removeItem(`${storagePrefix}userExchangeStatusId`));
    },
    setUserExchangeAccountId: (userExchangeAccountId) => {
        window.localStorage.setItem(`${storagePrefix}userExchangeAccountId`, userExchangeAccountId);
    },
    getUserExchangeAccountId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userExchangeAccountId`));
    },
    clearUserExchangeAccountId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userExchangeAccountId`));
    },
    setExchangeId: (exchangeId) => {
        window.localStorage.setItem(`${storagePrefix}exchangeId`, exchangeId);
    },
    getExchangeId: () => {
        return (window.localStorage.getItem(`${storagePrefix}exchangeId`));
    },
    setUserId: (userId) => {
        window.localStorage.setItem(`${storagePrefix}userId`, userId);
    },
    setEmailId: (emailId) => {
        window.localStorage.setItem(`${storagePrefix}emailId`, emailId);
    },
    setCodeType: (codeType) => {
        window.localStorage.setItem(`${storagePrefix}codeType`, codeType);
    },
    setPhoneNumber: (phoneNumber) => {
        window.localStorage.setItem(`${storagePrefix}phoneNumber`, phoneNumber);
    },
    setCountryId: (countryId) => {
        window.localStorage.setItem(`${storagePrefix}countryId`, countryId);
    },
    getUserId: () => {
        return (window.localStorage.getItem(`${storagePrefix}userId`));
    },
    getEmailId: () => {
        return (window.localStorage.getItem(`${storagePrefix}emailId`));
    },
    getCodeType: () => {
        return (window.localStorage.getItem(`${storagePrefix}codeType`));
    },
    getPhoneNumber: () => {
        return (window.localStorage.getItem(`${storagePrefix}phoneNumber`));
    },
    getCountryId: () => {
        return (window.localStorage.getItem(`${storagePrefix}countryId`));
    },
    clearUserId: () => {
        window.localStorage.removeItem(`${storagePrefix}userId`);
    },
    clearEmailId: () => {
        window.localStorage.removeItem(`${storagePrefix}emailId`);
    },
    clearCodeType: () => {
        window.localStorage.removeItem(`${storagePrefix}codeType`);
    },
    clearPhoneNumber: () => {
        window.localStorage.removeItem(`${storagePrefix}phoneNumber`);
    },
    clearCountryId: () => {
        window.localStorage.removeItem(`${storagePrefix}countryId`);
    },
    setLoginProvider: (exchangeId) => {
        window.localStorage.setItem(`${storagePrefix}loginProvider`, exchangeId);
    },
    getLoginProvider: () => {
        return (window.localStorage.getItem(`${storagePrefix}loginProvider`));
    },
    setStartedAt: (startedAt) => {
        window.localStorage.setItem(`${storagePrefix}startedAt`, startedAt);
    },
    getStartedAt: () => {
        return (window.localStorage.getItem(`${storagePrefix}startedAt`));
    },
    clearStartedAt: () => {
        window.localStorage.removeItem(`${storagePrefix}startedAt`);
    },
    setInvetedAmount: (invetedAmount) => {
        window.localStorage.setItem(`${storagePrefix}invetedAmount`, invetedAmount);
    },
    getInvetedAmount: () => {
        return (window.localStorage.getItem(`${storagePrefix}invetedAmount`));
    },
    clearInvetedAmount: () => {
        window.localStorage.removeItem(`${storagePrefix}invetedAmount`);
    },
    setBacktestingModal: (backtestingModal) => {
        window.localStorage.setItem(`${storagePrefix}backtestingModal`, backtestingModal);
    },
    getBacktestingModal: () => {
        return (window.localStorage.getItem(`${storagePrefix}backtestingModal`));
    },
    clearBacktestingModal: () => {
        window.localStorage.removeItem(`${storagePrefix}backtestingModal`);
    },
    setIsChangeEmail: (isChangeEmail) => {
        window.localStorage.setItem(`${storagePrefix}isChangeEmail`, isChangeEmail);
    },
    getIsChangeEmail: () => {
        return (window.localStorage.getItem(`${storagePrefix}isChangeEmail`));
    },
    clearIsChangeEmail: () => {
        window.localStorage.removeItem(`${storagePrefix}isChangeEmail`);
    },
    setIsAdminLoginAsUser: (status) => {
        window.localStorage.setItem(`${storagePrefix}isAdminLoginAsUser`, status);
    },
    getIsAdminLoginAsUser: () => {
        return (window.localStorage.getItem(`${storagePrefix}isAdminLoginAsUser`));
    },
    setDailingCode: (dialingCode) => {
        window.localStorage.setItem(`${storagePrefix}dialingCode`, dialingCode);
    },
    setCountryList: (countryList) => {
        localStorage.setItem(`${storagePrefix}countryList`, countryList);
    },
    getCountryList: () => {
        return localStorage.getItem(`${storagePrefix}countryList`);
    },
    getDailingCode: () => {
        return (window.localStorage.getItem(`${storagePrefix}dialingCode`));
    },
    clearDailingCode: () => {
        window.localStorage.removeItem(`${storagePrefix}dailingCode`);
    },
};

export {
    storage
};