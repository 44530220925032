import { useState, useEffect } from 'react';
import Images from "../assets/images/index";
import { useAuthenticate, useFetchUser, useRandomQuote, useFetchCountry } from "../services/user.service";
import { useNavigate } from "react-router-dom"
import { storage } from '../utils/storage';
import IResponse from '../types/response';
import { validateNotEmpty, validateEmailFormat } from '../utils/validation';
import { googleAuthenticateUrl } from '../helpers/socialauthenticateurl';
import { handleKeyDown, isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage } from '../helpers/common';
import * as Sentry from "@sentry/react";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [formResponseError, setFormResponseError] = useState('');
    const [quote, setQuote] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const checkValidation = (field: string) => {
        let isValid = true;
        if (field === "email" || field === "both") {
            if (!validateNotEmpty(email)) {
                setEmailError('Email is required');
                isValid = false;
            } else if (!validateEmailFormat(email)) {
                setEmailError('Invalid email format');
                isValid = false;
            } else {
                setEmailError('');
            }
        }
        if (field === "password" || field === "both") {
            if (!validateNotEmpty(password)) {
                setPasswordError('Password is required');
                isValid = false;
            } else {
                setPasswordError('');
            }
        }
        return isValid;
    };

    const loginWithGoogle = () => {
        fetchUserMutations.mutate({
            onSuccess: (response: IResponse) => {
                if (response.statusCode === 200) {
                    navigate(statusWiseOnboardingRedirectToPage());
                } else {
                    window.localStorage.clear();
                }
            },
            onError: (error: any) => {
                window.localStorage.clear();
                Sentry.captureException(error);
                setFormResponseError(error.message);
            }
        });
        window.location.href = googleAuthenticateUrl();
    };

    const requestForgotPassword = () => {
        navigate("/forgotpassword");
    };

    const fetchCountryMutation = useFetchCountry({
        onSuccess: (response: IResponse) => {
            if (response != null && response !== undefined) {
                const countryList = response;
                storage.setCountryList(JSON.stringify(countryList));
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setUserRoleId(response.data.userRoleId);
                storage.setUserStatusId(response.data.statusId);
                storage.setEmailId(response.data.email);
                storage.setUserId(response.data.userId);
                storage.setUserFullName(response.data.fullName);
                if (isNotNullUndefinedBlank(response.data.loginProvider))
                    storage.setLoginProvider(response.data.loginProvider);

                if (isNotNullUndefinedBlank(response.data.phoneNumber)) {
                    storage.setCountryId(response.data.countryId)
                    storage.setPhoneNumber(response.data.phoneNumber)
                }
                let exchangeAccounts = response.data.exchangeAccounts;
                if (exchangeAccounts.length > 0) {
                    storage.setUserExchangeStatusId(exchangeAccounts[0].statusId);
                    storage.setExchangeId(exchangeAccounts[0].exchangeId);
                    storage.setUserExchangeAccountId(exchangeAccounts[0].userExchangeAccountId);
                }
                navigate(statusWiseOnboardingRedirectToPage());
            }
            else {
                setFormResponseError(response.statusMessage);
                window.localStorage.clear();
                navigate("/registration");
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const authenticateMutation = useAuthenticate({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                setLoading(false);
                storage.setToken(response.data.token);
                storage.setRefreshToken(response.data.refreshToken);
                fetchUser();
            }
            else {
                setLoading(false);
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            setLoading(false);
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const randomQuoteMutation = useRandomQuote({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                setQuote(response.data.quoteDescription);
            }
            else {
                setFormResponseError(response.statusMessage);
            }
        },
        onError: (error) => {
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    useEffect(() => {
        randomQuoteMutation.mutate({});
        fetchCountryMutation.mutate({});
    }, []);

    const onAuthenticate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkValidation("both")) {
            authenticateMutation.mutate({ email, password });
            setLoading(true);
        }
    };
    const handlePasswordVisibility = () => {
        if (showPassword)
            setShowPassword(false);
        else if (!showPassword)
            setShowPassword(true);
    };


    const fetchUserMutations = useFetchUser({
        onSuccess: () => {},
        onError: (error: any) => {
            Sentry.captureException(error);
            setFormResponseError(error.message);
        }
    });

    const handleCreateAccount = () => {
        fetchUserMutation.mutate({});
    };

    return (
        <div className="bg-black-russian">
            <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
                <div className='hidden lg:block'>
                    <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
                        <div className='flex justify-center'>
                            <img
                                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                                src={Images.ailtralogo}
                                alt="Ailtra Logo" draggable="false" />
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className='w-[562px]'>
                                <div className='flex justify-center my-8 lg:my-[100px]'>
                                    <img
                                        className="bg-contain w-1/2 lg:w-3/5 3xl:w-4/6 bg-no-repeat"
                                        src={Images.welcome}
                                        alt="welcome Logo" draggable="false" />
                                </div>
                                <div className="before:content-[url('assets/images/quote.svg')] relative before:absolute before:-left-4.5 lg:mx-12 xl:mx-0 before:-mt-5 self-end">
                                    <div className="py-6 bg-white pl-6 xl:pl-17.5 pr-6 xl:pr-17.5 rounded-3xl bg-opacity-[0.08] backdrop-blur-[8px] lg:mb-0">
                                        <blockquote className="text-white font-normal text-sm text-center xl:text-lg max-[600px]:before:-ml-8.75 max-[600px]:before:opacity-30 flex flex-wrap">
                                            {quote}
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right bg-white rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
                    <div className='w-full flex justify-center'>
                        <div className='w-36 h-1/5'>
                            <img
                                className="w-full mt-4 lg:hidden block"
                                src={Images.mobilelogo}
                                alt="Mobile Logo" draggable="false" />
                        </div>
                    </div>
                    <div className='flex justify-center items-center mt-8 lg:mt-0 lg:h-full'>
                        <div className='w-[340px] 2xl:w-[388px]'>
                            <h1 className="text-25 font-semibold tracking-normal subpixel-antialiased md:text-4xl">
                                Welcome Back 👋
                            </h1>
                            <p className="lg:text-base text-sm font-normal text-blue-gulf sm:leading-6 lg:mt-7 tracking-0.16 mt-3">
                                Log in to your account and unleash the power of your AI-based
                                crypto auto trading bot.
                            </p>
                            <div className="mt-6 sm:mt-12">
                                <form onSubmit={onAuthenticate}>
                                    <label className={`block relative border px-4 py-2 bg-blue-alice focus:border-hover cursor:text transition-all duration-600
                                        ${emailError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'} `}>
                                        <span className="block text-xs font-semibold text-black-pearl">Email</span>
                                        <input
                                            type="email"
                                            name="email"
                                            className="pt-1 placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => checkValidation("email")}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Enter your email"
                                        />
                                        {emailError && <p className="text-red-500 text-xs font-semibold mt-1 absolute -bottom-5 -ml-4">{emailError}</p>}
                                    </label>
                                    <label htmlFor="password" className={`block relative border mt-6 transition-all px-4 py-2 bg-blue-alice cursor:text focus:outline-none duration-600 
                                            ${passwordError ? 'border-red-500 hover:border-red-500' : 'border-blue-hawkes focus:border-blue-rock hover:border-hover'}`}>
                                        <div className='flex justify-between items-center'>
                                            <div className='w-full'>
                                                <span className="block text-xs font-semibold text-black-pearl"> Password </span>
                                                {showPassword ? (
                                                    <input
                                                        type="text"
                                                        id="password"
                                                        name="password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onBlur={() => checkValidation("password")}
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        value={password}
                                                        onKeyDown={handleKeyDown}
                                                        placeholder="Enter your password"
                                                    />
                                                ) : (
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onBlur={() => checkValidation("password")}
                                                        className="placeholder-grey-storm bg-blue-alice focus:outline-none text-sm w-full pt-1.5"
                                                        value={password}
                                                        placeholder="Enter your password"
                                                        autoComplete="new-password"
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                )}
                                                {passwordError && <p className="text-red-500 text-xs mt-1 font-semibold absolute -bottom-5 -ml-4">{passwordError}</p>}
                                            </div>
                                            <div className='flex items-center'>
                                                {showPassword ? (
                                                    <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eye}
                                                            alt="close-eye"
                                                        />
                                                    </button>
                                                ) : (
                                                    <button type='button' className='outline-none' onClick={handlePasswordVisibility}>
                                                        <img
                                                            className="w-6 h-6 outline-none"
                                                            src={Images.eyeopen}
                                                            alt="open-eye"
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </label>
                                    <div className='w-full flex justify-end select-none'>
                                        <button type='button' className="w-max text-right mt-5 text-sm text-blue-rock tracking-[0.14px] cursor-pointer" onClick={requestForgotPassword}>
                                            Forgot Password?
                                        </button>
                                    </div>

                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className={`relative w-full flex items-center justify-center py-3.5 cursor-pointer font-bold text-sm transition-all text-white mt-4 sm:mt-8.5 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] outline-none ${loading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                                        {loading ? (
                                            <p className='flex items-center'><span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>{''}</span>Logging in...</p>
                                        ) : (
                                            'LOG IN'
                                        )}
                                    </button>
                                    <p className="text-red-500 text-xs font-semibold mt-1 absolute">{formResponseError}</p>
                                </form>
                                <div className="relative my-4 sm:my-6 flex justify-center items-center">
                                    <span className="w-[45%] h-px bg-mabel absolute right-0"></span>
                                    <p className="font-roboto text-blue-teal tracking-0.16">Or</p>
                                    <span className="w-[45%] h-px bg-mabel absolute left-0"></span>
                                </div>
                                <div className="flex">
                                    <div className="w-full flex gap-6">
                                        <button
                                            type="button"
                                            onClick={loginWithGoogle}
                                            className="bg-pink-pig text-cinnabar font-sora text-xs font-semibold w-full py-3.5 flex justify-center items-center uppercase outline-none hover:bg-rose focus-visible:bg-rose btn-hover-animation">
                                            <img
                                                className="mr-2 w-5"
                                                src={Images.googleicon}
                                                alt="Googel Icon" />
                                            <span>Google</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-6 sm:mt-12">
                                    <p className="text-base font-normal text-center text-black-russian">Don't you have an account?<span className="text-base font-semibold">
                                        {/* <a href="/registration" className="outline-none cursor-pointer ml-1">
                                            <span className="text-xs sm:text-base font-semibold underline outline-none">Create a new one!</span>
                                        </a> */}
                                        <button
                                            className="outline-none cursor-pointer ml-1"
                                            onClick={handleCreateAccount}
                                        >
                                            <span className="text-xs sm:text-base font-semibold underline">
                                                Create a new one!
                                            </span>
                                        </button>
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;