const validateNotEmpty = (value) => {
    return value !== '' && value !== null && value !== undefined;
};

const validateEmailFormat = (value) => {
    return /^\S+@\S+\.\S+$/.test(value);
};

export {
    validateNotEmpty,
    validateEmailFormat
};