import { useEffect } from 'react';
import { useFacebookLogin, useFetchUser } from '../services/user.service';
import { useNavigate } from "react-router-dom";
import { storage } from '../utils/storage';
import IResponse from '../types/response';
import * as Sentry from "@sentry/react";

const FacebookLoginRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlHashParams = new URLSearchParams(window.location.hash.slice(1));
        const access_token = urlHashParams.get('access_token');

        console.log('Full URL:', window.location.href); // Log the full URL
        console.log('Access Token:', access_token);

        if (access_token) {
            facebookLogin.mutate({ access_token });
        }
    }, []);

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                if (response.data.exchangeAccounts.length > 0) {
                    storage.setExchangeId(response.data.exchangeAccounts[0].exchangeId);
                    storage.setUserExchangeAccountId(response.data.exchangeAccounts[0].userExchangeAccountId);
                    navigate('/account/asset/setup?id=' + response.data.exchangeAccounts[0].userExchangeAccountId);
                }
                else
                    navigate('/connect/binance/info');
            }
            else {
                alert(response.statusMessage);
            }
        },
        onError: (error) => { 
            Sentry.captureException(error); 
            alert(error.message);
        }
    });

    const facebookLogin = useFacebookLogin({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                storage.setToken(response.data.token);
                fetchUser();
            }
            else {
                alert(response.statusMessage);
            }
        },
        onError: (error) => { 
            Sentry.captureException(error); 
            alert(error.message);
        }
    });

    return (
        <button type="button" className="bg-indigo-500 ..." disabled>
            <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
            </svg>
            Processing...
        </button>
    );
};

export default FacebookLoginRedirect;