import Images from "../assets/images";
import { useState } from "react";

const Userprofile = () => {
    const [isCoinSetupCollapse, setIsCoinSetupCollapse] = useState(false);
    const [isAnnouncementsCollapse, setIsAnnouncementsCollapse] = useState(false);

    return (
        <div className="py-8 px-9">
            <div className="block 3xl:grid 3xl:grid-cols-3 gap-4">
                <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded flex items-center justify-between">
                    <div className="flex justify-between w-full">
                        <div className="flex items-center">
                            <div className="absolute flex">
                                <div className="w-[82px] h-[82px]">
                                    <img src={Images.performer3} className="rounded-full w-full h-full shadow-lg border-4 border-black-blackRock object-cover" alt="user" />
                                </div>
                                <div className="h-7 w-7 text-lg rounded-full bg-gradient relative flex items-center border-4 border-black-blackRock justify-center top-[55px] right-[30px] transform  origin-center">
                                    <div className="w-3 h-3">
                                        <img src={Images.imageedit} className="w-full h-full" alt="image-edit" />
                                    </div>
                                </div>
                            </div>
                            <div className="ml-28">
                                <p className="text-lg font-semibold leading-normal text-white">Andrew Alfred</p>
                                <p className="text-sm font-normal leading-normal text-grey-nobel mt-1.6">@Crypto1235</p>
                                <p className="text-sm font-normal leading-normal text-grey-nobel mt-1">ID:25448</p>
                            </div>
                        </div>
                        <div className="w-4 h-4">
                            <img src={Images.edit} className="w-full h-full" alt="edit" />
                        </div>
                    </div>
                </div>
                <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded mt-5 3xl:mt-0">
                    <div className="flex">
                        <div className="w-1/3">
                            <p className="text-sm font-normal leading-normal text-grey-nobel">Available Fuel($)</p>
                        </div>
                        <div className="w-2/3">
                            <p className="text-sm font-normal leading-normal text-grey-nobel">Balance </p>
                            <p className="text-sm font-semibold text-white mt-2.5">
                                $1500.33
                                <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5">+2.83%</span>
                                <div className="flex justify-between gap-2 mt-4">
                                    <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2 text-xs text-white font-normal rounded-full">Purchase Fuel</button>
                                    <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2 text-xs text-white font-normal rounded-full">View Statement</button>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-white bg-opacity-3 p-5 border-white border-opacity-20 border rounded mt-5 3xl:mt-0">
                    <div className="flex">
                        <div className="w-1/3">
                            <p className="text-sm font-normal leading-normal text-grey-nobel">Wallet balance($)</p>
                        </div>
                        <div className="w-2/3">
                            <p className="text-sm font-normal leading-normal text-grey-nobel">Balance </p>
                            <p className="text-sm font-semibold text-white mt-2.5">
                                $1500.33
                                <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5">+2.83%</span>
                                <div className="flex justify-between gap-2 mt-4">
                                    <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2 text-xs text-white font-normal rounded-full">Recharge wallet</button>
                                    <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-2 text-xs text-white font-normal rounded-full">View Statement</button>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-7 2xl:grid 2xl:grid-cols-2 block gap-4">
                <div>
                    <h2>
                        <button type="button" className="flex items-center justify-between w-full" onClick={() => setIsCoinSetupCollapse(!isCoinSetupCollapse)}>
                            <span className="text-[22px] text-white font-semibold">My Referral Community</span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-344px)]"></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`transform transition duration-700 ${isCoinSetupCollapse ? 'rotate-90' : 'rotate-360'}`}>
                                <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="white" />
                            </svg>
                        </button>
                    </h2>
                    <div className={`collaps-content ${!isCoinSetupCollapse ? "collaps-open" : ""}`}>
                        <div className="bg-white bg-opacity-3 p-6 border-white border-opacity-20 border rounded mt-6">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center w-48 border-r-2 border-grey-tuna">
                                    <div className="w-8 h-8 flex justify-center items-center rounded-full">
                                        <div className="w-8 h-8">
                                            <img
                                                src={Images.daimond}
                                                className="w-full"
                                                alt="Daimond" />
                                        </div>
                                    </div>
                                    <div className="ml-3">
                                        <h2 className="text-sm text-white font-semibold tracking-normal">
                                            Hiren Bhuva
                                        </h2>
                                        <div>
                                            <span className="text-grey-nobel text-10 font-semibold">Level 1</span>
                                            <span className="text-grey-nobel text-10 font-semibold ml-2.5">Agent#72234</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1 w-48 text-center border-r-2 border-grey-tuna">
                                    <p className="text-xs font-normal text-grey-suit">
                                        Direct Referral
                                    </p>
                                    <p className="text-sm font-semibold text-white text-center mt-2.5">
                                        11
                                    </p>
                                </div>
                                <div className="col-span-1 w-48 text-center border-r-2 border-grey-tuna">
                                    <p className="text-xs font-normal text-grey-suit">
                                        Indirect Referral
                                    </p>
                                    <p className="text-sm font-semibold text-white text-center  mt-2.5">
                                        15
                                    </p>
                                </div>
                                <div className="col-span-1 w-44 flex justify-center">
                                    <button className="flex gap-2 items-center border border-grey-tuna rounded-full w-max py-1.5 px-2.5 relative after:absolute after:top-0 after:left-0 after:w-full after:h-full after:opacity-0 after:rounded-full after:bg-active-gradient after:transition after:duration-[0.4s] after:ease-out hover:after:opacity-100">
                                        <img src={Images.share} className="w-4 h-4" alt="recharge" />
                                        <span className="font-semibold text-white text-10">Share your link</span>
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mt-12">
                                <div className="col-span-1 w-52">
                                    <p className="text-sm font-normal text-grey-suit">
                                        Your Total Commission
                                    </p>
                                    <p className="text-sm font-semibold text-white mt-2.5">
                                        $740.33
                                        <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5">+2.83%</span>
                                    </p>
                                </div>
                                <div className="col-span-1 w-56">
                                    <p className="text-sm font-normal text-grey-suit">
                                        Total Community Commission
                                    </p>
                                    <p className="text-sm font-semibold text-white mt-2.5">
                                        $740.33
                                        <span className="bg-emerald text-white text-10 py-1 px-1.5 rounded-full ml-2.5">+2.83%</span>
                                    </p>
                                </div>
                                <div className="col-span-1 w-64">
                                    <div className="flex items-center">
                                        <img src={Images.crown} className="w-5 h-5 mr-2" alt="Crown" />
                                        <p className="text-13 font-semibold  text-white">
                                            Only 3 referrals left to level up!
                                        </p>
                                    </div>
                                    <p className="text-10 font-normal text-grey-suit mt-2.5">
                                        Become a level 2 member and start earning average of $900 per month effortlessly.
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-between gap-11 mt-7">
                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-3 text-sm text-white font-bold">MY COMMUNITY</button>
                                <button className="btn-hover-animation hover:bg-[right_center] bg-gradient-hover w-full py-3 text-sm text-white font-bold">INVITE FRIENDS</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-7 2xl:mt-0">
                    <h2>
                        <button type="button" className="flex items-center justify-between w-full" onClick={() => setIsAnnouncementsCollapse(!isAnnouncementsCollapse)}>
                            <span className="text-[22px] text-white font-semibold">Announcements</span>
                            <p className="border border-t-0 border-white border-opacity-12 w-[calc(100%-280px)]"></p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={`transform transition duration-700 ${isAnnouncementsCollapse ? 'rotate-90' : 'rotate-360'}`}>
                                <path d="M11.9465 5.45337H7.79316H4.05317C3.41317 5.45337 3.09317 6.2267 3.5465 6.68004L6.99983 10.1334C7.55317 10.6867 8.45317 10.6867 9.0065 10.1334L10.3198 8.82004L12.4598 6.68004C12.9065 6.2267 12.5865 5.45337 11.9465 5.45337Z" fill="white" />
                            </svg>
                        </button>
                    </h2>
                    <div className={`collaps-content ${!isAnnouncementsCollapse ? "collaps-open" : ""}`}>
                        <div className="bg-white bg-opacity-3 px-10 py-12 border-white border-opacity-20 flex justify-between items-center border rounded mt-6">
                            <div className="w-full lg:w-1/3">
                                <div className="w-48 h-44">
                                    <img src={Images.boostprofit} alt="Boost profit" />
                                </div>
                            </div>
                            <div className="w-full lg:w-2/3 text-center">
                                <p className="text-white text-lg">Hello Hiren,<br />Boost Profit with 9 More Coins!</p>
                                <p className="text-grey-suit text-xs">Hey Hiren! Maximize your potential with AIltra's AI-powered strategy. Your first order size is set at 8% ($800) based on a $10,000 fund and medium trading style. Each coin has a lot size of $80. You've enabled 5 coins with a total margin of $400. Add 9 more coins to boost your strategy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Userprofile;