import ailtraLogo from "./ailtra-logo.png";
import facebookicon from "./facebook.svg";
import googleicon from "./google.svg";
import ethereum from "./ethereum.gif";
import pattent_1 from "./bg-pattern-1.png";
import email from "./email.png";
import arrowLeft from "./arrowLeft.svg";
import emailbox2 from "./emailbox2.png";
import welcome from "./welcome.gif";
import congratulation from "./congratulation.gif";
import emoji from "./emoji.png";
import plan from "./plan.svg";
import ailtralogo2 from "./ailtralogo2.png";
import outputonlinegiftools from "./output-onlinegiftools.gif";
import binance from "./binance.png";
import avatar from "./avatar.png";
import binanceconected from "./binanceconected.gif"
import Binancelogopng from "./binance-logo-png.png";
import Cloud from "./cloud.png";
import plant from "./plant.png";
import verify from "./verify.gif";
import signin from "./signin.png";
import consent from "./consent.png";
import consentwelcome from "./consentwelcome.png";
import coindest from "./coindest.png";
import coinify from "./coinify.png";
import binancefulllogo from "./binancefulllogo.png";
import eye from "./eye.png";
import eyeopen from "./eyeopen.png";
import dashboard from "./dashboard.svg"
import crownstar from "./crownstar.svg"
import settings from "./settings.svg"
import shieldcheck from "./shieldcheck.svg"
import userid from "./userid.svg"
import wallet2 from "./wallet2.svg"
import magnifer from "./magnifer.png"
import loginprofile from "./loginprofile.png"
import moon from "./moon.svg"
import sun from "./sun.svg"
import bellbing from "./bellbing.svg"
import arrowdown from "./arrowdown.png"
import roundarrowdown from "./roundarrowdown.png"
import infocircle from "./infocircle.svg"
import dashboardbitcoin from "./dashboardbitcoin.png"
import bitcoinbtc from "./bitcoinbtc.png"
import ethereumicon from "./ethereumicon.png"
import binancecoin from "./binancecoin.png"
import rotateleft from "./rotateleft.png"
import userprofile from "./userprofile.jpg"
import info from "./info.svg"
import shieldactive from "./shieldactive.svg"
import shielddeactive from "./shielddeactive.svg"
import coinarrow from "./coinarrow.svg"
import alert from "./alert.svg"
import manualopen from "./manualopen.svg"
import recharge from "./recharge.svg"
import reward from "./reward.svg"
import performer1 from "./performer1.jpg"
import performer2 from "./performer2.jpg"
import performer3 from "./performer3.jpg"
import top1 from "./top1.png"
import daimond from "./daimond.svg"
import share from "./share.svg"
import crown from "./crown.svg"
import boostprofit from "./boostprofit.svg"
import downarrow from "./downarrow.svg"
import positivealert from "./positivealert.svg"
import logout from "./logout.svg"
import support from "./support.svg"
import usersetting from "./user-setting.svg"
import edit from "./edit.svg"
import imageedit from "./image-edit.svg"
import mobilelogo from "./mobilelogo.webp"
import logouticon from "./logouticon.svg"
import passwordright from "./password-right.png"
import passwordwrong from "./password-wrong.png"
import resetpasswordlogo from "./reset-password-logo.png"
import forgotpasswordlogo from "./forgot-password-logo.png"
import accbalancerightlayer from "./acc-balance-right-layer.svg"
import accbalanceleftlayer from "./acc-balance-left-layer.svg"
import accbalancemainlayer from "./acc-balance-main-layer.svg"
import accbalanceleftcoin from "./acc-balance-left-coin.svg"
import accbalancerightcoin from "./acc-balance-right-coin.svg"
import year from "./years.svg"
import months from "./months.svg"
import days from "./Day.svg"
import hours from "./Hours.svg"
import minutes from "./Minutes.svg"
import second from "./Seconds.svg"
import startthebot from "./start-the-bot.svg"
import popupbacktesting from "./popup-backtesting.png"
import backtesingemail from "./backtesing-email.svg"
import generateotp from "./generate-otp.svg"
import popupbot from "./popup-bot.svg"
import optbacktesting from "./opt-backtesting.svg"
import whitearrowleft from "./white-arrowleft.svg"
import verified from "./verified.svg"
import skeletonchart from "./skeleton-chart.svg"
import alertbacktesting from "./alert-backtesting.svg"
import startbot from "./start-bot.svg"
import dashboardskeleton from "./dashboard-skeleton.svg"
import ailtrachoose from "./ailtra-choose.png"
import monthlyprofit from "./monthly-profit.png"
import dailyrate from "./daily-rate.png"
import investment from "./investment.png"
import monthlyperformers from "./monthly-performers.png"
import topprofit from "./top-profit.png"
import freebot from "./free-bot.svg"
import profileicon from "./profile-icon.png"
import clearfilter from "./clear-filter.png"

interface ImageType {
  ailtralogo: string;
  facebookicon: string;
  googleicon: string;
  ethereum: string;
  pattent_1: string;
  email: string;
  arrowLeft: string;
  emailbox2: string;
  welcome: string;
  congratulation: string;
  emoji: string;
  plan: string;
  ailtralogo2: string;
  outputonlinegiftools: string;
  binance: string;
  avatar: string;
  binanceconected: string;
  Binancelogopng: string;
  Cloud: string;
  plant: string;
  verify: string;
  signin: string;
  consent: string;
  consentwelcome: string;
  coindest: string;
  coinify: string;
  binancefulllogo: string;
  eye: string;
  eyeopen: string;
  dashboard: string;
  crownstar: string;
  settings: string;
  shieldcheck: string;
  userid: string;
  wallet2: string;
  magnifer: string;
  loginprofile: string;
  moon: string;
  sun: string;
  bellbing: string;
  arrowdown: string;
  roundarrowdown: string;
  infocircle: string;
  dashboardbitcoin: string;
  bitcoinbtc: string;
  ethereumicon: string;
  binancecoin: string;
  rotateleft: string;
  userprofile: string;
  info: string;
  shieldactive: string;
  shielddeactive: string;
  coinarrow: string;
  alert: string;
  manualopen: string;
  recharge: string;
  reward: string;
  performer1: string;
  performer2: string;
  performer3: string;
  top1: string;
  daimond: string;
  share: string;
  crown: string;
  boostprofit: string;
  downarrow: string;
  positivealert: string;
  logout: string;
  support: string;
  usersetting: string;
  edit: string;
  imageedit: string;
  mobilelogo: string;
  logouticon: string;
  passwordright: string;
  passwordwrong: string;
  resetpasswordlogo: string;
  forgotpasswordlogo: string;
  accbalancerightlayer: string;
  accbalanceleftlayer: string;
  accbalancemainlayer: string;
  accbalanceleftcoin: string;
  accbalancerightcoin: string;
  year: string;
  months: string;
  days: string;
  hours: string;
  minutes: string;
  second: string;
  startthebot: string;
  popupbacktesting: string;
  backtesingemail: string;
  generateotp: string;
  popupbot: string;
  optbacktesting: string;
  whitearrowleft: string;
  verified: string;
  skeletonchart: string;
  alertbacktesting: string;
  startbot: string;
  dashboardskeleton: string;
  ailtrachoose: string;
  monthlyprofit: string;
  dailyrate: string;
  investment: string;
  monthlyperformers: string;
  topprofit: string;
  freebot:string;
  profileicon:string;
  clearfilter:string;
}

const Images: ImageType = {
  ailtralogo: ailtraLogo,
  facebookicon: facebookicon,
  googleicon: googleicon,
  ethereum: ethereum,
  pattent_1: pattent_1,
  email: email,
  arrowLeft: arrowLeft,
  emailbox2: emailbox2,
  welcome: welcome,
  congratulation: congratulation,
  emoji: emoji,
  plan: plan,
  ailtralogo2: ailtralogo2,
  outputonlinegiftools: outputonlinegiftools,
  binance: binance,
  avatar: avatar,
  binanceconected: binanceconected,
  Binancelogopng: Binancelogopng,
  Cloud: Cloud,
  plant: plant,
  verify: verify,
  signin: signin,
  consent: consent,
  consentwelcome: consentwelcome,
  coindest: coindest,
  coinify: coinify,
  binancefulllogo: binancefulllogo,
  eye: eye,
  eyeopen: eyeopen,
  dashboard: dashboard,
  crownstar: crownstar,
  settings: settings,
  shieldcheck: shieldcheck,
  userid: userid,
  wallet2: wallet2,
  magnifer: magnifer,
  loginprofile: loginprofile,
  moon: moon,
  sun: sun,
  bellbing: bellbing,
  arrowdown: arrowdown,
  roundarrowdown: roundarrowdown,
  infocircle: infocircle,
  dashboardbitcoin: dashboardbitcoin,
  bitcoinbtc: bitcoinbtc,
  ethereumicon: ethereumicon,
  binancecoin: binancecoin,
  rotateleft: rotateleft,
  userprofile: userprofile,
  info: info,
  shieldactive: shieldactive,
  shielddeactive: shielddeactive,
  coinarrow: coinarrow,
  alert: alert,
  manualopen: manualopen,
  recharge: recharge,
  reward: reward,
  performer1: performer1,
  performer2: performer2,
  performer3: performer3,
  top1: top1,
  daimond: daimond,
  share: share,
  crown: crown,
  boostprofit: boostprofit,
  downarrow: downarrow,
  positivealert: positivealert,
  logout: logout,
  support: support,
  usersetting: usersetting,
  edit: edit,
  imageedit: imageedit,
  mobilelogo: mobilelogo,
  logouticon: logouticon,
  passwordright: passwordright,
  passwordwrong: passwordwrong,
  resetpasswordlogo: resetpasswordlogo,
  forgotpasswordlogo: forgotpasswordlogo,
  accbalancerightlayer: accbalancerightlayer,
  accbalanceleftlayer: accbalanceleftlayer,
  accbalancemainlayer: accbalancemainlayer,
  accbalanceleftcoin: accbalanceleftcoin,
  accbalancerightcoin: accbalancerightcoin,
  year: year,
  months: months,
  days: days,
  hours: hours,
  minutes: minutes,
  second: second,
  startthebot: startthebot,
  popupbacktesting: popupbacktesting,
  backtesingemail: backtesingemail,
  generateotp: generateotp,
  popupbot: popupbot,
  optbacktesting: optbacktesting,
  whitearrowleft: whitearrowleft,
  verified: verified,
  skeletonchart: skeletonchart,
  alertbacktesting: alertbacktesting,
  startbot: startbot,
  dashboardskeleton: dashboardskeleton,
  ailtrachoose: ailtrachoose,
  monthlyprofit: monthlyprofit,
  dailyrate: dailyrate,
  investment: investment,
  monthlyperformers: monthlyperformers,
  topprofit: topprofit,
  freebot:freebot,
  profileicon:profileicon,
  clearfilter:clearfilter
};

export default Images;