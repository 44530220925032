import { patch, post, get } from "../helpers/config";
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useVerifyExchangeVerificationCode = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (verifiedData: any) => {
        return await patch("exchange/account/verification/verify-code", verifiedData);
    }, options);
};

export const useAddExchangeAccount = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("exchange/account", data);
    }, options);
};

export const useGetUserExchangeAccount = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("exchange/account");
    }, options);
};

export const useGetExchangeAssets = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (exchangeId: number) => {
        return await get("exchange/assets?exchangeId=" + exchangeId);
    }, options);
};

export const useGetExchangeAssetSuggestOrderSize = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (userExchangeAccountId: string) => {
        return await get("exchange/account/" + userExchangeAccountId + "/configure/auto/suggest");
    }, options);
};

export const useUserExchangeAccountAutoGenerateOrderSize = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (userExchangeAccountId: string) => {
        return await patch("exchange/account/" + userExchangeAccountId + "/configure/auto", "");
    }, options);
};

export const useUserExchangeAccountManualOrderSize = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("exchange/account/" + data[0].userExchangeAccountId + "/configure/manual", data);
    }, options);
};

export const useGetUserAssetProfile = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("exchange/account/" + data.userExchangeAccountId + "/asset/" + data.assetId + "/profile");
    }, options);
};

export const useGetUserAssetOrders = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("exchange/account/" + data.userExchangeAccountId + "/asset/" + data.assetId + "/orders");
    }, options);
};

export const useCreateManualCloseTrade = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("exchange/account/" + data.userExchangeAccountId + "/trade/" + data.assetId + "/close", data.orders);
    }, options);
};

export const useCreateManualOpenTrade = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("exchange/account/" + data.userExchangeAccountId + "/trade/" + data.assetId + "/open", data);
    }, options);
};

export const usePatchUserExchangeAsset = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("exchange/account/" + data.userExchangeAccountId + "/assets/" + data.assetId, data.bodyParam);
    }, options);
};

export const usePatchUserExchangeAccount = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("exchange/account/" + data[0], data[1]);
    }, options);
};

export const useGetUserExchangeAssets = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("exchange/account/" + data.userExchangeAccountId + "/asset/" + data.assetId);
    }, options);
};

export const useGetUserAssetTransactionOrders = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("exchange/account/" + data.userExchangeAccountId + "/asset/orders?pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize);
    }, options);
};

export const useGetExchangeAssetByExchangeSymbolAndExchangeId = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("exchange/asset/" + data.exchangeSymbol + "?exchangeId=" + data.exchangeId);
    }, options);
};

export const useRestartCoin = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("exchange/account/" + data.userExchangeAccountId + "/assetId/" + data.assetId + "/closeorder", data);
    }, options);
};

export const useExchangeSendVerificationCode = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("exchange/account/send-verification-code", data);
    }, options);
};

export const useUpdateUserExchangeAccountStatus = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await patch("exchange/account/" + data.userExchangeAccountId + "/update/status/" + data.statusId, "");
    }, options);
};

export const useGetExchangeAssetsConfigure = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (userExchangeAccountId: string) => {
        return await get("exchange/account/" + userExchangeAccountId + "/assets/configure");
    }, options);
};

export const useCreateBackTestingAccount = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("exchange/account/test", data);
    }, options);
};

export const useUpdateUserExchangeAccountProcessStatus = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await post("exchange/account/process/status/complete", data);
    }, options);
};

export const useGetTopAssets = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("profitandloss/analytics/" + data.userExchangeAccountId + "/topassets/pnl?periodicalType=" + data.periodicalType + "&periodicalType=" + data.periodicalType);
    }, options);
};

export const useGetTopHoldings = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("profitandloss/account/" + data.userExchangeAccountId + "/topholding/assets");
    }, options);
};

export const useGetMostGainedUsers = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("profitandloss/most/gained-users?filterType=" + data.filterType + "&limit=" + data.limit + "&pageNumber=" + data.pageNumber + "&pageSize=" + data.pageSize);
    }, options);
};

export const useGetDirectIndirectReferral = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("referral/user/direct/indirect/referral-count?profileUserId=" + data.userId);
    }, options);
};

export const useGetReferralEarning = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async () => {
        return await get("referral/user/today/total/referral-earning");
    }, options);
};

export const useGetReferralUserEarning = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("referral/user/earnings?profileUserId=" + data.userId);
    }, options);
};

export const useGetUserExchangeAccountPnL = (options?: UseMutationOptions<any, any, any, any>) => {
    return useMutation(async (data: any) => {
        return await get("exchange/account/" + data.userExchangeAccountId + "/pnl");
    }, options);
};