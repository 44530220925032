import { useEffect, useState } from "react";
import Images from "../assets/images";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { IUserAssetTransactionOrders } from "../types/exchange";
import IResponse from "../types/response";
import { useGetUserAssetTransactionOrders } from "../services/exchange.service";
import { storage } from "../utils/storage";
import { formatDateWithOrdinal, handleError, isNotNullUndefinedBlank, nth, timeAgo } from "../helpers/common";
import Pagination from "../helpers/pagination";
import { formatNumberWithCommas } from "../helpers/commaformattednumber";

const Transactions = () => {
    const [userAssetOrders, setUserAssetOrders] = useState<IUserAssetTransactionOrders[]>([]);
    const [allUserAssetOrders, setAllUserAssetOrders] = useState<IUserAssetTransactionOrders[]>([]);
    const [orderType, setOrderType] = useState("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [recordCount, setRecordCount] = useState<number>(0);

    useEffect(() => {
        setAllUserAssetOrders([]);
        setUserAssetOrders([]);
        getUserAssetOrders.mutate({ userExchangeAccountId: storage.getUserExchangeAccountId()?.toString() ?? "", pageNumber: pageNumber, pageSize: pageSize });
    }, [pageNumber, pageSize]);

    const getUserAssetOrders = useGetUserAssetTransactionOrders({
        onSuccess: (response: IResponse) => {
            if (response.data !== null && response.data.orders.length > 0) {
                response.data.orders.forEach((x: { orderLevelPercentage: number, orderLevel: number; }) => x.orderLevelPercentage = parseInt(((x.orderLevel * 100) / 12).toString()));
                setRecordCount(response.recordCount);
                setAllUserAssetOrders(response.data.orders);
                setUserAssetOrders(response.data.orders);
                setOrderType("all");
            }
            else if (isNotNullUndefinedBlank(response.statusMessage))
                alert(response.statusMessage);
        },
        onError: (error) => {
            handleError(error);
        },
    });

    const totalPages = Math.ceil(recordCount / pageSize);

    const handlePageChange = (newPage: number) => {
        setPageNumber(newPage);
    };

    const onOrderTypeChange = (orderType: string) => {
        setOrderType(orderType);
        if (orderType === "queued") {
            let queuedOrders = allUserAssetOrders.filter(x => x.sourceFrom === "Queued");
            setUserAssetOrders(queuedOrders);
        }
        else if (orderType === "opened") {
            let openedOrders = allUserAssetOrders.filter(x => x.sourceFrom === "Opened");
            setUserAssetOrders(openedOrders);
        }
        else if (orderType === "closed") {
            let closedOrders = allUserAssetOrders.filter(x => x.sourceFrom === "Closed");
            setUserAssetOrders(closedOrders);
        }
        else {
            setUserAssetOrders(allUserAssetOrders);
        }
    };

    return (
        <div className="py-8 px-9">
            <div>
                <h2
                    className="flex items-center justify-between w-full"
                    data-accordion-target="#accordion-collapse-body-1"
                    aria-expanded="true"
                    aria-controls="accordion-collapse-body-1">
                    <span className="text-2xl text-white font-semibold">
                        BTC Logs
                    </span>
                    <p className="border border-t-0 border-grey-nobel w-[calc(100%-143px)]"></p>
                </h2>
            </div>
            <div id="myTabContent">
                <div className="bg-grey-jaguar border-grey-tuna border rounded mt-5">
                    <ul
                        className="flex flex-wrap text-xl font-bold text-center"
                        id="myTab"
                        data-tabs-toggle="#myTabContent"
                        role="tablist">
                        <li key={"all"}
                            className="w-1/4">
                            <button type="button" onClick={() => onOrderTypeChange("all")}
                                className={`flex justify-center p-3 w-full ${orderType === "all" ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2" : "text-white font-normal"}`}>
                                <p className={`${orderType === "all" ? "bg-gradient text-gradient bg-clip-text w-max" : ""}`}>All Orders</p>
                            </button>
                        </li>
                        <li key={"queued"} className="w-1/4">
                            <button type="button" onClick={() => onOrderTypeChange("queued")}
                                className={`flex justify-center p-3 w-full ${orderType === "queued" ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2" : "text-white font-normal"}`}>
                                <p className={`${orderType === "queued" ? "bg-gradient text-gradient bg-clip-text w-max" : ""}`}>Queued</p>
                            </button>
                        </li>
                        <li key={"opened"} className="w-1/4">
                            <button type="button" onClick={() => onOrderTypeChange("opened")}
                                className={`flex justify-center p-3 w-full ${orderType === "opened" ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2" : "text-white font-normal"}`}>
                                <p className={`${orderType === "opened" ? "bg-gradient text-gradient bg-clip-text w-max" : ""}`}>Opened</p>
                            </button>
                        </li>
                        <li key={"closed"} className="w-1/4">
                            <button type="button" onClick={() => onOrderTypeChange("closed")}
                                className={`flex justify-center p-3 w-full ${orderType === "closed" ? "text-gradient bg-clip-text border-bottom bg-gradient border-b-2" : "text-white font-normal"}`}>
                                <p className={`${orderType === "closed" ? "bg-gradient text-gradient bg-clip-text w-max" : ""}`}>Closed</p>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="overflow-auto max-h-[calc(100vh-274px)] mt-3">
                {userAssetOrders.map((order: any, index) => (
                    <div className={`bg-grey-jaguar p-4 mb-2.5 flex items-center justify-start border rounded min-w-fit ${order.sourceFrom === "Opened" ? "border-camarone" : order.sourceFrom === "Queued" ? "border-grey-mischka" : "border-venetian-red"}`}>
                        <div className="flex items-center min-w-42.5">
                            <div className="bg-grey-tuna w-10 h-10 flex justify-center items-center rounded-full">
                                <div className="w-7 h-7">
                                    <img
                                        src={order.assetLogoUrl}
                                        className="w-full rounded-full"
                                        alt="Coin logo"
                                    />
                                </div>
                            </div>
                            <div className="ml-3">
                                <h2 className="text-15 text-white font-semibold tracking-normal">
                                    {order.baseSymbol}
                                </h2>
                                <p className="text-xs text-white font-normal tracking-normal">
                                    {order.assetName}
                                </p>
                            </div>
                        </div>
                        <div className="min-w-40">
                            <p className="text-xs font-normal text-grey-suit">Trade</p>
                            <p className={`text-10 font-semibold ${order.positionSide.toLowerCase() === "short" ? "bg-venetian-red" : "bg-camarone"} text-white rounded-full py-1 px-2 mt-1 w-max`}>{order.sourceFrom} {order.positionSide.toLowerCase() === "long" ? "Long" : order.positionSide.toLowerCase() === "short" ? "Short" : order.positionSide.toLowerCase() === "both" ? "Both" : ""}</p>
                        </div>
                        {
                            order.sourceFrom !== "Closed" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Order amount($)</p>
                                <p className="text-sm font-semibold text-white mt-1">${formatNumberWithCommas(order.quoteQuantity.toFixed(2))}</p>
                            </div>) : ""
                        }
                        {
                            order.sourceFrom !== "Closed" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Order amount(Coin)</p>
                                <p className="text-sm font-semibold text-white mt-1">{order.assetQuantity}</p>
                            </div>) : ""
                        }
                        {
                            order.sourceFrom !== "Queued" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Total amount</p>
                                <p className="text-sm font-semibold text-white mt-1">${formatNumberWithCommas(order.totalAmount?.toFixed(2) ?? 0.00)}</p>
                            </div>) : ""
                        }
                        {
                            order.sourceFrom === "Closed" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Closing Price</p>
                                <p className="text-sm font-semibold text-white mt-1">${formatNumberWithCommas(order.closingPrice.toFixed(2))}</p>
                            </div>) : ""
                        }
                        <div className="min-w-45">
                            <div className="flex justify-between items-center w-[73%]">
                                <p className="text-xs font-normal text-grey-suit">
                                    Order level
                                </p>
                                <p className="bg-black-blackRock text-white text-xs text-center w-10 h-6 flex items-center font-semibold justify-center ml-4">
                                    {order.orderLevel}{nth(order.orderLevel)}
                                </p>
                            </div>
                            <div>
                                <Slider
                                    className="custom-slider order-level coin-renge mt-1.5 w-[70%]"
                                    min={1}
                                    max={100}
                                    value={order.orderLevelPercentage}
                                    step={1}
                                    dotStyle={{ display: 'none' }}
                                    activeDotStyle={{ display: 'none' }} />
                            </div>
                        </div>
                        {
                            order.sourceFrom === "Opened" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Target Price</p>
                                <p className="text-sm font-semibold text-white mt-1">${formatNumberWithCommas(order.targetPrice?.toFixed(2)) ?? 0.00}</p>
                            </div>) : ""
                        }
                        {
                            order.sourceFrom === "Opened" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Floating P&L</p>
                                <div className="flex items-center">
                                    <h2 className="text-sm text-emerald font-semibold tracking-normal">
                                        $0
                                    </h2>
                                    <span className="bg-camarone text-white text-[8px] rounded-full py-0.5 px-1.5  ml-2">
                                        +0%
                                    </span>
                                </div>
                            </div>) : ""
                        }
                        {
                            order.sourceFrom === "Closed" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Exchange Fees</p>
                                <p className="text-sm font-semibold text-white mt-1">${order.exchangeFee}</p>
                            </div>) : ""
                        }
                        {
                            order.sourceFrom === "Closed" ? (<div className="min-w-45">
                                <p className="text-xs font-normal text-grey-suit">Fuel Charge</p>
                                <p className="text-sm font-semibold text-white mt-1">${order.fuelCharge}</p>
                            </div>) : ""
                        }
                        {
                            order.sourceFrom === "Closed" ? (<div className="min-w-45">
                                <p className="bg-gradient text-black text-xs py-1 px-6 rounded-full w-max">Profit</p>
                                <p className="bg-gradient text-gradient bg-clip-text w-max text-base font-semibold mt-1.5">${formatNumberWithCommas(order.profitPrice.toFixed(2))}</p>
                            </div>) : ""
                        }
                        {order.statusId <= 3 ? (
                            <div className="flex items-start justify-end min-w-[670px]">
                                <div className="w-6 h-6">
                                    <img src={Images.alert} alt="alert" className="animate-pulse" />
                                </div>
                                <div className="ml-1.5">
                                    <p className="text-xs font-normal text-white">Waiting for Exchange</p>
                                    <p className="text-xs font-normal text-grey-suit mt-1">{new Date(order.orderDateTime).toLocaleString('en-US', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true
                                    })}</p>
                                    <p className="text-xs font-normal text-grey-suit mt-1">{formatDateWithOrdinal(order.orderDateTime)}</p>
                                </div>
                            </div>
                        ) : (
                            <div className="min-w-[135px]">
                                <p className="text-xs font-normal text-grey-suit">{timeAgo(order.orderDateTime)}</p>
                                <p className="text-xs font-normal text-grey-suit mt-1">{new Date(order.orderDateTime).toLocaleString('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })}</p>
                                <p className="text-xs font-normal text-grey-suit mt-1">{formatDateWithOrdinal(order.orderDateTime)}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <Pagination
                currentPage={pageNumber}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default Transactions;
